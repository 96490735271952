import { Accordion } from 'react-bootstrap';
import SectionTitle from '../../Components/Common/SectionTitle/SectionTitle';
import './FaqSection.css';

const About = () => {
    return (
        <>
            <section className="faq-section pad-b-80 pad-t-50">
                <div className="container">
                    <SectionTitle CustomClass="text-center pad-b-50" title="  About MsingiPACK Cloud" ShapeImage="heading-clip-1.svg" />
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                 
                            <Accordion.Body>
                         
MsingiPACK Cloud platform is a solution powered by Virtual Essence Limited. The
solution is dedicated to meeting the challenges in e-Education by taking a lead role
in developing local relevant digital content for Kenyan schools and providing easy
access to a large repository of KICD approved interactive learning content and exam
material, Flexible and affordable ways of accessing the digital content. Our team is
focused on delivering ICT consultancy in the schools, solution training with a
complete user orientation to the Kenyan Market and the right support for your
solution.
                            </Accordion.Body>
                        </Accordion.Item>
             
                    </Accordion>
                </div>
            </section>
        </>
    );
}

export default About;