import { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Banner from "../../Components/Common/Banner/Banner";
import SectionTitle from "../../Components/Common/SectionTitle/SectionTitle";
import Plans from "../../Components/Plans/Plans";
import { InputText } from '../../Components/Common/Inputs/Inputs';
import CommonButton from '../../Components/Common/Buttons/Buttons';
import CommonModal from '../../Components/Common/Modal/Modal';
import '../../Components/Common/ContactForm/ContactForm.css'
import HandleError from '../../Components/HandleError/HandleError';
import { PakageListing } from "../..//Redux/Action/PakageAction";
import { useSelector, useDispatch } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import './Pricing.css';
import Api from '../../Api';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import FreeTrialPopup from './FreeTrialPopup';

const Pricing = () => {
    const myState1 = useSelector((state) => state.PakagePlanData);
    let dispatch = useDispatch()
    const [PakageDetails, setPakageDetails] = useState();

    useEffect(() => {
        dispatch(PakageListing());
        // setShow(myState2)
    }, [])

    useEffect(() => {
        // if (myState1.status === "success") {
        //     setisLoad(true)
        // }

        setPakageDetails(myState1.data?.pricing_details)
    }, [myState1])



    return (
        <>
            {console.log(PakageDetails && PakageDetails[0], "PakageDetails")}
            <Banner BannerHeading="Pricing" BannerSubHeading={<p>Interactive digital content solution, for the best price.</p>} />
            {PakageDetails && <section className="what-section pad-t-80 pad-b-80">
                <div className="container">
                    <SectionTitle CustomClass="text-center pad-b-50" title="Pricing Plans" ShapeImage="heading-clip-1.svg" />
                    <div className="tab-outer">
                        <Tabs defaultActiveKey="1" className="pricing-tabs" id="plans-tabs">
                            <Tab eventKey="0" title="Monthly plans">
                                <Plans BronzePrice={PakageDetails[0]?.monthly_price} SilverPrice={PakageDetails[1].packagePlanDetails[0]?.monthly_price} GoldPrice={PakageDetails[2]?.monthly_price} PlanDuration="Monthly" />
                            </Tab>
                            <Tab eventKey="1" title="Termly plans">
                                <Plans BronzePrice={PakageDetails[0]?.termly_price} SilverPrice={PakageDetails[1].packagePlanDetails[0]?.termly_price} GoldPrice={PakageDetails[2]?.termly_price} PlanDuration="Termly" />
                            </Tab>
                            <Tab eventKey="2" title="Yearly plans">
                                <Plans BronzePrice={PakageDetails[0]?.yearly_price} SilverPrice={PakageDetails[1].packagePlanDetails[0]?.yearly_price} GoldPrice={PakageDetails[2]?.yearly_price} PlanDuration="Yearly" />
                            </Tab>
                        </Tabs>
                    </div>
                    <div className="free-trail-bx">
                        <div>
                            <h2>Start a free trial</h2>
                            <p>Try first & decide later, no payment required.</p>
                        </div>
                        <FreeTrialPopup />
                    </div>


                </div>
            </section>}

        </>
    );
}

export default Pricing;