import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import "./DashboardHeader.css";
import { useEffect, useState } from "react";
import { UserList, NavClass, UserNotification } from "../../Redux/Action/UserAction";
import { useDispatch, useSelector } from "react-redux";
import CommonModal from "../Common/Modal/Modal";
import { toast } from "react-toastify";
import { LogoutApiData } from "../../Redux/Action/AuthAction";

const DashboardHeader = () => {
  const UserDetails = useSelector((state) => state.signupApiData);
  const NotioficationCount = useSelector((state) => state.notification);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [IsClass, setisClass] = useState(false)
  const [NotificationCount, setNotificationCount] = useState()
  const [show, setShow] = useState(false);

  if (localStorage.getItem("user-token")) {
    var userData = JSON.parse(localStorage.getItem("user-token")).data.id;
  }

  // useEffect(() => {
  //   if (userData) {
  //     dispatch(UserList(userData));
  //     var timer = setInterval(() => {
  //       dispatch(UserNotification());
  //     }, 2000);
  //   }
  //   return () => clearInterval(timer); // cleanup the timer
  // }, [userData]);

  useEffect(() => {
    dispatch(NavClass(IsClass));
    if (IsClass === false) {
      document.body.className = '';
    } else {
      document.body.className = 'sidebar-sticky';
    }
    setNotificationCount(NotioficationCount.data?.notification_count)
  }, [IsClass, NotioficationCount?.data]);

  let toggleBox = () => {
    setisClass(!IsClass)
    // console.log(document.body.className);

  };

  toast.configure();
  const logout = () => {
    setShow(true);
  };


  const handleClose = () => setShow(false);

  const handleSubmit = () => {
    setShow(false);
    dispatch(LogoutApiData());
    localStorage.removeItem("user-token");
    toast.success("Logout Successfully");
    navigate("/login");
  };


  const body = () => {
    return (
      <>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div className="congrats-bx">
          <h2 className="mb-4">Confirm Logout!</h2>
          <p>Are you sure, you want to logout now?</p>
        </div>
      </>
    )
  }
  const modelData = body();

  return (
    <>
      <div className="dashboard-side-bar">
        <div className="col logo-header">
          <div className="navbar-brand dashboard-logo">
            <Link to="/">
              <img src="/images/logo.svg" alt="logo" />
            </Link>
          </div>
        </div>
        <ul>
          <li>
            <NavLink to="/dashboard">
              <span className="material-icons">
                dashboard
              </span>
              {" "}
              <span className="menu-name">Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/my-subscription">
              <span className="material-icons">
                subscriptions
              </span>
              {" "}
              <span className="menu-name">My Subscriptions</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/user-account">
              <span className="material-icons">
                account_circle
              </span>
              {" "}
              <span className="menu-name">User-Account</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/lms-domain">
              <span className="material-icons">
                account_circle
              </span>
              {" "}
              <span className="menu-name">LMS Domains</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/login-logs">
              <span className="material-icons">
                report
              </span>
              {" "}
              <span className="menu-name">Session-Logs</span>
            </NavLink>
          </li>


          <li>
            <NavLink to="/lms-documentation">
              <span className="material-icons">
                api
              </span>
              {" "}
              <span className="menu-name">LMS-Documentation</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/documentation">
              <span className="material-icons">
                description
              </span>
              {" "}
              <span className="menu-name">Documentation</span>
            </NavLink>
          </li>


          <li>
            <NavLink to="/notifications">
              <span className="material-icons">
                notifications_none
              </span>
              {" "}
              <span className="menu-name">Notifications</span>
            </NavLink>
          </li>

          {/* <li>
            <NavLink to="/settings">
              <span className="material-icons">
                settings
              </span>
              {" "}
              <span className="menu-name">Settings</span>
            </NavLink>
          </li> */}

          <li>
            <button
              onClick={logout}
            >
              <span className="material-icons">
                logout
              </span>
              {" "}
              <span className="menu-name">Logout</span>
            </button>
          </li>

        </ul>
      </div>
      <CommonModal
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        show={show}
        ModalClass="confirmation-popup"
        Name="Logout"
        FooterClass="full-btn"
        modelData={modelData}
      />
    </>
  );
};

export default DashboardHeader;
