import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from "../../Components/Common/Banner/Banner";
import { Currency } from '../../Currency';

const InvoicePackage = () => {
    const [cartData, setcartData] = useState(0)

    useEffect(() => {
        const cartdata = localStorage.getItem('cart_item')
        if (cartdata) {
            setcartData(JSON.parse(cartdata))
        }
    }, [])


    return (<>
        {
            cartData.PakageId === 0 ?
                Object.keys(cartData).length !== 0 &&
                Object.keys(cartData.pakage).map((oneKey, i) => {
                    return (
                        <>
                            <tr>
                                <td colSpan={2} style={{ borderTop: '1px dashed #afafaf', lineHeight: '1.5' }}>
                                    <strong>{cartData.pakage[oneKey].grade_name}</strong> <br />
                                    <small>
                                        {cartData.pakage[oneKey].subjects.map((ele, i) => {
                                            console.log(cartData.pakage[oneKey].subjects.length - 1, "cartData.pakage[oneKey]")
                                            return (
                                                <>
                                                    <span key={i}> {ele.subject_name}
                                                        {i < cartData.pakage[oneKey].subjects.length - 1 ? <>,</> : null}
                                                    </span>

                                                </>
                                            )
                                        })}
                                    </small>
                                </td>
                                <td style={{ borderTop: '1px dashed #afafaf', whiteSpace: 'nowrap' }} align="center"> Ksh {cartData.prize} </td>
                                <td style={{ borderTop: '1px dashed #afafaf' }} align="center"> {cartData.pakage[oneKey].subjects.length}</td>
                                <td style={{ borderTop: '1px dashed #afafaf' }} align="center"> {cartData.pakage[oneKey].users?.toLocaleString()}</td>
                                <td style={{ borderTop: '1px dashed #afafaf', textAlign: 'center', fontWeight: 600, whiteSpace: 'nowrap' }}> {Currency(cartData.pakage[oneKey].subjects.length * parseInt(cartData.prize) * cartData.pakage[oneKey].users)}</td>
                            </tr>
                        </>
                    )
                }) : cartData.PakageId === 1 ?
                    cartData.pakage.map((oneKey, i) => {
                        return (
                            <>
                                <tr>
                                    <td colSpan={2} style={{ borderTop: '1px dashed #afafaf', lineHeight: '1.5' }}>
                                        <strong>{oneKey.grade_name}</strong> <br />

                                    </td>
                                    <td style={{ borderTop: '1px dashed #afafaf', whiteSpace: 'nowrap' }} align="center"> Ksh {oneKey.price} </td>
                                    {/* <td style={{ borderTop: '1px dashed #afafaf' }} align="center"> {1}</td> */}
                                    <td style={{ borderTop: '1px dashed #afafaf' }} align="center"> {oneKey.users?.toLocaleString()}</td>
                                    <td style={{ borderTop: '1px dashed #afafaf', textAlign: 'center', fontWeight: 600, whiteSpace: 'nowrap' }}>{Currency(oneKey.price * oneKey.users)}</td>
                                </tr>
                            </>
                        )
                    }) :
                    <>
                        <tr>
                            <td colSpan={2} style={{ borderTop: '1px dashed #afafaf', lineHeight: '1.5' }}>
                                <strong>All Grades/Classes content</strong> <br />

                            </td>
                            <td style={{ borderTop: '1px dashed #afafaf', whiteSpace: 'nowrap' }} align="center"> Ksh {cartData.prize} </td>
                            <td style={{ borderTop: '1px dashed #afafaf' }} align="center">   {cartData.users?.toLocaleString()}</td>
                            <td style={{ borderTop: '1px dashed #afafaf', textAlign: 'center', fontWeight: 600, whiteSpace: 'nowrap' }}>{Currency(Number(cartData.prize) * Number(cartData.users))}</td>
                        </tr>
                    </>}</>
    )
}

export default InvoicePackage;