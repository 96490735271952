import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoute = () => {
  const userToken = localStorage.getItem("user-token");
  var auth = true;
  if (userToken == undefined || userToken == null) {
    auth = false;
  } else {
    auth = true;
  }
  return userToken ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
