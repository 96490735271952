
import DashboardNavbar from "../../../Components/DashboardNavbar/DashboardNavbar";
import DashboardHeader from "../../../Components/DashboardHeader/DashboardHeader";
import "../../../Components/Common/ContactForm/ContactForm.css";
import "../Dashboard.css";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation, NavLink } from "react-router-dom"
import Api from "../../../Api";
import { SubscriptionDetailsLista } from "./SubscriptionDetailsList";

const SubscriptionDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let { id } = useParams();
    const [SubscriptionDetailsList, setSubscriptionList] = useState()
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user-token'));
        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + userData.data.token,
            }
        };
        fetch(Api.baseURL + `/subscription_details_tree/${id}`, config)
            .then((res) => res.json())
            .then((data) => {
                setSubscriptionList(data.data)
            })
            .catch((err) => {
                if (err) {
                    console.log("this is err", err);
                }
            });
    }, []);

    const SubscriptionDetails = (id) => {
        navigate(`/Subscription-details/${id}`)

    }

    const ViewContent = (url) => {
        navigate(`/view-content/${id}`, { state: { Url: url, id: id } });
    }
    return (
        <>
            <DashboardHeader />
            <div className="mpc-dashboard">
                <DashboardNavbar />
                <div className="dashboard-right-content">
                    <h1 className="main-page-heading with-filter">
                        <span>Subscription Details</span>
                        <span> <button className="btn-theme" theme="info"
                        >
                            <NavLink
                                to={`/my-subscription`}
                                theme="info"
                            >
                                <span className="material-icons">keyboard_backspace</span>

                            </NavLink>

                        </button></span>
                    </h1>
                    <div className="table-responsive">

                        <SubscriptionDetailsLista SubscriptionDetailsList={SubscriptionDetailsList} ViewContent={ViewContent} />

                    </div>





                </div>
            </div>
        </>
    );
};

export default SubscriptionDetails;
