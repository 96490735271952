import {
    USER_NOTIFICATION
} from "../Type.js/Type";

const initialState = {};
const notification = (state = initialState, action) => {
    let data = action.payload;
    switch (action.type) {
        case USER_NOTIFICATION:
            return data;
        default:
            return state;
    }
};
export default notification;
