import React, { useState, useEffect } from "react";
import Banner from "../../Components/Common/Banner/Banner";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import "./ContentPlans.css";
import { PakageListing } from "../../Redux/Action/PakageAction";
import ContentTabs from "./contantTab";
import ContentHeader from "./ContentHeader";
import ContentPrize from "./ContentPrize";
import Loader from '../../Components/Common/loader';
const ContentPlans = () => {
  const myState1 = useSelector((state) => state.PakagePlanData);
  let dispatch = useDispatch()
  let location = useParams()
  const [PakageDetails, setPakageDetails] = useState();
  const [GradeDetails, setGradeDetails] = useState();
  const [PakageId, setPakageId] = useState(2);
  const [isLoad, setisLoad] = useState(false);
  const [DiscountValue_per, setDiscountValue_per] = useState();
  // const [DiscountType, setDiscountType] = useState();

  useEffect(() => {
    if (myState1.status === "success") {
      setisLoad(true)
    }
    setGradeDetails(myState1.data?.grade_details)
    setPakageDetails(myState1.data?.pricing_details)
  }, [myState1])

  useEffect(() => {

    if (location.id === "bronze-plans" || location.id == 0) {

      console.log(location.id, "location.id");
      setisLoad(false)
      setGradeDetails()
      setPakageDetails()
      dispatch(PakageListing());
      setPakageId(0)
    }
    else if (location.id === "silver-plans" || location.id == "1") {

      console.log(location.id, "location.id");

      setisLoad(false)
      setGradeDetails()
      setPakageDetails()
      dispatch(PakageListing());
      setPakageId(1)
    }
    else if (location.id === "gold-plans" || location.id == 2) {

      console.log(location.id, "location.id");
      setisLoad(false)
      setGradeDetails()
      setPakageDetails()
      dispatch(PakageListing());
      setPakageId(2)
    }
  }, [location.id])

  const handleSelect = (key) => {

    setDiscountValue_per()
    if (key === "bronze-plans")
      setPakageId(0)
    else if (key === "silver-plans")
      setPakageId(1)
    else if (key === "gold-plans")
      setPakageId(2)
  }

  useEffect(() => {
    dispatch(PakageListing());
    if (localStorage.getItem("items")) {
      localStorage.removeItem("items")
    }
  }, [])

  const changeDiscount = (per) => {
    setDiscountValue_per(per)
  }
  if (isLoad) {
    return (
      <>
        <Banner
          BannerHeading="Content Plans"
          BannerSubHeading={
            <p>
              Curated and approved content by Kenya Institute <br /> of Curriculum
              Development (KICD)
            </p>
          }
        />
        <section className="what-section pad-t-80 pad-b-80">
          <div className="container">
            <ol className="breadcrumb mb-5">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Content Plans
              </li>
            </ol>
            <div className="pricing-tabs">
              <Tabs
                defaultActiveKey={location.id ? location.id : "gold-plans"}
                className="ContentPlansTabs"
                id="ContentPlansTabs"
                onSelect={handleSelect}
              >
                <Tab
                  eventKey="bronze-plans"
                  title={
                    <>
                      <img src="/images/bronze-icon.svg" alt="bronze" />
                      <h2>{PakageDetails[0]?.package_name}</h2> <p>{`UP TO ${PakageId == 0 && DiscountValue_per ? DiscountValue_per : PakageDetails[0]?.monthly_discount_per}%OFF`}</p>
                    </>
                  }
                >
                  <div className="contentPlans-bx" offer={`UP TO ${PakageId == 0 && DiscountValue_per ? DiscountValue_per : PakageDetails[0]?.monthly_discount_per}%OFF`}>

                    <ContentTabs PakageType="Bronze" GradeDetails={GradeDetails} PakageId={PakageId} PakageDetails={PakageDetails} changeDiscount={changeDiscount} />
                  </div>
                </Tab>
                <Tab
                  eventKey="silver-plans"
                  title={
                    <>
                      <img src="/images/silver-icon.svg" alt="silver" />{" "}
                      <h2>{PakageDetails[1]?.package_name}</h2> <p>{`UP TO ${PakageId == 1 && DiscountValue_per ? DiscountValue_per : PakageDetails[1]?.monthly_discount_per}%OFF`}</p>
                    </>
                  }
                >
                  <div className="contentPlans-bx" offer={`UP TO ${PakageId == 1 && DiscountValue_per ? DiscountValue_per : PakageDetails[1]?.monthly_discount_per}%OFF`}>
                    <div className="row align-items-center">
                      <ContentHeader />
                    </div>  <ContentTabs PakageType="Silver" GradeDetails={GradeDetails} PakageId={PakageId} PakageDetails={PakageDetails} changeDiscount={changeDiscount} />
                  </div>
                </Tab>
                <Tab
                  eventKey="gold-plans"
                  title={
                    <>
                      <img src="/images/gold-icon.svg" alt="gold" /> <h2>{PakageDetails[2]?.package_name}</h2>{" "}
                      <p>{`UP TO ${PakageId == 2 && DiscountValue_per ? DiscountValue_per : PakageDetails[2]?.monthly_discount_per}%OFF`}</p>
                    </>
                  }
                >
                  <div className="contentPlans-bx" offer={`UP TO ${PakageId == 2 && DiscountValue_per ? DiscountValue_per : PakageDetails[2]?.monthly_discount_per}%OFF`}>

                    <ContentTabs PakageType="Gold" GradeDetails={GradeDetails} PakageId={PakageId} PakageDetails={PakageDetails} changeDiscount={changeDiscount} />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </section>
      </>
    );
  }
  else {
    return (<>
      <div style={{ height: 250 }} className="d-flex align-items-center justify-content-center">
        <Loader isMsg={true} />
      </div>
    </>

    )
  }
};

export default ContentPlans;
