import { useEffect, useState } from 'react';
import DashboardNavbar from '../../../Components/DashboardNavbar/DashboardNavbar';
import DashboardHeader from '../../../Components/DashboardHeader/DashboardHeader';
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Api from '../../../Api';
import "../../../Components/Common/ContactForm/ContactForm.css";
import Loader from '../../../Components/Common/loader';
import ReactApexChart from 'react-apexcharts'

const ReportList = () => {
    return (
        <>
            <DashboardHeader />
            <div className="mpc-dashboard">
                <DashboardNavbar />
                <div className="dashboard-right-content">
                    <h1 className="main-page-heading with-filter">
                        <span>API Keys</span>
                        <div className="filter">
                        </div>
                    </h1>
                    <div className="repeat-white-bx shadow-none hover-effect">
                        <div className='table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">User Name</th>
                                        <th scope="col">Plan Name</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">API Key</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Created Date</th>
                                        <th scope="col" style={{ textAlign: "center" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Erwin E. Brown</td>
                                        <td>Support for theme</td>
                                        <td>Erwin E. Brown</td>
                                        <td>012345678901</td>
                                        <td><span className="badge bg-danger">Inactive</span></td>
                                        <td>05/04/2022</td>
                                        <td style={{ textAlign: "center" }}>
                                            <button className="btn-success" title="Edit"><a href="" ><span className="material-icons">edit</span></a></button>
                                            <button className="btn-danger" title="Delete"><a href=""><span className="material-icons">delete</span></a></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Erwin E. Brown</td>
                                        <td>Support for theme</td>
                                        <td>Erwin E. Brown</td>
                                        <td>012345678901</td>
                                        <td><span className="badge bg-success">Active</span></td>
                                        <td>05/04/2022</td>
                                        <td style={{ textAlign: "center" }}>
                                            <button className="btn-success"><a href="" ><span className="material-icons">edit</span></a></button>
                                            <button className="btn-danger"><a href=""><span className="material-icons">delete</span></a></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ReportList