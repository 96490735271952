import { useEffect, useState } from 'react';
import DashboardNavbar from '../../../Components/DashboardNavbar/DashboardNavbar';
import DashboardHeader from '../../../Components/DashboardHeader/DashboardHeader';
import PaginatedItems from "../../../Components/Common/pagination";
import Api from '../../../Api';
import "../../../Components/Common/ContactForm/ContactForm.css";
import Loader from '../../../Components/Common/loader';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import CommonModal from '../../../Components/Common/Modal/Modal';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from 'react-toastify';
import HandleError from '../../../Components/HandleError/HandleError';
import Multiselect from 'multiselect-react-dropdown';

const ManageUser = () => {
    let { id } = useParams()
    let navigate = useNavigate()
    const [listData, setlistData] = useState()
    const [loading, setLoading] = useState(true)
    const [Gradeid, setGradeid] = useState()
    //popup
    const [Show, setShow] = useState(false);
    const [pdf, setPdf] = useState()
    const [Error, setError] = useState()
    // csv errros
    const [CSVerror, setCSVerror] = useState()
    // popup for single user
    const [Isshows, setIsShows] = useState(false);
    const [formLoader, setformLoader] = useState(false);
    const [SelectedValue, setSelectedValue] = useState({});
    const [Dropdowndata, setDropdowndata] = useState({});
    const [Reload, setReload] = useState(false);
    const [SelectedDropdowndata, setSelectedDropdowndata] = useState({});
    const [grade_id, setgrade_id] = useState()
    const [reloadkrdebhai, setreloadkrdebhai] = useState(false)
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user-token'));
        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + userData.data.token,
            }
        };
        fetch(Api.baseURL + `/subscription_details/${id}`, config)
            .then((res) => res.json())
            .then((data) => {
                setlistData(data.data)
                setLoading(false)
            })
            .catch((err) => {
                if (err) {
                    console.log("this is err", err);
                }
            });


    }, [reloadkrdebhai]);

    let ViewContent = (id, grade_id) => {
        console.log(id, "id");
        navigate(`/assigned-user/${id}/${grade_id}`)
    }

    let AssignUser = (id) => {
        setGradeid(id)
        setShow(true)
        console.log(id, "id");
    }

    const handleClose = () => {
        setShow(false)
        setError()
    }

    const Submitdata = (e) => {
        if (pdf) {
            console.log(typeof id, "i am best id");
            const token = JSON.parse(localStorage.getItem("user-token"));
            let formData = new FormData();
            formData.append("grade_id", Gradeid);
            formData.append('subscription_id', parseInt(id));
            formData.append("csv_file", pdf);
            console.log(formData, "formdata");
            setShow(false)
            setformLoader(true)
            setError()
            axios({
                url: Api.baseURL + `/upload_users_csv`,
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Authorization: "BEARER " + token.data.token
                },
                data: formData
            })
                .then(result => {
                    if (result?.data.status === "success") {
                        setCSVerror(result.data.errors)
                        toast.success(result.data.message)
                        console.log("hello", result);
                        setformLoader(false)
                        setreloadkrdebhai(!reloadkrdebhai)
                    }

                    else if (result.data.status === "error") {
                        console.log(result.data, "dartfsf");
                        setformLoader(false)
                    }
                })
                .catch(error => {
                    HandleError(error.response.data)
                    setformLoader(false)
                })
        }
        else {
            setError("Input Field can't be Blank")
        }
    };

    const body = () => {
        return (
            <>
                <p className="mb-1 text-danger">{Error}</p>
                <input type="file" accept=".xls,.xlsx" onChange={(e) => {
                    setPdf(e.target.files[0])
                }} required />
            </>
        )
    }

    let modelData = Show && body();


    //popup for single user
    const handleShow = (grade_id) => {
        setgrade_id(grade_id)
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
        };
        fetch(Api.baseURL + `/otherusers`, config)
            .then((resp) => resp.json())
            .then((result) => {
                if (result?.status === "success") {
                    console.log(result.data.users, "gdfgcfgfdgfdgfdgd");
                    setDropdowndata({ "options": result.data.users })

                }
            })
            .catch((error) => {
                console.log(error)
            });
        // listing api of other user 
        const config1 = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
            body: JSON.stringify({ "id": id })
        };
        fetch(Api.baseURL + `/list_assign_users/${id}/${grade_id}`, config1)
            .then((resp) => resp.json())
            .then((result) => {
                if (result.status === "success") {
                    // setUserData(result.data)
                    console.log("fgsdjhdsfd", result.data.data);
                    setSelectedDropdowndata(result.data.data)
                }
                if (result.status === "error") {
                    HandleError(result)
                }
            })
    };

    const popupClose = () => {
        setIsShows(false)
        // setFormErrors({})
    }

    const Senddata = (e) => {
        const token = JSON.parse(localStorage.getItem("user-token"));
        setIsShows(false)
        let array = []
        for (let index = 0; index < SelectedValue.length; index++) {
            array.push(SelectedValue[index].id)
        }
        const config = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
            body: JSON.stringify({ "users": array })
        };
        fetch(Api.baseURL + `/assign_users/${id}/${grade_id}`, config)
            .then((resp) => resp.json())
            .then((result) => {
                console.log('hhiuhu');
                if (result?.status === "success") {
                    toast.success(result.message)
                    setDropdowndata({ "options": result.data.users })
                    setReload(!Reload)
                    console.log(result);
                    setreloadkrdebhai(!reloadkrdebhai)
                }
                if (result?.status === "error") {
                    console.log(result);
                    HandleError(result)
                }
            })
            .catch((error) => {
                console.log(error, "fsdfs")
            });
    };

    const onSelect = (selectedList, selectedItem) => {
        setSelectedValue(selectedList)
    }

    const onRemove = (selectedList, removedItem) => {
        setSelectedValue(selectedList)
    }

    const body1 = () => {
        return (
            <>
                <Multiselect
                    className='dataaaaa'
                    options={Dropdowndata.options} // Options to display in the dropdown
                    selectedValues={SelectedDropdowndata} // Preselected value to persist in dropdown
                    onSelect={onSelect} // Function will trigger on select event
                    onRemove={onRemove} // Function will trigger on remove event
                    displayValue="name_email" // Property name to display in the dropdown options
                    showCheckbox={true}
                    placeholder="Search User"

                />

            </>
        )
    }

    let modelData1 = listData ? body1() : null;


    const title = () => {
        return (
            <>
                <div className='d-flex justiy'>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip> Download Sample Excel </Tooltip>}
                    >
                        <a className="btn-theme btn-danger"
                            href={Api.baseURL + `/download_sample_excel`}
                            theme="info"
                            target="_blank"
                        >
                            <span className="material-icons">file_download</span>
                        </a>
                    </OverlayTrigger>
                </div>
            </>
        )
    }

    let title1 = listData ? title() : null;
    return (
        <>
            <DashboardHeader />
            <div className="filter">
                {console.log("log", Show)}
                {Show
                    &&
                    <CommonModal
                        handleClose={handleClose}
                        handleSubmit={Submitdata}
                        show={Show}
                        modelData={modelData}
                        ModalTitle="Upload Excel File"
                        csvIcon={title1}
                        FooterClass="d-flex justify-content-between"
                    />
                }
            </div>
            <div className="mpc-dashboard">
                <DashboardNavbar />
                <div className="dashboard-right-content pt-0">
                    {console.log("dfdfsdfsdf", CSVerror?.length > 0)}
                    {CSVerror?.length > 0 &&
                        <div
                            className="d-flex justify-content-sm-around"
                        >
                            <table className="table" style={{ width: "96%" }}>
                                <thead>
                                    <tr>
                                        <td style={{ textAlign: "left" }}>Row</td>
                                        <td style={{ textAlign: "center" }}>Error Message</td>
                                        <td style={{ textAlign: "left" }}>User Email</td>

                                    </tr>

                                </thead>
                                {CSVerror.map((ele, i) => {
                                    return (
                                        <>  <tbody>
                                            <tr style={{ background: "#dc3545" }}>
                                                <td style={{ textAlign: "left" }}>{ele.row} </td>
                                                <td style={{ textAlign: "center" }}> {ele.error_message}</td>
                                                <td style={{ textAlign: "left" }}>{ele.user_email}</td>
                                            </tr>
                                        </tbody>
                                        </>
                                    )
                                })}
                            </table>
                            <span style={{ width: "10px", fontSize: "20px" }} onClick={() => setCSVerror()}>x</span>
                            {/* <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip> Download Invoice </Tooltip>}
                        >
                            <button className="btn-theme badge badge-pill badge-danger" theme="info"

                            >
                                <span className="material-icons">delete</span>
                            </button>
                        </OverlayTrigger>
                        &nbsp; */}
                        </div>}
                    <div className=" d-flex mobile-block justify-content-sm-between mt-3">
                        <h1 className="main-page-heading with-filter">

                            <span>Manage User</span>
                        </h1>
                        <div className="mobile-block">
                            <div className="d-flex mobile-block justify-content-center">
                                {listData?.data?.plan_id == 3 ?
                                    <p className="d-flex mobile-block align-items-center mb-0 text-center h4">

                                        <span>Total Student Limit
                                            <span data-plugin="counterup">
                                                &nbsp;
                                                {listData?.data?.edited_tot_users}</span> &nbsp;&nbsp;
                                        </span>
                                        |<span>&nbsp;&nbsp;Assigned Users
                                            <span data-plugin="counterup">
                                                &nbsp;
                                                {listData?.data?.activeSubscribers}</span> &nbsp;&nbsp;
                                        </span>
                                    </p> : ""}

                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip> Download Sample Excel </Tooltip>}
                                >
                                    <a className="btn-theme btn-danger"
                                        href={Api.baseURL + `/download_sample_excel`}
                                        theme="info"
                                        target="_blank"
                                    >
                                        <span className="material-icons">file_download</span>
                                    </a>
                                </OverlayTrigger>
                                <button className="btn-theme" theme="info"
                                >
                                    <NavLink
                                        to="#"
                                        onClick={() => navigate(-1)}
                                        theme="info"
                                    >
                                        <span className="material-icons">keyboard_backspace</span>

                                    </NavLink>

                                </button></div>

                        </div>
                    </div>
                    <div className="notifications-table">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: '20%' }}>#</th>
                                        <th scope="col" style={{ width: '20%' }}>Grade Name</th>
                                        {listData?.data?.plan_id == 3 ? "" :
                                            <th scope="col" style={{ width: '20%' }}>Student Limit</th>}

                                        <th scope="col" style={{ width: '20%' }}>Assigned Users</th>
                                        <th scope="col" style={{}}>Action</th>

                                    </tr>
                                </thead>
                                {loading === true ?
                                    <Loader />
                                    :
                                    <>
                                        <tbody>
                                            {listData.data && listData.data.grades.map((ele, i) => {
                                                console.log("this", ele)
                                                return (
                                                    <>
                                                        <tr key={i}>
                                                            <td key={i} scope="col">{i + 1}</td>
                                                            <td scope="col">
                                                                {ele.grade_name}
                                                            </td>
                                                            {listData?.data?.plan_id == 3 ? "" :
                                                                <td scope="col">

                                                                    {ele?.edited_tot_users}
                                                                </td>}

                                                            <td scope="col">
                                                                {ele.assigned_users}
                                                            </td>
                                                            <td scope="col">
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip> View Users </Tooltip>}
                                                                >
                                                                    <button key={i} className="btn-theme" theme="info"
                                                                        onClick={() => ViewContent(id, ele.grade_id)}
                                                                    >
                                                                        <span className="material-icons">visibility</span>
                                                                    </button>
                                                                </OverlayTrigger>
                                                                &nbsp;
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip> Upload User List </Tooltip>}
                                                                >
                                                                    <button className="btn-theme" theme="info"
                                                                        onClick={() => AssignUser(ele.grade_id)}
                                                                    >
                                                                        <span className="material-icons">add</span>
                                                                    </button>
                                                                </OverlayTrigger>
                                                                &nbsp;
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip> Assign User </Tooltip>}
                                                                >
                                                                    <button className="btn-theme" theme="info"
                                                                        onClick={() => {
                                                                            setIsShows(true)
                                                                            handleShow(ele.grade_id)
                                                                        }}
                                                                    >
                                                                        <span className="material-icons">view_cozy</span>
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </td>

                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </>
                                }
                            </table>
                        </div>
                    </div>
                </div>
                <CommonModal
                    handleClose={popupClose}
                    handleSubmit={Senddata}
                    show={Isshows}
                    modelData={modelData1}
                    ModalTitle="Manage User"
                />
            </div>
        </>
    )
}
export default ManageUser