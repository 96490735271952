
const api = {
  baseURL: "",
};
console.log("process.env.NODE_ENV", process.env.REACT_APP_MODE)
if (process.env.REACT_APP_MODE === "development") {
  api.baseURL = "http://elearning-backend.arkasoftwares.in/api/v1"
} else if (process.env.REACT_APP_MODE === "production") {
  api.baseURL = "https://api.msingipack.cloud/api/v1"
}

export default api;
//http://elearning-backend.arkasoftwares.in
//http://192.168.2.80:3334/api/v1
//http://api.msingipack.cloud/api/v1