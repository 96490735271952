import {
  DASHBOARD_STATE,
  USERSIGNUP_SUCCESS,
  USERSLOGIN_SUCCESS,
  USERSLOGOUT_SUCCESS,
} from "../Type.js/Type";
import Api from "../../Api/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HandleError from "../../Components/HandleError/HandleError";
import moment from "moment-timezone";

toast.configure();

const signupApiData = (Formdata) => (dispatch) => {
  const config = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(Formdata),
  };
  fetch(Api.baseURL + `/users/register`, config)
    .then((resp) => resp.json())
    .then((result) => {
      dispatch({
        type: USERSIGNUP_SUCCESS,
        payload: result,
      });
      if (result.status === "success") {
        localStorage.setItem('isSignup', 1);
        toast.success("Registration Successfully");
        setInterval(() => {
          window.location.href = "/login";
        }, 500);
      } else {
        toast.error(result.message);
      }
    });
};

const loginApiData =
  ({ loginFieldData }) =>
    (dispatch) => {
      const config = {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(loginFieldData),
      };
      fetch(Api.baseURL + `/users/login`, config)
        .then((resp) => resp.json())
        .then((result) => {
          dispatch({
            type: USERSLOGIN_SUCCESS,
            payload: result,
          });
          if (result.status === "success") {
            toast.success("Login Successfully");
            localStorage.setItem("user-token", JSON.stringify(result));
            setInterval(() => {
              window.location.href = "/dashboard";
            }, 700);
          } else {
            toast.error(result.message);
          }
        });
    };

const LogoutApiData = () => (dispatch) => {
  const token = JSON.parse(localStorage.getItem("user-token"));
  const config = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token,
    },
  };
  fetch(Api.baseURL + `/users/logout`, config)
    .then((res) => res.json())
    .then((data) => {
      dispatch({
        type: USERSLOGOUT_SUCCESS,
        payload: data,
      });
      localStorage.removeItem("user-token");
    })
    .catch((err) => {
      if (err) {
        console.log("this is err", err);
      }
    });
};

const ContactApiData =
  ({ contactFields }) =>
    (dispatch) => {
      const config = {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(contactFields),
      };
      fetch(Api.baseURL + `/users/contact-us`, config)
        .then((resp) => resp.json())
        .then((result) => {
          if (result.status === "success") {
            toast.success("Contact request submitted successfully");
          } else {
            toast.error(result.message);
          }
        });
    };



const DatePickerAction =
  (contactFields) =>
    (dispatch) => {
      let data0 = {
        ...(contactFields.date && {
          date: moment(contactFields.date).format('YYYY-MM-DD')
        }),
        ...((contactFields.month && contactFields.month != "0") && {
          month: contactFields.month
        }),
        ...(contactFields.year && {
          year: contactFields.year
        })
      }
      console.log("this isisisisisisisisisis err", contactFields);
      const userData = JSON.parse(localStorage.getItem('user-token'));
      const config = {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "BEARER " + userData.data.token,
        },
        body: JSON.stringify(data0)
      };
      fetch(Api.baseURL + `/get_dashboard_stats`, config)
        .then((res) => res.json())
        .then((data) => {
          console.log("this is err", data);
          dispatch({
            type: DASHBOARD_STATE,
            payload: data.data,
          })
          // setLoading(false)
          // setDashboard(data.data)
        })
        .catch((err) => {
          if (err) {
            console.log("this is err", err);
          }
        });
    };



export { signupApiData, loginApiData, LogoutApiData, ContactApiData, DatePickerAction };
