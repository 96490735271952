import { Link } from "react-router-dom";
import Banner from "../../Components/Common/Banner/Banner";
import SectionTitle from "../../Components/Common/SectionTitle/SectionTitle";
import './GettingStarted.css';

const GettingStarted = () => {
    return (
        <>
            <Banner BannerHeading="Getting Started" BannerSubHeading={<p>Follow the Instructions</p>} />
            <section className="getstarted-section pad-t-80 pad-b-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <SectionTitle StepCount={<h4 className="mt-0">STEP 1</h4>} title="Create Account" ShapeImage="heading-clip-2.svg" SubHeading={<p className="what-sec-text pad-t-30">Please <Link to="/sign-up">Sign up</Link> to create your MsingiPACK Cloud Account in a minute.</p>} />
                        </div>
                        <div className="col-lg-6 text-end">
                            <div className="getting-started-img">
                                <img src="/images/sign-up.png" alt="Create Account" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-last">
                            <SectionTitle StepCount={<h4 className="mt-0">STEP 2</h4>} title="Verify Your Email" ShapeImage="heading-clip-2.svg" SubHeading={<p className="what-sec-text pad-t-30">Please check your email inbox and verify your email address.</p>} />
                        </div>
                        <div className="col-lg-6">
                            <div className="getting-started-img">
                                <img src="/images/email-verify.png" alt="Verify Your Email" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <SectionTitle StepCount={<h4 className="mt-0">STEP 3</h4>} title="Login To Access" ShapeImage="heading-clip-2.svg" SubHeading={<p className="what-sec-text pad-t-30">Please log in to access the dashboard, subscription, and more.</p>} />
                        </div>
                        <div className="col-lg-6 text-end">
                            <div className="getting-started-img">
                                <img src="/images/msingipack-dashboard.png" alt="dashboard" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-last">
                            <SectionTitle StepCount={<h4 className="mt-0">STEP 4</h4>} title="Choose A Content Plan" ShapeImage="heading-clip-2.svg" SubHeading={<p className="what-sec-text pad-t-30">Please go to content plans section on the home page and click on subscribe button of the plan you like to use. Check below image for reference.</p>} />
                        </div>
                        <div className="col-lg-6">
                            <div className="getting-started-img">
                                <img src="/images/go-to-plans.png" alt="subscribe" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <SectionTitle StepCount={<h4 className="mt-0">STEP 5</h4>} title="Process The Payment To Subscribe" ShapeImage="heading-clip-2.svg" SubHeading={<p className="what-sec-text pad-t-30">Checkout and process your invoice.</p>} />
                        </div>
                        <div className="col-lg-6 text-end">
                            <div className="getting-started-img">
                                <img src="/images/Invoice_Payment.png" alt="Process the payment" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-last">
                            <SectionTitle StepCount={<h4 className="mt-0">STEP 6</h4>} title="Setup Your API Domain" ShapeImage="heading-clip-2.svg" SubHeading={<p className="what-sec-text pad-t-30">Add your LMS domain and generate your API token to integrate.</p>} />
                        </div>
                        <div className="col-lg-6">
                            <div className="getting-started-img">
                                <img src="/images/LMS_Domains.png" alt="API access" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <SectionTitle StepCount={<h4 className="mt-0">STEP 7</h4>} title="Follow The Instruction On The Documentation" ShapeImage="heading-clip-2.svg" SubHeading={<p className="what-sec-text pad-t-30">Please follow the instruction on API documentation for integrating the API.</p>} />
                        </div>
                        <div className="col-lg-6 text-end">
                            <div className="getting-started-img">
                                <img src="/images/LMS_Documentation.png" alt="documentation" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default GettingStarted;