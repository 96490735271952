import {
    USER_ACCOUNT
} from "../Type.js/Type";

const initialState = [];
const UserDetails = (state = initialState, action) => {
    let data = action.payload;
    switch (action.type) {
        case USER_ACCOUNT:
            return data;
        default:
            return state;
    }
};
export default UserDetails;
