import { combineReducers } from "redux";
import signupApiData from "./AuthUser";
import PakagePlanData from "./PakagePaln";
import UserDetails from "./UserAccount";
import navClass from "./Navclass";
import notification from "./Notification"
import DashboardStash from "./DashboardStash";
const rootReducer = combineReducers({
  signupApiData,
  PakagePlanData,
  UserDetails,
  navClass,
  notification,
  DashboardStash
});

export default rootReducer;
