import React, { useState } from "react";
import "../../../Components/Common/ContactForm/ContactForm.css";
import "../Dashboard.css";
import Button from 'react-bootstrap/Button';
import CommonModal from "../../../Components/Common/Modal/Modal";
import Api from "../../../Api";
import { NavLink, useNavigate } from "react-router-dom";
export const SubscriptionDetailsLista = ({ SubscriptionDetailsList }) => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [subject, setSubject] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (ele, grade) => {
        setShow(true);
        setSubject({ ...ele, grade_category_id: grade })
    }

    const ViewContents = (elem, id) => {
        navigate(`/view-content/${elem.subject_id}`, { state: { grade_category_id: id } })
    }

    const body = (ele) => {
        return (<>
            <ul className="subjects-list">
                {ele.subjects && [...ele.subjects].sort((a, b) => a.subject_id - b.subject_id).map((elem, i) => {
                    return (
                        <li key={i}>
                            <div className="bx-subject" style={{ "cursor": "pointer" }} onClick={() => ViewContents(elem, ele.grade_category_id)}>
                                <img src={`${elem.icon}`} alt="Subject 1" />
                            </div>
                        </li>
                    )
                })}
            </ul>
        </>)
    }

    if (SubscriptionDetailsList?.length !== undefined) {
        return (
            <>
                <CommonModal
                    handleClose={handleClose}
                    handleSubmit={handleShow}
                    show={show}
                    ModalTitle={"View Subject"}
                    modelData={body(subject)}
                    ButtonFalse={true}
                />

                <div className="col-md-12 pt-0 pb-0 p-5">
                    <div className="row">
                        {SubscriptionDetailsList?.map((ele, i) => {
                            return (
                                <div className="col-md-12 d-flex mt-5" key={i}>
                                    <div className="grade-list w-100">
                                        <h2>  {ele.grade_category_name}</h2>
                                        <ul className="list-ul-design " style={{ "listStyle": "none" }}>
                                            {ele.grades && [...ele.grades].sort((a, b) => a.grade_order - b.grade_order)?.map((value, i) => {
                                                return (
                                                    <li key={i}>
                                                        <Button variant="" onClick={() => handleShow(value, ele.grade_category_id)}>
                                                            <img src={`${value.icon}`} alt="Grade 1" />
                                                        </Button>
                                                    </li>
                                                )
                                            })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </>
        );
    } else {
        return "...Loading"
    }
}



