import DashboardNavbar from "../../../Components/DashboardNavbar/DashboardNavbar";
import DashboardHeader from "../../../Components/DashboardHeader/DashboardHeader";
import "../../../Components/Common/ContactForm/ContactForm.css";
import "../Dashboard.css";
import { useEffect, useState } from "react";
import CommonButton from "../../../Components/Common/Buttons/Buttons";
import UserTab from "./Usertab";
import CommonModal from "../../../Components/Common/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "../../../Components/Common/Inputs/Inputs";
import { AddUser, Useraccount, UseraccountUpdate } from "../../../Redux/Action/UserAction";
import { Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import Api from "../../../Api";
import HandleError from "../../../Components/HandleError/HandleError";
import PaginatedItems from "../../../Components/Common/pagination";
import { toast } from "react-toastify";
const UserAccount = () => {
    const myState1 = useSelector((state) => state.UserDetails);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [UserData, setUserData] = useState();
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [isChecked, setIsChecked] = useState();
    const [formValues, setFormValues] = useState({
        email: "",
        password: "",
        name: "",
        mob_no: "",
        username: ""
    });

    /* password show hide */
    const [Password, SetPassword] = useState(false);
    /* checkbox data */
    const [checkBoxData, setcheckBoxData] = useState([])

    // recored per page
    const [Recordperpage, setRecordperpage] = useState(10);
    const [currentPosts, setcurrentPosts] = useState();
    const [currentPage, setcurrentPage] = useState(1);
    const ShowPassword = () => {
        SetPassword(!Password);
    };

    const [reload, setReload] = useState(false)

    const [UsersType, setUsersType] = useState()

    const [Desable, setDesable] = useState(false)
    useEffect(() => {
        dispatch(Useraccount(Recordperpage, currentPage, UsersType));
        setcheckBoxData([])
    }, [reload, Recordperpage, currentPage, UsersType]);

    useEffect(() => {
        setcurrentPosts(myState1?.data?.tot_users)
    }, [myState1]);


    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit && !UserData) {
            dispatch(AddUser(formValues, Recordperpage, currentPage, UsersType));
            setShow(false);
            setFormValues({
                email: "",
                password: "",
                name: "",
                mob_no: "",
                username: ""
            });
            setIsSubmit(false)
        } else if (Object.keys(formErrors).length === 0 && isSubmit && UserData) {
            dispatch(UseraccountUpdate(UserData, Recordperpage, currentPage, UsersType));
            setShow(false);
            setUserData();
            setIsSubmit(false)
        }
    }, [formErrors]);



    /* password show hide */
    const [Passwordvisible, SetPasswordvisible] = useState(false);
    const VisiblePassword = () => {
        SetPasswordvisible(!Passwordvisible);
    };
    /* password show hide */



    const handleShow = () => setShow(true);

    const handleClose = () => {
        setShow(false)
        setUserData()
        setFormValues({
            email: "",
            password: "",
            name: "",
            mob_no: "",
            username: ""
        });
        Desable(false)
        setFormErrors({ email: "" })
    }
    // for add new user

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const Submitdata = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    };

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        // const regex1 = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const regex1 = /^[A-Za-z0-9_-]*$/i;
        // const regex1 = ^[A-Za-z0-9_-]*$;
        if (!values.name) {
            errors.name = "Full Name is required!";
        } else if (!isNaN(values.name)) {
            errors.name = "Please enter Alphabet value";
        } else if (values.name.length < 4 || values.name.length > 40) {
            errors.name = "Name Length Should Be 4 To 40";
        }
        if (!values.username) {
            errors.username = "User Name is required!";
        }
        else if (!regex1.test(values.username)) {
            errors.username = "Enter Vaild Username!";
        }
        else if (values.username.length < 4 || values.username.length > 40) {
            errors.username = "Name Length Should Be 4 To 40";
        }
        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "Enter Vaild Email!";
        } else if (values.email.length < 10) {
            errors.email = "Email Length should Be 50";
        }
        if (!values.mob_no) {
            errors.mob_no = "Mobile No. is required!";
        } else if (isNaN(values.mob_no)) {
            errors.mob_no = "Please enter Numeric value";
        } else if (values.mob_no.length <= 5 ||
            values.mob_no.length > 16) {
            errors.mob_no = "Number should 5 to 16 digit";
        }
        // if (!values.company_name) {
        //     errors.company_name = "Company Name is required!";
        // } else if (values.company_name.length > 50) {
        //     errors.company_name = "Company Name should 50 digit";
        // }
        // if (!values.physical_address) {
        //     errors.physical_address = "Physical Address is required!";
        // } else if (values.physical_address.length > 100) {
        //     errors.physical_address = "Physical Address should 100 digit";
        // }
        // if (!values.postal_address) {
        //     errors.postal_address = "Postal Address is required!";
        // } else if (values.postal_address.length > 100) {
        //     errors.postal_address = "Postal Address should 100 digit";
        // }
        // if (!values.company_email_address) {
        //     errors.company_email_address = "Company Email is required!";
        // } else if (!regex.test(values.company_email_address)) {
        //     errors.company_email_address = "Enter Vaild Email!";
        // }

        // if (!values.company_mob_no) {
        //     errors.company_mob_no = "Company Telephone Number is required!";
        // } else if (isNaN(values.company_mob_no)) {
        //     errors.company_mob_no = "Please enter Numeric value";
        // } else if (
        //     values.company_mob_no.length <= 5 ||
        //     values.company_mob_no.length > 16
        // ) {
        //     errors.company_mob_no = "Number should 5 to 16 digit";
        // }

        if (!values.password) {
            errors.password = "Password is required!";
        } else if (values.password.length < 3) {
            errors.password = "Password must be more than 4 characters";
        } else if (values.password.length > 10) {
            errors.password = "Password cannot exceed more than 16 characters";
        }


        // if (!values.password_confirmation) {
        //     errors.password_confirmation = "Confirm Password is required!";
        // } else if (values.password_confirmation !== values.password) {
        //     errors.password_confirmation = "Confirm Password Should be matched ";
        // }

        // if (!isChecked) {
        //     errors.t_c = "Select term&condition";
        // }
        return errors;
    };

    const EmailCheck = (e) => {
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
            body: JSON.stringify({
                "email": e.target.value
            }),
        };
        fetch(Api.baseURL + `/get_otheruser_by_email`, config)
            .then((resp) => resp.json())
            .then((result) => {
                if (result.status === "success") {
                    if (Object.keys(result.data).length === 0) {
                        console.log("fgsfdgjhgfdsgdfhgshdfghsdfg");
                        setFormValues({
                            email: formValues.email,
                            password: "",
                            name: "",
                            mob_no: "",
                            username: ""
                        })
                        setDesable(false)
                    } else {
                        setFormValues(result.data)
                        setDesable(true)
                    }
                }
                if (result.status === "error") {
                    toast.error(result.message);
                }
            })
            .catch((error) => {
                console.log("hell", error.response);
            });
    }
    const body = () => {
        return (
            <>
                <div>
                    <InputText
                        className="col-2 mb-0"
                        type="email"
                        name="email"
                        value={formValues.email}
                        placeholder="Enter Email"
                        onChange={handleChange}
                        error={formErrors.email}
                        labelText="Email Address"
                        required="true"
                        onBlur={EmailCheck}

                    />

                    <InputText
                        className="col-2 mb-0"
                        type="text"
                        name="username"
                        value={formValues.username}
                        placeholder="Enter User Name"
                        onChange={handleChange}
                        error={formErrors.username}
                        labelText="Username"
                        required="true"
                        isDisabled={Desable}
                    />
                    <InputText
                        className="col-2 mb-0"
                        type="text"
                        name="name"
                        value={formValues.name}
                        placeholder="Enter Full Name"
                        onChange={handleChange}
                        error={formErrors.name}
                        labelText="Full Name"
                        required="true"
                        isDisabled={Desable}
                    />

                    <InputText
                        className="col-2 mb-0"
                        type="number"
                        name="mob_no"
                        value={formValues.mob_no}
                        placeholder="Enter Phone Number"
                        onChange={handleChange}
                        error={formErrors.mob_no}
                        labelText="Phone Number"
                        required="true"
                        isDisabled={Desable}
                    />
                    <InputText
                        type={Password ? "text" : "password"}
                        name="password"
                        placeholder="Enter Password"
                        value={formValues.password}
                        isPassword="true"
                        error={formErrors.password}
                        onChange={handleChange}
                        onClick={ShowPassword}
                        labelText="Password"
                        FormGroupClass="mb-0"
                        className=""
                        // autocomplete={!Desable ? "new-password" : ""}
                        ChangeClass={Password ? "show-hide active" : "show-hide"}
                        required="true"
                        isDisabled={Desable}
                    />
                </div>
            </>
        )
    }

    // edit existing user
    const EditUser = (ele) => {
        setUserData({
            id: ele.id,
            email: ele.email,
            name: ele.name,
            mob_no: ele.mob_no,
            password: ele.password,
            username: ele.username
        })
        setShow(true);
    }
    const handleEdit = (e) => {
        const { name, value } = e.target;
        setUserData({ ...UserData, [name]: value });
    }
    const Submitdata1 = async (e) => {
        let data = await validate1(UserData)
        setFormErrors(data);
        if (Object.keys(data).length === 0) {
            setIsSubmit(true);
        }
    };
    const validate1 = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.name) {
            errors.name = "Name is required!";
        } else if (!isNaN(values.name)) {
            errors.name = "Please enter Alphabet value";
        } else if (values.name.length < 4 || values.name.length > 40) {
            errors.name = "Name Length Should Be 4 To 40";
        }
        if (!values.username) {
            errors.username = "Name is required!";
        } else if (!isNaN(values.username)) {
            errors.username = "Please enter Alphabet value";
        } else if (values.username.length < 4 || values.username.length > 40) {
            errors.username = "Name Length Should Be 4 To 40";
        }
        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "Enter Vaild Email!";
        } else if (values.email.length < 10) {
            errors.email = "Email Length should Be 50";
        }
        if (!values.mob_no) {
            errors.mob_no = "Mobile No. is required!";
        } else if (isNaN(values.mob_no)) {
            errors.mob_no = "Please enter Numeric value";
        } else if (values.mob_no.length <= 5 ||
            values.mob_no.length > 16) {
            errors.mob_no = "Number should 5 to 16 digit";
        }
        return errors;
    };

    const body1 = () => {
        return (
            <>
                <InputText
                    className="col-2 mb-0"
                    type="text"
                    name="email"
                    defaultValue={UserData.email}
                    placeholder="Enter Email Address"
                    onChange={handleEdit}
                    labelText="Email Address"
                    required="true"
                    isDisabled
                />
                <InputText
                    className="col-2 mb-0"
                    type="text"
                    name="username"
                    defaultValue={UserData.username}
                    error={formErrors.username}
                    placeholder="Enter User Name"
                    onChange={handleEdit}
                    labelText="User Name"
                    required="true"
                    isDisabled
                />
                <InputText
                    className="col-2 mb-0"
                    type="text"
                    name="name"
                    defaultValue={UserData.name}
                    placeholder="Enter Full Name"
                    onChange={handleEdit}
                    error={formErrors.name}
                    labelText="Full Name"
                    required="true"
                />

                <InputText
                    className="col-2 mb-0"
                    type="text"
                    name="mob_no"
                    defaultValue={UserData.mob_no}
                    placeholder="Phone Number"
                    onChange={handleEdit}
                    error={formErrors.mob_no}
                    labelText="Phone Number"
                    FormGroupClass="mb-0"
                    required="true"
                />
                <InputText
                    isDisabled
                    className="col-2 mb-0"
                    type={Passwordvisible ? "text" : "password"}
                    isPassword="true"
                    name="password"
                    defaultValue={UserData.password}
                    placeholder="Password"
                    onChange={handleEdit}
                    labelText="Password"
                    FormGroupClass="mb-0"
                    required="true"
                    onClick={VisiblePassword}
                    ChangeClass={Passwordvisible ? "show-hide active" : "show-hide"}
                />
            </>
        )
    }
    let modelData = UserData ? body1() : body();

    const handleClose1 = () => {
        setShow1(false)
        setFormValues({
            email: "",
            password: "",
            name: "",
            mob_no: "",
            username: ""
        });
    }

    const Submitdata2 = () => {
        setShow1(false)
        const data = checkBoxData
        setcheckBoxData([])
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "DELETE",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
            body: JSON.stringify({ users: data })
        };
        fetch(Api.baseURL + `/delete_multiple_users`, config)
            .then((resp) => resp.json())
            .then((result) => {
                console.log("hellooooooooooo", result);
                if (result.status === "success") {
                    setReload(!reload)
                    setShow(false)
                    toast.success("Users delete successfully")
                }
                if (result.status === "error") {
                    HandleError(result)
                }
            })
    }

    const selectUsers = (e) => {
        var data = []
        var users = myState1?.data.users
        if (e.target.checked) {
            users.map((ele, i) => {
                data.push(ele.id)
            })
            setcheckBoxData([...data])
        } else {
            setcheckBoxData([])
            // const updatedDataRequiredField = checkBoxData.filter((value, index) =>  id !== value)
            // setcheckBoxData(updatedDataRequiredField)
        }
    }

    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };



    return (
        <>
            <CommonModal
                handleClose={handleClose}
                handleSubmit={UserData ? Submitdata1 : Submitdata}
                show={show}
                ModalTitle={UserData ? "Edit user" : "Add new user"}
                modelData={modelData}
            />
            <CommonModal
                handleClose={handleClose1}
                handleSubmit={Submitdata2}
                show={show1}
                ModalTitle={"Delete user"}
                modelData="Are you sure you want to delete these users? These will not be retrievable and unassigned from all study materials."
            />
            <DashboardHeader />
            <div className="mpc-dashboard">
                <DashboardNavbar />
                <div className="dashboard-right-content">
                    <h1 className="main-page-heading with-filter">
                        <span>User Account</span>
                        <div className="filter">
                            <select
                                className=" btn btn-sm btn-green"
                                onChange={(e) => setUsersType(e.target.value)}
                            //    style={{width : "150px" ,height : "45px"}}
                            >
                                <option disabled selected>Users Type</option>
                                <>
                                    <option className="custom-drop">
                                        All
                                    </option>
                                    <option className="custom-drop">
                                        Dummy
                                    </option>
                                    <option className="custom-drop">
                                        Manual
                                    </option>
                                </>
                            </select>


                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip
                                    style={checkBoxData.length != "0" && { display: "none" }}
                                >Please select the users  </Tooltip>}
                            >
                                <span className="m-2">
                                    <CommonButton
                                        isButton="true"
                                        BtnType="submit"
                                        BtnColor="green btn-sm"
                                        BtnText="Delete Selected  User"
                                        onClick={() => checkBoxData.length != "0" ? setShow1(true) : ""}
                                    />
                                </span>

                            </OverlayTrigger>

                            {/* </OverlayTrigger>                            */}
                            <CommonButton
                                isButton="true"
                                BtnType="submit"
                                BtnColor="green btn-sm"
                                BtnText="Add User"
                                onClick={() => setShow(true)}
                            />
                        </div>
                    </h1>
                    <div className="table-responsive">
                        <table className="table table-centered mb-0" id="btn-editable">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="togg-btn">
                                            <input type="checkbox"
                                                checked={checkBoxData.length > 0 && checkBoxData.length == myState1?.data?.users.length ? true : false}
                                                onClick={(e) => {
                                                    selectUsers(e)

                                                }}
                                            />
                                            <select
                                                className=""
                                                onChange={(e) => {
                                                    setRecordperpage(e.target.value)
                                                    setcurrentPage(1)
                                                }}
                                            //    style={{width : "150px" ,height : "45px"}}
                                            >
                                                <option disabled selected></option>
                                                <>
                                                    <option className="custom-drop" value="10">
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </option>
                                                    <option className="custom-drop" value="50">
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;50&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </option>
                                                    <option className="custom-drop" value="100">
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;100&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </option>
                                                    <option value="250">
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;250&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </option>
                                                    <option className="custom-drop" value="500">
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;500&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </option>
                                                    <option className="custom-drop" value="1000">
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </option>
                                                </>
                                            </select>
                                            {/* <button>▼</button>
                                            <div className="dropdown-bx">
                                                <span className="custom-drop">
                                                    10
                                                </span>
                                                <span className="custom-drop">
                                                    50
                                                </span>
                                                <span className="custom-drop">
                                                    100
                                                </span>
                                                <span >
                                                    250
                                                </span>
                                                <span className="custom-drop">
                                                    500
                                                </span>
                                                <span className="custom-drop">
                                                    1000
                                                </span>
                                            </div> */}
                                        </div>
                                    </th>
                                    <th>S.N.</th>
                                    <th>#Username</th>
                                    <th>Full Name</th>
                                    <th>Email Address</th>
                                    <th>Phone No.</th>
                                    <th>Status</th>
                                    <th style={{ textAlign: "center" }}>Action</th>
                                    {/* <th>Company name</th>
                                    <th>Physical Location</th>
                                    <th>Postal Address</th>
                                    <th>Company Email Address</th>
                                    <th>Company Website</th>
                                    <th>Company Contacts</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                <UserTab EditUser={EditUser} setcheckBoxData={setcheckBoxData} checkBoxData={checkBoxData}
                                    Recordperpage={Recordperpage} currentPage={currentPage} UsersType={UsersType} />
                                <tr className="mobile-hide" style={{ height: "150px" }}></tr>
                            </tbody>
                        </table>
                    </div>
                    {currentPosts > Recordperpage &&
                        <PaginatedItems
                            Recordperpage={Recordperpage}
                            List={currentPosts}
                            HandlePageClick={HandlePageClick}
                        />
                    }
                </div>
            </div>

        </>
    );
};

export default UserAccount;
