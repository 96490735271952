import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import SectionTitle from "../../Components/Common/SectionTitle/SectionTitle";
import { signupApiData } from "../../Redux/Action/AuthAction";
import { InputText } from "../../Components/Common/Inputs/Inputs";
import CommonButton from "../../Components/Common/Buttons/Buttons";
import "../Login/Login.css";
import ReCAPTCHA from "react-google-recaptcha";
const SignUp = () => {
  const dispatch = useDispatch();

  /* Password show hide */
  const [Password, SetPassword] = useState(false);
  const [isChecked, setIsChecked] = useState();
  const [confirmPassword, SetConfirmPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [submitCaptcha, setSubmitCaptcha] = useState(false)
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    name: "",
    website_url: "",
    mob_no: "",
    company_name: "",
    physical_address: "",
    postal_address: "",
    city_town: "",
    company_email_address: "",
    company_mob_no: "",
    t_c: false,
    captcha_token: ""
  });
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      dispatch(signupApiData(formValues));
    }
  }, [formErrors]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.name) {
      errors.username = "Name is required!";
    } else if (!isNaN(values.name)) {
      errors.username = "Please enter Alphabet value";
    } else if (values.name.length < 4 || values.name.length > 40) {
      errors.username = "Name Length Should Be 4 To 40";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "Enter Vaild Email!";
    }
    if (!values.mob_no) {
      errors.mob_no = "Mobile No. is required!";
    } else if (isNaN(values.mob_no)) {
      errors.mob_no = "Please enter Numeric value";
    } else if (values.mob_no.length <= 5 ||
      values.mob_no.length > 16) {
      errors.mob_no = "Number should 5 to 16 digit";
    }
    if (!values.company_name) {
      errors.company_name = "Company Name is required!";
    } else if (values.company_name.length > 50) {
      errors.company_name = "Company Name should 50 digit";
    }
    if (!values.physical_address) {
      errors.physical_address = "Physical Address is required!";
    } else if (values.physical_address.length > 100) {
      errors.physical_address = "Physical Address should 100 digit";
    }
    if (!values.postal_address) {
      errors.postal_address = "Postal code is required!";
    } else if (values.postal_address.length > 100) {
      errors.postal_address = "Postal code should 100 digit";
    }
    if (!values.city_town) {
      errors.city_town = "city town is required!";
    } else if (values.city_town.length > 100) {
      errors.city_town = "city town should 100 digit";
    }
    if (!values.company_email_address) {
      errors.company_email_address = "Company Email is required!";
    } else if (!regex.test(values.company_email_address)) {
      errors.company_email_address = "Enter Vaild Email!";
    }

    if (!values.company_mob_no) {
      errors.company_mob_no = "Company Telephone Number is required!";
    } else if (isNaN(values.company_mob_no)) {
      errors.company_mob_no = "Please enter Numeric value";
    } else if (
      values.company_mob_no.length <= 5 ||
      values.company_mob_no.length > 16
    ) {
      errors.company_mob_no = "Number should 5 to 16 digit";
    }
    if (!values.password) {
      errors.password = "Password is required!";
    } else if (values.password.length < 3) {
      errors.password = "Password must be more than 4 characters";
    } else if (values.password.length > 16) {
      errors.password = "Password cannot exceed more than 16 characters";
    }

    if (!values.password_confirmation) {
      errors.password_confirmation = "Confirm Password is required!";
    } else if (values.password_confirmation !== values.password) {
      errors.password_confirmation = "Confirm Password Should be matched ";
    }

    if (!isChecked) {
      errors.t_c = "Select term&condition";
    }
    return errors;
  };

  const ShowPassword = () => {
    SetPassword(!Password);
  };
  const ShowConfirmPassword = () => {
    SetConfirmPassword(!confirmPassword);
  };

  const onChange = (value) => {
    console.log("Captcha value:", value);
    setSubmitCaptcha(true)
    setFormValues({ ...formValues, captcha_token: value });
  }
  /* Password show hide */
  return (
    <>
      {/* <GoogleReCaptchaProvider
        reCaptchaKey="6Lceux0gAAAAABciKCJm__F6FLiHKxHQYw3VuItX"
        language="en-AU"
        useRecaptchaNet="[optional_boolean_value]"
        useEnterprise="[optional_boolean_value]"
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'body', // optional, default to "head", can be "head" or "body",
          nonce: undefined // optional, default undefined
        }}
      > */}
      <section className="hero-section authentication-banner">
        <div className="banner-bg">
          <img src="/images/banner-top-vector.svg" alt="banner-vectors" />
        </div>
        <div className="banner-btm-bg">
          <img src="/images/banner-btm-vector.svg" alt="banner-vectors" />
        </div>
        <div className="container-fluid">
          <SectionTitle
            CustomClass="text-center pad-b-30"
            title="Sign Up"
            ShapeImage="heading-clip-1.svg"
            SubHeading={
              <p className="what-sec-text">Provide details to create an account</p>
            }
          />
          <div className="authentication-bx sign-up-authentication">
            <form
              onSubmit={handleSubmit}
              onKeyPress={(e) =>
                e.key === "Enter"
                  ? handleSubmit
                  : ""
              }
            >
              <div className="row">
                <div className="form-group col-lg-12 mb-2">
                  <h2>Contact details <small>(<span className="text-danger">*</span> Required)</small> </h2>
                </div>
                <div className="col-lg-6">
                  <InputText
                    type="text"
                    name="name"
                    value={formValues.name}
                    placeholder="Enter Full Name"
                    onChange={handleChange}
                    error={formErrors.username}
                    labelText="Full Name"
                    required="true"
                  />
                </div>
                <div className="col-lg-6">
                  <InputText
                    type="text"
                    name="email"
                    value={formValues.email}
                    placeholder="Enter Email Address"
                    onChange={handleChange}
                    error={formErrors.email}
                    labelText="Email Address"
                    required="true"
                  />
                </div>
                <div className="col-lg-6">
                  <InputText
                    type="text"
                    name="mob_no"
                    value={formValues.mob_no}
                    placeholder="Enter Mobile No."
                    onChange={handleChange}
                    error={formErrors.mob_no}
                    labelText="Mobile No."
                    required="true"
                  />
                </div>
                <div className="form-group col-lg-12 mb-2 mt-4">
                  <h2>Company Information</h2>
                </div>
                <div className="col-lg-6">
                  <InputText
                    type="text"
                    name="company_name"
                    value={formValues.company_name}
                    placeholder="Enter Registered Company name"
                    onChange={handleChange}
                    error={formErrors.company_name}
                    labelText="Registered Company Name"
                    required="true"
                  />
                </div>
                <div className="col-lg-6">
                  <InputText
                    type="text"
                    name="physical_address"
                    value={formValues.physical_address}
                    placeholder="Enter Physical Location"
                    onChange={handleChange}
                    error={formErrors.physical_address}
                    labelText="Physical Location"
                    required="true"
                  />
                </div>
                <div className="col-lg-6">
                  <InputText
                    type="text"
                    name="postal_address"
                    value={formValues.postal_address}
                    placeholder="Enter Postal Address&Code"
                    onChange={handleChange}
                    error={formErrors.postal_address}
                    labelText="Postal Address"
                    required="true"
                  />
                </div>
                <div className="col-lg-6">
                  <InputText
                    type="text"
                    name="city_town"
                    value={formValues.city_town}
                    placeholder="Enter City / Town"
                    onChange={handleChange}
                    error={formErrors.city_town}
                    labelText="City / Town"
                    required="true"
                  />
                </div>
                <div className="col-lg-6">
                  <InputText
                    type="text"
                    name="company_email_address"
                    value={formValues.company_email_address}
                    placeholder="Enter Company Email Address"
                    onChange={handleChange}
                    error={formErrors.company_email_address}
                    labelText="Company Email Address"
                    required="true"
                  />
                </div>
                <div className="col-lg-6">
                  <InputText
                    type="text"
                    name="website_url"
                    value={formValues.website_url}
                    placeholder="Enter Company Website"
                    onChange={handleChange}
                    error={formErrors.website_url}
                    labelText="Company Website"
                  />
                </div>
                <div className="col-lg-6">
                  <InputText
                    type="text"
                    name="company_mob_no"
                    value={formValues.company_mob_no}
                    placeholder="Enter Company Telephone Contacts"
                    onChange={handleChange}
                    error={formErrors.company_mob_no}
                    labelText="Company Telephone Contacts"
                    required="true"
                  />
                </div>
                <div className="col-lg-6">
                  <InputText
                    type={Password ? "text" : "password"}
                    name="password"
                    value={formValues.password}
                    placeholder="Enter Password"
                    onChange={handleChange}
                    FormGroupClass="hasicon"
                    isPassword="true"
                    onClick={ShowPassword}
                    ChangeClass={Password ? "show-hide active" : "show-hide"}
                    error={formErrors.password}
                    labelText="Password"
                    required="true"
                  />
                </div>
                <div className="col-lg-6">
                  <InputText
                    type={confirmPassword ? "text" : "password"}
                    name="password_confirmation"
                    onChange={handleChange}
                    placeholder="Enter Confirm Password"
                    value={formValues.password_confirmation}
                    FormGroupClass="hasicon"
                    isPassword="true"
                    onClick={ShowConfirmPassword}
                    ChangeClass={
                      confirmPassword ? "show-hide active" : "show-hide"
                    }
                    error={formErrors.password_confirmation}
                    labelText="Confirm Password"
                    required="true"
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="custom-check-block">
                  <ReCAPTCHA
                    sitekey="6LcfzyEgAAAAAGA0Ibj4iKxHA8Ou5HWc1julTQ7r"
                    onChange={(value) => onChange(value)}
                  />
                </div>
              </div>
              <div className="form-group">

                <div className="custom-check-block">
                  <input
                    type="checkbox"
                    className="form-check-input d-none"
                    id="exampleCheck1"
                    checked={isChecked}
                    onChange={() => (
                      setIsChecked(!isChecked),
                      setFormValues({ ...formValues, t_c: true })
                    )}
                  />
                  <label htmlFor="exampleCheck1" className="custom-check-label">
                    <span className="text-danger">*</span> I agree to the{" "}
                    <a href="https://www.msingipack.cloud/terms-of-use" target="_blank">Terms &amp; Conditions</a> of MsingiPACK
                    Cloud.{" "}
                  </label>
                </div>
                <p className="input-errors mt-2">{formErrors.t_c}</p>
              </div>
              <div className="nonce"></div>
              <div className="form-group text-center">
                <CommonButton
                  isButton="true"
                  BtnType="submit"
                  BtnColor="green w-50"
                  BtnText="Sign Up"
                  disabled={!submitCaptcha}
                />
              </div>
              <p className="create-account">
                Already have any account? <Link to="/login">Login</Link>
              </p>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
