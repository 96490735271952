import {
  PAKAGE_SUCCESS
} from "../Type.js/Type";
import Api from "../../Api/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const PakageListing = () => (dispatch) => {
  const config = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
    },
  };
  fetch(Api.baseURL + `/fetch_pricing_details`, config)
    .then((res) => res.json())
    .then((data) => {
      console.log("res", data);
      dispatch({
        type: PAKAGE_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      // if (err) {
      //   dispatch(ForceLogoutApiData(err))
      // }
    });
};

export { PakageListing };
