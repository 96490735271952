import { useEffect, useState } from 'react';
import DashboardNavbar from '../../../Components/DashboardNavbar/DashboardNavbar';
import DashboardHeader from '../../../Components/DashboardHeader/DashboardHeader';
import Api from '../../../Api';
import "../../../Components/Common/ContactForm/ContactForm.css";
import Loader from '../../../Components/Common/loader';
import PaginatedItems from "../../../Components/Common/pagination";
import { UserNotification } from '../../../Redux/Action/UserAction';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import HandleError from '../../../Components/HandleError/HandleError';
import { toast } from 'react-toastify';

const Notifications = () => {
    const [listData, setlistData] = useState()
    const [currentPosts, setcurrentPosts] = useState();
    const [currentPage, setcurrentPage] = useState(1);
    const [loading, setLoading] = useState(true)
    const [loadings, setLoadings] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
        };
        fetch(Api.baseURL + `/users/notificationlist?page_no=${currentPage}`, config)
            .then((resp) => resp.json())
            .then((result) => {
                console.log(result, "res")
                setlistData(result.data.notifications)
                setcurrentPosts(result.data.tot_notifications)
                setLoading(false)
                dispatch(UserNotification());
                if (result.status === "error") {
                    // HandleError(result)
                }
            })
    }, [currentPage, loadings])

    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };

    const SendEmailPopUp = data => {
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "DELETE",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
        };
        fetch(Api.baseURL + `/delete_user_notification/${data}`, config)
            .then((resp) => resp.json())
            .then((result) => {

                if (result.status === "success") {
                    toast.success(result.message)
                    setLoadings(!loadings)
                    console.log(result, "res")
                }
                if (result.status === "error") {
                    HandleError(result)
                    console.log(result, "res")
                }
            })
    }
    return (
        <>
            <DashboardHeader />
            <div className="mpc-dashboard">
                <DashboardNavbar />
                <div className="dashboard-right-content">
                    <h1 className="main-page-heading with-filter">
                        <span>Notifications</span>
                        <div className="filter">
                        </div>
                    </h1>
                    <div className="notifications-table">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: '5%' }}>#</th>
                                        <th scope="col" style={{ width: '20%' }}>Notification Title</th>
                                        <th scope="col" style={{ width: '45%' }}>Description</th>
                                        <th scope="col" style={{ width: '10%' }}>Time</th>
                                        <th scope="col" style={{ width: '10%' }}></th>
                                        <th scope="col" style={{ width: '20%' }}>Action</th>
                                    </tr>
                                </thead>
                                {loading === true ?
                                    <Loader />
                                    :
                                    <>
                                        {listData?.length != "0" ? listData.map((ele, i) => {
                                            return (<>
                                                <tbody>
                                                    <tr>
                                                        <td scope="col">{i + 1 + (currentPage - 1) * 10}</td>
                                                        <td scope="col">{ele.title}</td>
                                                        <td scope="col" className='max-width'>{ele.message}</td>
                                                        <td scope="col">{ele.time_diff}</td>
                                                        <td >
                                                            {ele.type == "RENEW SUBSCRIPTION" ?

                                                                <span onClick={() => { navigate("/my-subscription") }} className={`badge badge-pill badge-success`}>
                                                                    Renew Subscription</span>

                                                                :

                                                                null}
                                                        </td>
                                                        <td scope="col">
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip> Delete </Tooltip>}
                                                            >
                                                                <button className="btn-danger"
                                                                    onClick={() => { SendEmailPopUp(ele.notification_user_id) }}
                                                                >
                                                                    <span className="material-icons">
                                                                        delete
                                                                    </span>
                                                                </button>
                                                            </OverlayTrigger>
                                                            &nbsp;
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </>)
                                        }) : <tbody>
                                            <tr>
                                                <td colSpan="15" className="text-center noRec-found"> No record found</td>
                                            </tr>
                                        </tbody>}
                                    </>
                                }
                            </table>
                        </div>
                        {currentPosts > 10 &&
                            <PaginatedItems
                                List={currentPosts}
                                HandlePageClick={HandlePageClick}
                            />}
                    </div>
                </div>
            </div>
        </>
    )
}
export default Notifications;
