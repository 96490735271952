import React, { useState } from "react";
import "./ContentPlans.css";

const EachSubject = ({ PakageDetails, prize, PakageId }) => {
    console.log(PakageId, "PakageId");
    return (
        <>
            {PakageId === "0" ?
                <span className="subject-price">Each Subject Ksh.{prize} </span> : PakageId === "2" ?
                    <span className="subject-price">Each Package Ksh.{prize}</span> : <span className="subject-price">Each Grade Ksh.{prize}</span>
            }
        </>
    )
}
export default EachSubject;