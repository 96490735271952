import './Inputs.css';


export const InputText = (props) => {
    return (
        <div className={`form-group ${props.FormGroupClass}`}>
            {props.labelText && <label>
                {props.labelText}
                {
                    props.required &&
                    <span className="text-danger"> *</span>
                }
            </label>}
            <div className='hasicon'>
                <input
                    step={props.step}
                    type={props.type}
                    name={props.name}
                    value={props.value}
                    defaultValue={props.defaultValue}
                    className={`form-control ${props.className}`}
                    placeholder={props.placeholder}
                    id={props.id}
                    onChange={props.onChange}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    hidden={props.isHidden}
                    disabled={props.isDisabled}
                    autoComplete="off"
                    min={props.min}
                    ref={props.ref}
                    size={props.size}
                // autocomplete={props.autocomplete}
                />
                {
                    props.isPassword &&
                    <span
                        className={props.ChangeClass}
                        onClick={props.onClick}
                    >
                    </span>
                }
            </div>
            {props.error &&
                <p className='input-errors'>{props.error}</p>
            }
        </div>
    );
}

export const SelectBox = (props) => {
    return (
        <div className={`form-group ${props.FormGroupClass}`}>
            <select
                name={props.name}
                className={`form-control ${props.className}`}
                id={props.id}
                onChange={props.onChange}
                required={props.required}
            >
                {
                    props.optionData
                }
            </select>
        </div>
    );
}