import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from '../Pages/Home/Home'
import Pricing from '../Pages/Pricing/Pricing'
import ContentPlans from '../Pages/ContentPlans/ContentPlans'
import ContactUs from '../Pages/ContactUs/ContactUs'
import GettingStarted from '../Pages/GettingStarted/GettingStarted'
import ApiDocumentation from '../Pages/ApiDocumentation/ApiDocumentation'
import ApiIntegrations from '../Pages/ApiIntegrations/ApiIntegrations'
import OrderDetails from '../Pages/OrderDetails/OrderDetails'
import PageNotFound from '../Pages/PageNotFound/404'
import ForgotPassword from '../Pages/ForgotPassword/ForgotPassword'
import Invoice from '../Pages/Invoice/Invoice'
import ThankYou from '../Pages/ThankYou/ThankYou'
import Login from '../Pages/Login/Login'
import SignUp from '../Pages/SignUp/SignUp'
import MySubscription from '../Pages/Dashboard/MySubscription/MySubscription'
import MyProfile from '../Pages/Dashboard/MyProfile/MyProfile'
import UnAuthUser from '../Pages/UnAuthUser'
import PrivateRoute from '../Components/PrivateRoute/PrivateRoute'
import UserAccount from '../Pages/Dashboard/UserAccount/UserAccount'
import ResetPassword from '../Pages/ResetPssword'
import SubscriptionDetails from '../Pages/Dashboard/MySubscription/SubscriptionDetails'
import Dashborad from '../Pages/Dashboard/Dashborad/Dashborad'
import AssignUser from '../Pages/Dashboard/AssignUser/Assignusers'
import ViewContent from '../Pages/Dashboard/ViewContent/ContentView'
import LoginLogs from '../Pages/Dashboard/Reports/LoginLogs'
import Documentation from '../Pages/Dashboard/Documentation/Documentation'
import ApiKeys from '../Pages/Dashboard/ApiKeys/ApiKeys'
import Notifications from '../Pages/Dashboard/Notifications/Notifications'
import Settings from '../Pages/Dashboard/Settings/Settings'
import UpgradeUserList from '../Pages/Dashboard/UpgradeUserRequest/UpgradeUserList'
import Faq from '../Components/Common/FaqSection/FaqSection'
import FreeTrial from '../Pages/Dashboard/FreeTrial/freeTrial'
import TermsofUse from '../Pages/Terms of Use/TermsofUse'
import PrivacyPolicy from '../Pages/PrivacyPolicy/PrivacyPolicy'
import RefundPolicy from '../Pages/RefundPolicy/RefundPolicy'
import About from '../Pages/About/About'
import UnAuthAccont from '../Pages/UnAuthAccount'
import RenewInvoice from '../Pages/renew Invoice/Invoice'
import ManageUser from '../Pages/Dashboard/manageuser/manageuser'
import Lmsdomains from '../Pages/Dashboard/Lmsdomain/Lmsdomain'
import LMSDocumentation from '../Pages/Dashboard/Lms Documentation/LMSDocumentation'
import IncrementedUser from "../Pages/Dashboard/incremented_user/incrementeduser";
const RoutesJS = () => {
  return (
    <React.Suspense fallback={<p> Loading...</p>}>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/pricing" exact element={<Pricing />} />
        <Route path="/content-plans" exact element={<ContentPlans />} />
        <Route path="/content-plans/:id" element={<ContentPlans />} />
        <Route path="/contact-us" exact element={<ContactUs />} />
        <Route path="/getting-started" exact element={<GettingStarted />} />
        <Route path="/api-documentation" exact element={<ApiDocumentation />} />
        <Route path="/integrations" exact element={<ApiIntegrations />} />
        <Route path="/order-details" exact element={<OrderDetails />} />
        <Route path="/thank-you" exact element={<ThankYou />} />
        <Route path="/:pathName" element={<PageNotFound />} />
        <Route path="/sign-up" exact element={<SignUp />} />
        <Route path="/invoice" exact element={<Invoice />} />
        <Route path="/renew-invoice/:id" exact element={<RenewInvoice />} />
        <Route path="/forgot-password" exact element={<ForgotPassword />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/verify-user/:id" exact element={<UnAuthUser />} />
        <Route path="/verify-account/:id" exact element={<UnAuthAccont />} />
        <Route path="/reset-password/:id" exact element={<ResetPassword />} />
        <Route path="/FAQs" exact element={<Faq />} />
        <Route path="/terms-of-use" exact element={<TermsofUse />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route path="/refund-policy" exact element={<RefundPolicy />} />
        <Route path="/about" exact element={<About />} />

        <Route element={<PrivateRoute />}>
          <Route path="/my-subscription" exact element={<MySubscription />} />
          <Route path="/user-account" exact element={<UserAccount />} />
          <Route path="/my-profile" exact element={<MyProfile />} />
          <Route path="/lms-domain" exact element={<Lmsdomains />} />
          <Route path="/Subscription-details/:id" exact element={<SubscriptionDetails />} />
          <Route path="/dashboard" exact element={<Dashborad />} />
          <Route path="/assigned-user/:id/:grade_id" exact element={<AssignUser />} />
          <Route path="/view-content/:id" exact element={<ViewContent />} />
          <Route path="/login-logs" exact element={<LoginLogs />} />
          <Route path="/documentation" exact element={<Documentation />} />
          <Route path="/lms-documentation" exact element={<LMSDocumentation />} />
          <Route path="/api-keys" exact element={<ApiKeys />} />
          <Route path="/notifications" exact element={<Notifications />} />
          <Route path="/settings" exact element={<Settings />} />
          <Route path="/upgrade-request/:id" exact element={<UpgradeUserList />} />
          <Route path="/free-trial-request" exact element={<FreeTrial />} />
          <Route path="/manage-user/:id" exact element={<ManageUser />} />
          <Route path="/incremented-user/:id" exact element={<IncrementedUser />} />
        </Route>
      </Routes>
    </React.Suspense>
  );
};
export default RoutesJS;
