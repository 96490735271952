import { Modal } from 'react-bootstrap';
import Button from "../Buttons/Buttons";
import "./Modal.css"

const CommonModal = ({ handleClose, handleSubmit, show, modelData, ModalTitle, Name, ModalClass, FooterClass, disabled, csvIcon, ButtonFalse, isDisabled }) => {
    return (
        <>
            {modelData && <Modal className={ModalClass} show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                {ModalTitle && <Modal.Header closeButton>
                    <div className="modal-title h4">{ModalTitle}</div>
                </Modal.Header>}
                <Modal.Body>
                    {<>
                        {modelData}
                    </>}
                </Modal.Body>
                <Modal.Footer className={FooterClass} >
                    {csvIcon &&
                        <div className="modal-title h4">{csvIcon}</div>}
                    {!ButtonFalse &&
                        <div className='footer-modal-btn'>
                            <Button
                                isButton="true"
                                BtnColor="red btn-sm"
                                BtnText="Cancel"
                                onClick={handleClose}
                            />
                            <Button
                                disabled={disabled}
                                isButton="true"
                                BtnColor="green btn-sm"
                                BtnText={Name ? Name : "Submit"}
                                // disabled={isDisabled}
                                onClick={handleSubmit}
                            />
                        </div>
                    }

                </Modal.Footer>
            </Modal>}
        </>
    );
};
export default CommonModal;
{/* <CommonModal
                show={show}
                onHide={handleClose}
                keyboard={false}
                size="md"
                onClick={handleClose}
                className="confirmation-popup"
                ModalTitle={ModalTitle}
                ModalData={
                    <>
                        {modelData}
                        <div className="clnicaltrial-detail-ftr">
                            <Button
                                isButton="true"
                                BtnColor="red w-50"
                                BtnText="No"
                                onClick={handleClose}
                            />
                            <Button
                                isButton="true"
                                BtnColor="green w-50"
                                BtnText={Name ? Name : "Yes"}
                                onClick={handleSubmit}
                            />
                        </div>
                    </>}
            /> */}