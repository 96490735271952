import "../../../Components/Common/ContactForm/ContactForm.css";
import "../Dashboard.css";
import { useEffect, useState } from "react";
import Api from "../../../Api";
import { toast } from "react-toastify";
import { useNavigate, NavLink } from "react-router-dom";
import { OverlayTrigger, Toast, Tooltip } from "react-bootstrap";
import CommonModal from "../../../Components/Common/Modal/Modal";
import { InputText, SelectBox } from "../../../Components/Common/Inputs/Inputs";
import HandleError from "../../../Components/HandleError/HandleError";

const SubscriptionsList = ({ SubscriptionList, SubscriptionDetails, UserList, UpgradeUser, setLoading }) => {
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [SubscriptionId, setSubscriptionId] = useState();
    const [isReload, setIsReload] = useState(false);
    // RENEW plan request 
    const [formErrors, setFormErrors] = useState([{ mob_no: "User is required!" }, { mob_no: "Please enter Numeric value", }
        , { mob_no: "Number of user should be More then 0" },
    { mob_no: "Number of user should be Integer" }]);
    const [DropDownErrors, setDropDownErrors] = useState("");
    const [UserTotal, setUserTotal] = useState(1)
    const [dropdown, setdropDown] = useState(0)
    const [ListOfGradeThatInSubscription, setListOfGradeThatInSubscription] = useState()
    const [TypeOfSubscription, setTypeOfInSubscription] = useState()
    let navigate = useNavigate();
    console.log({ UserTotal, formErrors }, "UserTotalUserTotal432423");
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user-token'));
        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + userData.data.token,
            }
        };
        fetch(Api.baseURL + `/subscription_details/${SubscriptionId}`, config)
            .then((res) => res.json())
            .then((data) => {
                console.log(data.data.data, "res.data.grades");
                setListOfGradeThatInSubscription(data.data.data)
                if (data.data.data.package_name != "Gold") {
                    let data1 = data.data.data.grades.map((ele, i) => {
                        if (show) {
                            return {
                                "grade_id": ele.grade_id,
                                "users": 1
                            }
                        } if (show1) {
                            return {
                                "grade_id": ele.grade_id,
                                "users": 0
                            }
                        }

                    })
                    setUserTotal(data1)
                }
                else {
                    setUserTotal(1)
                }
            })
            .catch((err) => {
                if (err) {
                    console.log("this is err", err);
                }
            });
    }, [SubscriptionId])

    const UpgradeUsers = (id) => {
        setSubscriptionId(id)
        setShow(true);
    }

    const handleClose = () => {
        setShow(false)
        setdropDown(0)
        setUserTotal(0)
        setSubscriptionId()
        setListOfGradeThatInSubscription()
        setDropDownErrors("")
    }

    const Submitdata = (e) => {
        if (dropdown != "0" && UserTotal != undefined && UserTotal != NaN && UserTotal != 0 && UserTotal != null) {
            console.log("Fsdfsfsdf", UserTotal);
            setDropDownErrors("")
            let data;
            let check = false
            console.log("Fsdfsdfsf", typeof Number(UserTotal), ListOfGradeThatInSubscription.package_name);
            if (ListOfGradeThatInSubscription.package_name == "Gold") {
                // toast.success("lashdka4535j")
                check = true
            } else {
                for (let index = 0; index < UserTotal.length; index++) {
                    if (UserTotal && (UserTotal[index]?.users == "" || UserTotal[index]?.users == undefined)) {
                        toast.error("Please input number the number of students in selected grades.");
                        break
                    } else {
                        check = true
                        continue
                    }
                }
            }
            if (check) {
                fun()
            }
            function fun() {
                if (ListOfGradeThatInSubscription && ListOfGradeThatInSubscription.package_name === "Gold") {
                    data = { "no_of_users": UserTotal, "tenure": dropdown, "grades": undefined, url: "subscription_details_for_renew" }
                } else {
                    data = { "grades": UserTotal, "no_of_users": undefined, "tenure": dropdown, url: "subscription_details_for_renew" }
                }
                const token = JSON.parse(localStorage.getItem("user-token"));
                const config = {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json",
                        Accept: "application/json",
                        Authorization: "BEARER " + token.data.token,
                    },
                    body: JSON.stringify(data)
                };
                fetch(Api.baseURL + `/subscription_details_for_renew/${SubscriptionId}`, config)
                    .then((res) => res.json())
                    .then((data1) => {
                        if (data1.data.status == "success") {
                            navigate(`/renew-invoice/${SubscriptionId}`, { state: data })
                        }
                        if (data1.status == "error") {
                            HandleError(data1)
                        }
                    })
            }
        } else {
            setDropDownErrors("Please Select Plan Tenure")
        }
    };

    const EnterNumber = (e) => {
        e.preventDefault();
        let data = validate(e)
        console.log("ksajkdhasdsas", data);
        // formErrors.map((ele, i) => {
        //     if (!data.mob_no) {
        //         console.log("fsdfjfsdfdsfsfsdff");
        //     }
        // })
        // setFormErrors(data);
    }

    const validate = (e) => {
        if (e.target.name === "name") {
            setUserTotal(parseInt(e.target.value))
        }
        else {
            // errors.name = e.target.name
            let data = UserTotal.map((ele, i) => {
                if (i == e.target.name) {
                    console.log("FsfsdFsfsdFsfsdFsfsd", parseInt(Math.abs(e.target.value)));
                    ele.users = parseInt(Math.abs(e.target.value))
                    return ele
                } else if (!ele.users) {
                    ele.users = ""
                    return ele
                }
                return ele
            })

            setUserTotal(data)
        }
    };

    const body1 = () => {
        return (
            <>
                {ListOfGradeThatInSubscription && ListOfGradeThatInSubscription.package_name === "Gold" ?
                    <InputText
                        className="col-2 mb-0"
                        type="number"
                        name="name"
                        placeholder="Enter Number of user"
                        onChange={EnterNumber}
                        value={UserTotal}
                        error={formErrors.mob_no}
                        labelText="Number of user"
                    />
                    : ""}
                <SelectBox
                    name="name"
                    FormGroupClass="mb-0"
                    onChange={e => {
                        setdropDown(e.target.value);
                    }}
                    required="required"

                    optionData={
                        <>
                            <option selected disabled value="0">Select Plan Tenure</option>
                            <option value="monthly">Monthly</option>
                            <option value="termly" >Termly</option>
                            <option value="yearly">Yearly</option>
                        </>
                    }
                />
                < p className='input-errors' > {DropDownErrors}</p >
                {ListOfGradeThatInSubscription && ListOfGradeThatInSubscription.package_name === "Gold"
                    ?
                    ""
                    :
                    ListOfGradeThatInSubscription?.grades &&
                    ListOfGradeThatInSubscription?.grades.map((ele, i) => {
                        return (
                            <>
                                <div className="contentPlans-col">
                                    <div className="row align-items-center">
                                        <div className="col-sm-6">
                                            <h2 className="contentPlans-title">
                                                <i />
                                                {ele.grade_name}
                                            </h2>
                                        </div>
                                        <label
                                            className="col-lg-6"
                                            style={{
                                                justifyContent: "flex-end",
                                                textAlign: "right",
                                                display: "flex"
                                            }}>
                                            <div
                                                className="hasicon form-group mb-0"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-end"
                                                }}>
                                                {console.log("fsdkfhksdjfshdfds", i, UserTotal && Number(UserTotal[i]?.users))}
                                                <InputText
                                                    step={1}
                                                    type="text"
                                                    className="form-control undefined "
                                                    autoComplete="off"
                                                    value={UserTotal && (UserTotal[i]?.users ? parseInt(Math.abs(UserTotal[i]?.users)) : "")}
                                                    min={1}
                                                    onChange={EnterNumber}
                                                    name={i}
                                                    error={UserTotal && (UserTotal[i]?.users == "" || UserTotal[i]?.users == undefined) ? formErrors[0].mob_no
                                                        : isNaN(UserTotal && UserTotal[i]?.users) == true ? formErrors[1].mob_no :
                                                            ""}
                                                    style={{ width: "50%", height: "4rem" }}
                                                />
                                                <div className="">No. of users</div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
                {console.log(ListOfGradeThatInSubscription, "ListOfGradeThatInSubscription")}
            </>
        )
    }
    let modelData = ListOfGradeThatInSubscription && body1();


    const handleClose1 = () => {
        setShow1(false)
        setListOfGradeThatInSubscription()
        setdropDown(0)
        setUserTotal(0)
        setSubscriptionId()
        setDropDownErrors("")
    }

    const Submitdata1 = (e) => {
        if (UserTotal != undefined && UserTotal != NaN && UserTotal != 0 && UserTotal != null) {
            console.log("Fsdfsfsdf", UserTotal);
            let data;
            let check = false
            console.log("Fsdfsdfsf", typeof Number(UserTotal), ListOfGradeThatInSubscription.package_name);
            if (ListOfGradeThatInSubscription.package_name == "Gold") {
                // toast.success("lashdka4535j")
                check = true
            } else {
                for (let index = 0; index < UserTotal.length; index++) {
                    if (UserTotal && (UserTotal[index]?.users == "" || UserTotal[index]?.users == undefined)) {
                        continue
                    } else {
                        check = true
                        continue
                    }
                }
            }
            if (check) {
                fun()
            } else if (!check) {
                toast.error("Please input number of students alteast in any 1 grade.");
            }
            function fun() {
                if (ListOfGradeThatInSubscription && ListOfGradeThatInSubscription.package_name === "Gold") {
                    data = { "no_of_users": UserTotal, "grades": undefined, url: "subscription_details_for_increment" }
                } else {
                    data = { "grades": UserTotal, "no_of_users": undefined, url: "subscription_details_for_increment" }
                }
                const token = JSON.parse(localStorage.getItem("user-token"));
                const config = {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json",
                        Accept: "application/json",
                        Authorization: "BEARER " + token.data.token,
                    },
                    body: JSON.stringify(data)
                };
                fetch(Api.baseURL + `/subscription_details_for_increment/${SubscriptionId}`, config)
                    .then((res) => res.json())
                    .then((data1) => {
                        if (data1.data.status == "success") {
                            navigate(`/renew-invoice/${SubscriptionId}`, { state: data })
                        }
                        if (data1.status == "error") {
                            HandleError(data1)
                        }
                    })
            }
        }

    };

    const UpgradeUsers1 = (id) => {
        setSubscriptionId(id)
        setShow1(true);
    }

    const body2 = () => {
        return (
            <>
                {ListOfGradeThatInSubscription && ListOfGradeThatInSubscription.package_name === "Gold" ?
                    <>
                        {console.log("Fdsfsfsdfs", isNaN(UserTotal))}
                        <InputText
                            className="col-2 mb-0"
                            type="number"
                            name="name"
                            placeholder="Enter Number of user"
                            onChange={EnterNumber}
                            value={UserTotal}
                            labelText="Number of user"
                        />
                        <p className='input-errors'> {(UserTotal == undefined) || (UserTotal == "") || isNaN(UserTotal)
                            || (UserTotal == 0) || (UserTotal == null) ? formErrors[0].mob_no : ""}</p>
                    </>
                    : ""}
                {ListOfGradeThatInSubscription && ListOfGradeThatInSubscription.package_name === "Gold"
                    ?
                    ""
                    :
                    ListOfGradeThatInSubscription?.grades &&
                    ListOfGradeThatInSubscription?.grades.map((ele, i) => {
                        return (
                            <>
                                <div className="contentPlans-col">
                                    <div className="row align-items-center">
                                        <div className="col-sm-6">
                                            <h2 className="contentPlans-title">
                                                <i />
                                                {ele.grade_name}
                                            </h2>
                                        </div>
                                        <label
                                            className="col-lg-6"
                                            style={{
                                                justifyContent: "flex-end",
                                                textAlign: "right",
                                                display: "flex"
                                            }}>
                                            <div
                                                className="hasicon form-group mb-0"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-end"
                                                }}>
                                                {console.log("fsdkfhksdjfshdfds", i, UserTotal && Number(UserTotal[i]?.users))}
                                                <InputText
                                                    step={1}
                                                    defaultValue={UserTotal[i]?.users}
                                                    type="number"
                                                    className="form-control undefined "
                                                    autoComplete="off"
                                                    min={1}
                                                    onChange={EnterNumber}
                                                    name={i}
                                                    style={{ width: "50%", height: "4rem" }}
                                                />
                                                <div className="">No. of users</div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                {console.log(ListOfGradeThatInSubscription, "ListOfGradeThatInSubscription")}
            </>
        )
    }
    let modelData1 = ListOfGradeThatInSubscription && body2();

    console.log("devdevdevdev", UserTotal)
    if (SubscriptionList.subs.length > 0) {
        return (
            <>
                <CommonModal
                    handleClose={handleClose}
                    handleSubmit={Submitdata}
                    show={show}
                    modelData={modelData}
                    Name={"Send Request"}
                    ModalTitle="Renew Plan Request"
                />
                <CommonModal
                    handleClose={handleClose1}
                    handleSubmit={Submitdata1}
                    show={show1}
                    modelData={modelData1}
                    Name={"Send Request"}
                    ModalTitle="Increase User Request"
                />
                {console.log(UserTotal, "UserTotalUserTotalUserTotal")}
                {SubscriptionList.subs.map((ele, i) => {
                    return (
                        <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>
                                {ele?.user_name}
                            </td>
                            <td>{ele?.invoice_id}</td>
                            <td>{ele?.edited_tot_users}</td>
                            <td>{ele?.plan_name}</td>

                            <td>{ele?.plan_tenure[0]?.toUpperCase() + ele?.plan_tenure.substring(1)}</td>
                            <td>{ele.status === 0 ? <span className={`badge badge-pill badge-success`}> Paid - Renewed</span>
                                : ele.is_plan_expired === 1 ? <span className={`badge badge-pill badge-danger`}> Expired</span>
                                : ele.is_payment_done === "Paid" ? <span className={`badge badge-pill badge-success`}>
                                    {ele?.is_payment_done}</span> :
                                    <span className={`badge badge-pill badge-warning`}>
                                        {ele?.is_payment_done}</span>}
                            </td>
                            <td>{ele?.start_date ? ele?.start_date : "-"}</td>
                            <td>{ele?.end_date ? ele?.end_date : "-"}</td>
                            <td>{ele?.apply_date}</td>
                            <td style={{ textAlign: "center", whiteSpace: "noWrap" }}>
                                {ele.is_plan_expired ? null :
                                    <>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip> View </Tooltip>}
                                        >
                                            <button
                                                className="btn-theme"
                                                theme="info"
                                                onClick={ele.is_payment_done === "Paid" || ele.is_payment_done === "Processing" ? () => {
                                                    SubscriptionDetails(ele.id)
                                                } : () => {
                                                    toast.warning("Account inactive due to renewal or non payment")
                                                }
                                                }
                                            >
                                                <span className="material-icons">visibility</span>
                                            </button>
                                        </OverlayTrigger>
                                        &nbsp;
                                    </>}

                                {ele?.is_incremented_subscription == 0 ?
                                    <> <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip> Download Invoice </Tooltip>}
                                    >
                                        <a className="btn-danger"
                                            href={Api.baseURL + `/download_invoice/${ele.id}`}
                                            theme="info"
                                            target="_blank"
                                        >
                                            <span className="material-icons">file_download</span>
                                        </a>
                                    </OverlayTrigger>
                                        &nbsp;</> : ""}

                                <>
                                {ele.status===1  && <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip> Manage User </Tooltip>}
                                    >
                                        <button
                                            className="btn-success"
                                            style={{ backgroundColor: "green" }}
                                            theme="info"
                                            onClick={ele.is_payment_done === "Paid" || ele.is_payment_done === "Processing" ? () => {
                                                UserList(ele.id);
                                            } : () => {
                                                toast.warning("Account inactive due to renewal or non payment")
                                            }
                                            }
                                        >
                                            <span className="material-icons">view_cozy</span>
                                        </button>
                                    </OverlayTrigger> }
                                   
                                    &nbsp;</>

                                {(ele.show_renew_button == 1 && ele.is_plan_renewed == 0 ) || (ele.is_plan_expired == 1 && ele.is_plan_renewed == 0)
                                    ?
                                    <> <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip> Renewal Plan </Tooltip>}
                                    >
                                        <button
                                            className="btn-success"
                                            style={{ backgroundColor: "#0d6efd" }}
                                            theme="info"
                                            onClick={() => UpgradeUsers(ele?.id)}
                                        >
                                            <span className="material-icons">autorenew

                                            </span>
                                        </button>
                                    </OverlayTrigger>
                                        &nbsp;
                                    </>
                                    :
                                    ""
                                }
                                {ele?.is_plan_expired ||ele.status===0 ? null :
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip> Increase User </Tooltip>}
                                    >
                                        <button className="btn-theme" theme="info"
                                            onClick={ele.is_payment_done === "Paid" || ele.is_payment_done === "Processing" ? () => {
                                                UpgradeUsers1(ele?.id)
                                            } : () => {
                                                toast.warning("Account inactive due to renewal or non payment")
                                            }
                                            }
                                        >
                                            <span className="material-icons">add</span>
                                        </button>
                                    </OverlayTrigger>}
                                &nbsp;
                                {ele?.is_incremented_subscription == 0 || ele.is_plan_expired == 1 ? null :
                                    < OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip> View extend users list </Tooltip>}
                                    >
                                        <button className="btn-theme" theme="info"
                                            onClick={ele.is_payment_done === "Paid" || ele.is_payment_done === "Processing" ? () => {
                                                navigate(`/incremented-user/${ele.id}`)
                                            } : () => {
                                                toast.warning("Account inactive due to renewal or non payment")
                                            }
                                            }
                                        >
                                            <span className="material-icons">send</span>
                                        </button>
                                    </OverlayTrigger>}
                                &nbsp;
                            </td>
                        </tr>)
                })
                }
            </>
        );
    } else {
        return (
            <>
                <tr>
                    <td colSpan="15" className="text-center noRec-found"> No record found</td>
                </tr>
            </>)
    }
}

export default SubscriptionsList;   
