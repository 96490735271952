export const USERSIGNUP_SUCCESS = "USERSIGNUP_SUCCESS";
export const USERSLOGIN_SUCCESS = "USERSLOGIN_SUCCESS";
export const USERSLOGOUT_SUCCESS = "USERSLOGOUT_SUCCESS";
export const PAKAGE_SUCCESS = "PAKAGE_SUCCESS";

export const USER_DETAILS = "USER_DETAILS";

export const USER_ACCOUNT = "USER_ACCOUNT";

export const Navclass = "NavClass";

export const USER_NOTIFICATION = "USER_NOTIFICATION";

export const FREE_TRIAL_VARIABLE = "FREE_TRIAL_VARIABLE";

export const DASHBOARD_STATE = "DASHBOARD_STATE";