import React from 'react';

const Loader = ({ isMsg }) => {
  return (
    <>
      <div className="text-center d-flex flex-column justify-content-center align-items-center">
        <div className="spinner-border m-6" role="status">
        </div>
        {isMsg && <span className="sr-only pt-4">Loading...</span>}
      </div>
    </>
  );
};

export default Loader;
