import React from "react";


const ContentHeader = ({ id }) => {
    return (

        <div className="col-lg-7">
            {id === "0" ? <div className="alert alert-primary mb-0" role="alert">

                Please select either Monthly, Termly or Yearly plan then select your subjects. (Minimum 1 subject MUST be selected). Enter the number of users that will access the content and continue.
            </div> :
                id === "2" ? <div className="alert alert-primary mb-0" role="alert">
                    Please select either Monthly, Termly or Yearly plan , Enter the number of users that will access the content and continue

                </div> :
                    <div className="alert alert-primary mb-0" role="alert">
                        Please select either Monthly, Termly or Yearly plan then
                        select your subjects. (Minimum 1 Grade MUST be
                        selected). Enter the number of users that will access the
                        content and continue.
                    </div>
            }
        </div>

    )
};

export default ContentHeader;

