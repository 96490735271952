import React, { useEffect, useState } from "react"
import { Accordion } from 'react-bootstrap';
import SectionTitle from '../../Components/Common/SectionTitle/SectionTitle';
import './FaqSection.css';
import Api from "../../Api";
import HandleError from "../../Components/HandleError/HandleError";
import renderHTML from 'react-render-html';
const PrivacyPolicy = () => {
    const [data, serData] = useState()
    useEffect(() => {
        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
            },
        };
        fetch(Api.baseURL + `/content`, config)
            .then((resp) => resp.json())
            .then((result) => {
                serData(result.data.contents)
                if (result.status === "error") {
                    HandleError(result)
                }
            })
    }, [])

    return (
        <>
            <section className="faq-section pad-b-80">
                <div className="container">
                    <SectionTitle CustomClass="text-center pad-b-50" title={data && <>
                        {/* {parse(data[0].content)} */}
                        {renderHTML(data[1].title)}
                        {/* <div className="post__description" dangerouslySetInnerHTML={{ __html: data[0].content }} /> */}
                    </>}
                        ShapeImage="heading-clip-1.svg" />
                    <div CustomClass="text-center pad-b-50" className="text-center pad-b-50"
                        ShapeImage="heading-clip-1.svg" >
                        {data && <>
                            {/* {parse(data[0].content)} */}
                            {renderHTML(data[1].content)}
                            {/* <div className="post__description" dangerouslySetInnerHTML={{ __html: data[0].content }} /> */}
                        </>}
                    </div>
                </div>
            </section>
        </>
    );
}

export default PrivacyPolicy;