import React, { useEffect } from 'react';
import { toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';
import Api from "../Api/index";
const UnAuthAccont = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const config = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
            },
        };
        fetch(Api.baseURL + `/verify-account/${id}`, config)
            .then((resp) => resp.json())
            .then((result) => {
                // dispatch({
                //     type: USERSIGNUP_SUCCESS,
                //     payload: result,
                // });
                if (result.status === "success") {
                    toast.success("Email Verification Complete You Can login Now");
                    localStorage.removeItem("user-token")
                    navigate("/login");
                } else {
                    toast.error(result.message);
                }
            });
    }, [])

    return (
        <>
            <div>...Loading</div>
        </>
    );
}

export default UnAuthAccont;