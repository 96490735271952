import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { DatePickerAction } from "../../../Redux/Action/AuthAction";

class RangeDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: "",
      month: null,
      defaultMonthSet: 1,
      year: new Date().getFullYear()
    };

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.date !== this.state.date || prevState.month !== this.state.month || prevState.year !== this.state.year) {
      console.log(this.state, "update workinh ");
      // dispatch(DatePicker({ "year": year }))
      this.props.DatePickerAction(this.state);
    }
  }
  handleStartDateChange(value) {
    console.log('aaaaaaa ' + value)
    if (value[1] === "date") {
      this.setState({
        ...this.state,
        ...{ date: value[0], month: null, defaultMonthSet: 1, year: "" }
      });
    }
    else if (value[1] === "month") {
      this.setState({
        ...this.state,
        ...{ month: value[0], date: "", defaultMonthSet: 0 }
      });
    }
    else if (value[1] === "year") {
      var date = moment(value[0]).format('YYYY')
      console.log("update workinh", date);
      this.setState({
        ...this.state,
        ...{ year: date, date: "", defaultMonthSet: 0 }
      });
    }
  }

  handleEndDateChange(value) {
    this.setState({
      ...this.state,
      endDate: new Date(value)
    });
  }


  monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  render() {
    return (
      <>
        <div className="row">
          <div className="col-lg-4 mt-5 mt-lg-0">
            <DatePicker
              selected={this.state.date}
              onChange={(date) => this.handleStartDateChange([date, "date"])}
              placeholderText="Select Date"
            />
          </div>
          <div className="col-lg-4 mt-5 mt-lg-0">
            <select
              className="form-control custom-select"
              onChange={(e) => this.handleStartDateChange([e.target.value, "month"])}
            >
              <option className="mb-2 form-control" selected={this.state.defaultMonthSet == 1 ? true : false} value={`0`}>Select Month</option>
              {this.monthNames.map((obj, i) => {
                return (
                  <>
                    <option className="mb-2 form-control" value={i + 1} key={i}>
                      {obj}
                    </option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="col-lg-4 mt-5 mt-lg-0">
            <DatePicker
              value={`${this.state.year}`}
              onChange={(date) => this.handleStartDateChange([date, "year"])}
              showYearPicker
              dateFormat="yyyy"
              placeholderText="Select Year"
            />
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, { DatePickerAction })(RangeDatePicker);
