import { Accordion } from 'react-bootstrap';
import SectionTitle from '../SectionTitle/SectionTitle';
import './FaqSection.css';
// import Accordion from 'react-bootstrap/Accordion';

const Faq = () => {
    return (
        <>
            <section className="faq-section pad-b-80 pad-t-80">
                <div className="container">
                    <SectionTitle className="text-center mb-5" title="  Frequently Asked Questions (FAQs)" ShapeImage="heading-clip-1.svg" />

                    <Accordion defaultActiveKey="0" className='mt-5'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>What is MsingiPACK Cloud?</Accordion.Header>
                            <Accordion.Body>
                                MsingiPACK Cloud enables users to access quality digital content that has been
                                aligned to both Competency Based Curriculum (CBC) as well as the current 8-4-4
                                curriculum easily through an API. The solution allows integration of any platform
                                such as Web app (Includes Web browser), Mobile apps, Desktop apps, Learning
                                Management System (LMS), etc to consume and make use of the content
                                according to their needs by offering flexible and affordable plans. This is ideal for
                                schools, digital publishers, companies or individuals offering digital learning
                                solutions.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Who should use MsingiPACK Cloud?</Accordion.Header>
                            <Accordion.Body>
                                We have yet to meet a customer who does not consider easy access, flexible and
                                affordable digital content a priority. MsingiPACK cloud platform can be used by;
                                <ul>
                                    <li>
                                        Schools who want to give teachers and students access to interactive
                                        digital content accessible from anywhere anytime and from any
                                        device.
                                    </li>
                                    <li>
                                        Digital Publishers who have difficulty finding approved interactive
                                        digital content ready to interface and use.
                                    </li>
                                    <li>
                                        App Developers who innovate and build Apps for any platform such as
                                        Learning Management Systems(LMS) Android and Windows Apps or
                                        IOS. We provide an API to securely integrate.
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>What are the benefits of using MsingiPACK Cloud?</Accordion.Header>
                            <Accordion.Body>
                                MsingiPACK Cloud offers the following benefits;
                                <ul>
                                    <li>
                                        Easy access to a large repository of KICD approved interactive learning
                                        content and exam material.
                                    </li>
                                    <li>
                                        Flexible plans with large savings on learning and exam revision material.
                                    </li>
                                    <li>
                                        Software As a Service (SaaS) Cloud platform. Integrate to your existing
                                        platforms (Apps, LMS etc.) through an API
                                    </li>
                                    <li>
                                        Manage, track and monitor subscriptions, plans and usage. Easy-to-use
                                        interface from any connecting device.
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>How can I get started with MsingiPACK Cloud?</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li>
                                        Sign up/Register from the home page. Ensure you verify your email address
                                        by clicking on verify via email received.
                                    </li>
                                    <li>
                                        Login and choose a content plan that suits you from the Content Plans
                                        Menu.
                                    </li>
                                    <li>
                                        Receive an invoice &amp; make a payment.
                                    </li>
                                    <li>
                                        Add students and assign them to your subscription via Dashboard.
                                    </li>
                                    <li>
                                        Students will automatically receive a link to start accessing content.
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>What can I do with my account?</Accordion.Header>
                            <Accordion.Body>
                                With one account you can purchase and manage multiple subscriptions.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>How do I manage my subscription(s)?</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li>
                                        Once you sign up you will be able to access your Dashboard as follows;
                                    </li>
                                    <li>
                                        Click the Dashboard button to redirect to Dashboard home.
                                    </li>
                                    <li>
                                        The Dashboard will display in summary the total number of subscriptions
                                        Paid and Unpaid.
                                    </li>
                                    <li>
                                        Click on My Subscriptions on the side bar to view all the details about your
                                        subscription.
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>How can students access interactive digital content?</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li>
                                        From the dashboard click on User-Account to Add Student Accounts.
                                    </li>
                                    <li>
                                        Then click on Assign User from the subscription you want to add
                                        students.
                                    </li>
                                    <li>
                                        Once students are added they will automatically receive an email with
                                        a link to start accessing content.
                                    </li>
                                    <li>
                                        NOTE: Students can belong to multiple subscriptions.
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Can I integrate it with my existing LMS or App?</Accordion.Header>
                            <Accordion.Body>
                                Yes. We provide documentation to guide Developers integrate the API script to your
                                existing platform or app.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>What do I need to access digital content?</Accordion.Header>
                            <Accordion.Body>
                                Interactive digital content can be accessed using the following devices;
                                <ul>
                                    <li>
                                        Mobile Smart Phone (Android or IoS)
                                    </li>
                                    <li>Android or Windows Tablet</li>
                                    <li>PC/Laptop</li>
                                </ul>
                                NOTE: Smartphones screen size minimum specifications should be atleast
                                5.75’x2.78 or higher for a good learning experience.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9">
                            <Accordion.Header>How do I view my Dashboard?</Accordion.Header>
                            <Accordion.Body>
                                You need to login to be able to access your dashboard. After logging in, Click on the
                                Dashboard button at the top right section of MsingiPACK landing page. <br />
                                This will display your dashboard with the following;
                                <ul>
                                    <li>
                                        Total number of subscriptions (Paid &amp; Unpaid)
                                    </li>
                                    <li>Total number of users</li>
                                    <li>Total number of learning materials</li>
                                    <li>Overall usage activity</li>
                                    <li>Average session time</li>
                                    <li>User Manager</li>
                                    <li>Session logs view</li>
                                    <li>API documentation</li>
                                    <li>Notifications</li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="10">
                            <Accordion.Header>How do I make a payment?</Accordion.Header>
                            <Accordion.Body>
                                Choose your Content Plan and the number of users you are targeting and
                                complete the process to generate the invoice. Note your subscription will be inactive
                                until a payment is received. The Cloud team will process your payment as per
                                invoice and activate your subscription. You will receive a notification once this is
                                completed and proceed to access and manage your subscription.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="11">
                            <Accordion.Header>Can I renew my subscription?</Accordion.Header>
                            <Accordion.Body>
                                Yes. You will be notified when your subscription is due. Click Renew and follow the
                                steps provided to renew your subscription.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="12">
                            <Accordion.Header>What if my subscription expires?</Accordion.Header>
                            <Accordion.Body>
                                All services associated with the subscription will stop working. This includes access
                                to content to all assigned users.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="13">
                            <Accordion.Header>Need help about MsingiPACK Cloud?</Accordion.Header>
                            <Accordion.Body>
                                For more enquiries you can contact us through the following; <br />
                                Tel: +254111044600 <br />
                                Sales Enquiries: sales@msingipack.com <br />
                                Support: support@msingipack.com <br />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                </div>
            </section>
        </>
    );
}

export default Faq;