import { useEffect, useState } from 'react';
import DashboardNavbar from '../../../Components/DashboardNavbar/DashboardNavbar';
import DashboardHeader from '../../../Components/DashboardHeader/DashboardHeader';
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Api from '../../../Api';
import "../../../Components/Common/ContactForm/ContactForm.css";
import Loader from '../../../Components/Common/loader';
import ReactApexChart from 'react-apexcharts'
import api from '../../../Api';
import { useDispatch, useSelector } from "react-redux";

const Documentation = () => {
    const UserDetails = useSelector((state) => state.signupApiData);
    const [Variable, setVariable] = useState()

    useEffect(() => {
        //http://msingipack-student.arkasoftwares.in/api/v1/
        if (UserDetails) {
            // setVariable(`http://msingipack-student.arkasoftwares.in/api/v1/verify-user/${UserDetails?.data?.api_key}`)
            setVariable(`https://student.msingipack.cloud/verify-student/12345`)
        }
    }, [UserDetails])
    return (
        <>
            <DashboardHeader />
            <div className="mpc-dashboard">
                <DashboardNavbar />
                <div className="dashboard-right-content">
                    <h1 className="main-page-heading with-filter">
                        <span>Documentation</span>
                        <div className="filter">
                        </div>
                    </h1>
                    <div className="dashboard-details">
                        <div className="row">
                            <div className="col-md-12 col-xl-12">
                                <div className="repeat-white-bx">
                                    <div className="row">
                                        <div className="col-md-12 col-xl-12">
                                            <h3 className="text-dark">API KEY</h3>
                                            <h4>{UserDetails && UserDetails?.data?.api_key}</h4>
                                        </div>
                                    </div> {/* end row*/}
                                </div> {/* end widget-rounded-circle*/}

                            </div>{/* end col*/}
                            <div className="col-md-12 col-xl-12">
                                <div className="repeat-white-bx">
                                    <div className="row">
                                        <div className="col-md-12 col-xl-12">
                                            <h3 className="text-dark">URL</h3>
                                            <h4>{`${Api.baseURL}/access-creds/${UserDetails?.data?.api_key}`}</h4>
                                        </div>
                                    </div> {/* end row*/}
                                </div> {/* end widget-rounded-circle*/}

                            </div>{/* end col*/}
                            <div className="col-md-12 col-xl-12">
                                <div className="repeat-white-bx">
                                    <div className="row">
                                        <div className="col-md-12 col-xl-12">
                                            <h3 className="text-dark">Sample Response</h3>
                                            <div className="highlight-outer">
                                                <pre>
                                                    <pre className="highlight prettyprint" >{JSON.stringify({
                                                        "status": "success",
                                                        "message": "",
                                                        "data": [
                                                            {
                                                                "name": "student_name",
                                                                "email": "student_name@anydomain.com",
                                                                "url": Variable
                                                            }
                                                        ]
                                                    }, null, 2)}</pre>
                                                </pre>
                                            </div>     </div>
                                    </div> {/* end row*/}
                                </div> {/* end widget-rounded-circle*/}

                            </div>{/* end col*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Documentation