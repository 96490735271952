import { useEffect, useState } from 'react';
import DashboardNavbar from '../../../Components/DashboardNavbar/DashboardNavbar';
import DashboardHeader from '../../../Components/DashboardHeader/DashboardHeader';
import "../../../Components/Common/ContactForm/ContactForm.css";
import { useSelector } from "react-redux";

const LMSDocumentation = () => {
    const UserDetails = useSelector((state) => state.signupApiData);
    const [Variable, setVariable] = useState()

    useEffect(() => {
        //http://msingipack-student.arkasoftwares.in/api/v1/
        if (UserDetails) {
            setVariable(`http://msingipack-student.arkasoftwares.in/api/v1/verify-user/${UserDetails?.data?.api_key}`)
        }
    }, [UserDetails])
    return (
        <>
            <DashboardHeader />
            <div className="mpc-dashboard">
                <DashboardNavbar />
                <div className="dashboard-right-content">
                    <h1 className="main-page-heading with-filter">
                        <span>LMS Documentation</span>
                        <div className="filter">
                        </div>
                    </h1>
                    <div className="dashboard-details">
                        <div className="row">
                            <div className="col-md-12 col-xl-12">
                                <div className="repeat-white-bx">
                                    <div className="row">
                                        <div className="col-md-12 col-xl-12">
                                            <h3 className="text-dark">LMS Domain Token</h3>
                                            <h4>
                                                {`{{domain_token}}   -> This defines the domain token generated for specific domain.`}

                                                {/* {UserDetails && UserDetails?.data?.lms_token} */}
                                            </h4>
                                        </div>
                                    </div> {/* end row*/}
                                </div> {/* end widget-rounded-circle*/}
                            </div>{/* end col*/}
                            <div className="col-md-12 col-xl-12" style={{ display: 'none' }}>
                                <div className="repeat-white-bx">
                                    <div className="row">
                                        <div className="col-md-12 col-xl-12">
                                            <div className='d-flex'>
                                                <div className='m-2'>
                                                    <h3 className="text-dark">CSS </h3>
                                                    <a className="btn-danger p-1 m-1"
                                                        href={`http://elearning-backend.arkasoftwares.in/LMS_assets/elearning-api.css`}
                                                        theme="info"
                                                        download
                                                    // target="_blank"
                                                    >
                                                        <span className="material-icons">file_download</span>
                                                    </a>
                                                </div>
                                                <div className='m-2'>
                                                    <h3 className="text-dark">javascript </h3>
                                                    <a className="btn-danger p-1 m-1"
                                                        href={`http://elearning-backend.arkasoftwares.in/LMS_assets/elearning-api.js`}
                                                        theme="info"
                                                        target="_blank"
                                                        download="elearning-api.js"
                                                    >
                                                        <span className="material-icons">file_download</span>
                                                    </a>
                                                </div>
                                                <div className='m-2'>
                                                    <h3 className="text-dark">jquery </h3>
                                                    <a className="btn-danger p-1 m-1"
                                                        href={`http://elearning-backend.arkasoftwares.in/LMS_assets/jquery.blockUI.js`}
                                                        theme="info"
                                                        download
                                                    // target="_blank"
                                                    >
                                                        <span className="material-icons">file_download</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end row*/}
                                </div>
                                {/* end widget-rounded-circle*/}
                                {/* {console.log(UserDetails, "UserDetailsUserDetails ")} */}
                            </div>
                            {/* end col*/}
                            <div className="col-md-12 col-xl-12">
                                <div className="repeat-white-bx">
                                    <div className="row">
                                        <div className="col-md-12 col-xl-12">
                                            <h3 className="text-dark">Sample HTML</h3>
                                            <div className="highlight-outer">
                                                <pre>
                                                    <pre className="highlight prettyprint" >
                                                        {`
<!DOCTYPE html>
<html>
<head>
	<link href="https://api.msingipack.cloud/LMS_assets/elearning-api.css" rel="stylesheet" type="text/css">
	<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
	<script type="text/javascript" src="https://api.msingipack.cloud/LMS_assets/elearning-api.js"></script>
	<script src="https://api.msingipack.cloud/LMS_assets/jquery.blockUI.js"></script>
</head>
<body>
	<div id="lmsHtmlMaindiv" class="container">
		<div id="lmsHtmlInnerdiv" class="border-saprate"></div>
	</div>
	<script type="text/javascript">
		var domainUnqToken = {{domain_token}};
        var domainUrl = window.location.origin;
    </script>
</body>
</html>
`}
                                                    </pre>
                                                </pre>

                                            </div>     </div>
                                    </div> {/* end row*/}
                                </div> {/* end widget-rounded-circle*/}

                            </div>{/* end col*/}
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}
export default LMSDocumentation