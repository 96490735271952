import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './OrderDetails.css';
import { Currency } from '../../Currency';

const OrderTotal = ({ cartdata, flag, isshow, subtotalprices }) => {
    const [cartData, setcartData] = useState(cartdata)
    const location = useLocation();
    const [UserCost, setUsercost] = useState()
    const [tax, settax] = useState()
    const [discount, setdiscount] = useState()
    const [finalPrice, setFinalprice] = useState()

    useEffect(() => {
        if (cartdata && !cartData) {
            setcartData(cartdata)
            setUsercost(cartdata.priceperuser * cartdata.users)
        } else if (!isshow) {
            const cartdata = JSON.parse(localStorage.getItem('cart_item'))
            setcartData(cartdata)
        }
        if (cartData.PakageId == 2) {
            setdiscount((((UserCost * parseFloat(subtotalprices)) * cartData.discount_per) / 100))
        } else {
            setdiscount((((parseFloat(subtotalprices)) * cartData.discount_per) / 100))
        }
        // settax((((UserCost * parseFloat(subtotalprices)) * cartData.tax_per) / 100))
    }, [flag, cartdata, UserCost])

    useEffect(() => {
        if (cartData.PakageId == 2) {
            settax((((parseFloat(subtotalprices) - discount) * cartData.tax_per) / 100))
        } else {
            settax((((parseFloat(subtotalprices) - discount) * cartData.tax_per) / 100))
        }
    }, [discount, subtotalprices])
    console.log("sub total", cartData);
    useEffect(() => {
        if (cartData.PakageId == 2) {
            setFinalprice((subtotalprices + tax - discount))
        } else {
            setFinalprice((Number(subtotalprices) + Number(tax) - Number(discount)))
        }


        if (isshow) {
            console.log("sfsdfsdfdsfdsfsfs", subtotalprices, "Fsdf", tax, discount);

            const localStorageData = JSON.parse(localStorage.getItem('cart_item'))
            localStorageData['tax'] = tax;
            localStorageData['discount'] = discount;
            localStorageData['UserCost'] = UserCost;
            localStorageData['subtotalprice'] = subtotalprices;
            if (cartData.PakageId == 2) {
                localStorageData['total'] = (subtotalprices + tax - discount);
            } else {
                localStorageData['total'] = (subtotalprices + tax - discount);
            }
            localStorage.setItem('cart_item', JSON.stringify(localStorageData))
            localStorageData['discount'] = discount;
        }
    }, [tax, subtotalprices])
    return (
        <>{isshow ? <div className='order-total'>
            <h2>Cart Total</h2>
            <p> Sub Total: <span> {(isshow) ? Currency(subtotalprices) : Currency(cartData.subtotalprice)}</span></p>

            {/* <p>Discount {cartdata.discount_per + "%"}: <span className='green-value'>Ksh {discount && (discount).toFixed(2)}</span></p> */}
            <p>Tax VAT {cartdata.tax_per + "%"}: <span className='text-right'>{tax && Currency(tax)}</span></p>
            <p><strong>Total:</strong> <strong>{finalPrice && Currency(finalPrice)}</strong></p>
        </div> :
            <td className='order-total' colSpan="2">
                <h2>Cart Total</h2>
                <p> Sub Total: <span> {Currency(cartData?.subtotalprice)}</span></p>

                {/* <p>Discount {cartdata.discount_per + "%"}: <span className='green-value'>Ksh {(cartData.discount)?.toFixed(2)}</span></p> */}
                <p>Tax VAT  {cartdata.tax_per + "%"}: <span className='text-right'> {Currency(cartData.tax)}</span></p>
                <p><strong>Total:</strong> <strong> {cartData && (Currency(cartData.total))}</strong></p>

            </td>}
        </>
    );
}

export default OrderTotal; 