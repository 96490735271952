import React, { useEffect, useState } from 'react';
import Banner from "../../Components/Common/Banner/Banner";
import { Link } from "react-router-dom";
import CommonButton from '../../Components/Common/Buttons/Buttons';
import OrderTotal from './OrderTotal';
import './OrderDetails.css';
import { Currency } from '../../Currency';
const OrderDetails = () => {
    const [cartData, setcartData] = useState(0)
    const [subtotal, setsubtotal] = useState([])
    const [flag, setflag] = useState(false)
    const [subtotalPrice, setsubtotalPrice] = useState()

    useEffect(() => {
        const cartdata = localStorage.getItem('cart_item')
        if (cartdata) {
            setcartData(JSON.parse(cartdata))
        }
    }, [])

    const deleteSubject = (gradeId, subjects) => {
        const cartdata = localStorage.getItem('cart_item')
        let data = JSON.parse(cartdata)
        delete data.pakage[gradeId]
        data.Subject = data.Subject - subjects
        if (Object.keys(data.pakage).length === 0) {
            data.prize = 0
            data.users = 0
        }
        localStorage.setItem('cart_item', JSON.stringify(data))
        setcartData(data)
        setflag(!flag)
    }

    const deleteGrade = (gradeId) => {
        let newArray = [];
        const cartdata = localStorage.getItem('cart_item')
        let data = JSON.parse(cartdata)
        newArray = data.pakage.map(function (el, i) {
            if (el.grade_id == gradeId) {
                return "remove";
            } else {
                return "keep";
            }
        })
        newArray.map(function (ele, i) {
            if (ele == "remove") {
                data.pakage.splice(i, 1);
            }
        });
        localStorage.setItem('cart_item', JSON.stringify(data))
        setcartData(data)
        setflag(!flag)
    }

    let ans = 0;
    console.log(cartData.PakageId, "hello");
    let totalAmount = cartData.PakageId === 0 ? Object.keys(cartData).length !== 0 && Object.keys(cartData?.pakage)?.map((oneKey, i) => {
        ans = ans + (cartData.pakage[oneKey].subjects.length * cartData.pakage[oneKey].users * Number(cartData.pakage[oneKey].rate));
    }) : cartData.PakageId === 1 ?
        cartData.pakage.map((oneKey, i) => {
            console.log(cartData.pakage[oneKey], " cartData.pakage[oneKey].users");
            ans = ans + (parseFloat(oneKey.price) * parseFloat(cartData.pakage[i].users));
        }) : ans = Number(cartData.prize) * Number(cartData.users);

    return (
        <>
            <Banner BannerHeading="Order Summary" BannerSubHeading={<p>Review your order below.</p>} />
            <section className='order-details pad-t-80 pad-b-80'>
                <div className='container'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-5">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/content-plans">Content Plans</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Order Details</li>
                        </ol>
                    </nav>
                    <div className='table-responsive'>
                        <table className="table center-align-table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col" align='left'>{cartData.pakagetype} plan</th>
                                    <th scope="col">Price</th>

                                    <th scope="col">Number Of User</th>

                                    {cartData.PakageId === 2 || cartData.PakageId === 1 ? "" : <th scope="col">Sub. QTY</th>}
                                    <th scope="col">Total Price</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cartData.PakageId === 0 ?
                                    Object.keys(cartData).length !== 0 &&
                                    Object.keys(cartData.pakage).map((oneKey, i) => {
                                        return (
                                            <>
                                                <tr key={i}>
                                                    <th scope="row">{i + 1}</th>
                                                    <td align='left'>
                                                        <strong className='d-block'>{cartData.pakage[oneKey].grade_name}</strong>
                                                        {cartData.pakage[oneKey].subjects.map((ele, i) => {
                                                            return (
                                                                <>
                                                                    <span key={i}> {ele.subject_name}</span>
                                                                </>
                                                            )
                                                        }).map((item, index) => [index > 0 && ', ', item])}
                                                    </td>
                                                    <td>{Currency(cartData.prize)}</td>
                                                    <td>{cartData.pakage[oneKey].users?.toLocaleString()}</td>
                                                    <td>{cartData.pakage[oneKey].subjects.length}</td>
                                                    <td><strong>{Currency(cartData.pakage[oneKey].subjects.length * parseFloat(cartData.prize) * cartData.pakage[oneKey].users)}</strong></td>
                                                    <td>
                                                        <button className='btn-action btn-danger'><img src="/images/trash.svg" width={22} alt="icon" onClick={() => { deleteSubject(oneKey, cartData.pakage[oneKey].subjects.length) }} /></button>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })
                                    :
                                    cartData.PakageId === 1 ?
                                        cartData.pakage.map((oneKey, i) => {
                                            console.log("hjkghjkgdfgd", oneKey)
                                            return (
                                                <>
                                                    <tr key={i}>
                                                        <th scope="row">{i + 1}</th>
                                                        <td align='left'>
                                                            <strong className='d-block'>{oneKey.grade_name}</strong>
                                                            {/* {cartData.pakage[oneKey].subjects.map((ele, i) => {
                                                            return (
                                                                <>
                                                                    <span key={i}> {ele.subject_name},</span>
                                                                </>
                                                            )
                                                        })} */}
                                                        </td>
                                                        <td>{Currency(oneKey.price)}</td>
                                                        <td>{cartData.pakage[i].users?.toLocaleString()}</td>
                                                        {console.log(".pakage[i]  ", cartData.pakage[i].Subject[0].length)}
                                                        {/* <td>{cartData.pakage[i].Subject[0].length}</td> */}
                                                        <td><strong>{Currency(oneKey.price * cartData.pakage[i].users)}</strong></td>
                                                        <td>
                                                            <button className='btn-action btn-danger'><img src="/images/trash.svg" width={22} alt="icon" onClick={() => { deleteGrade(oneKey.grade_id) }} /></button>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        }) :
                                        <>
                                            <tr >
                                                <td>1</td>
                                                <td align='left'>
                                                    <strong className='d-block'>All Grades/Classes content</strong>
                                                </td>
                                                {console.log("fgsjdfsd", cartData)}
                                                <td>{Currency(cartData.prize)}</td>
                                                <td>{cartData.users?.toLocaleString()}</td>
                                                <td><strong>{Currency(Number(cartData.prize) * Number(cartData.users))}</strong></td>
                                                <td>
                                                    <button className='btn-action btn-danger'><Link to="/content-plans"><img src="/images/trash.svg" width={22} alt="icon" /></Link></button>
                                                </td>
                                            </tr>
                                        </>
                                }
                            </tbody>
                        </table>
                    </div>
                    <OrderTotal cartdata={cartData} flag={flag} isshow={true} subtotalprices={ans} />
                    <div className="step-navigation">
                        <Link to="/content-plans" className='btn btn-text'><span className="material-icons">arrow_back</span> Clear The Cart</Link>
                        <CommonButton isLink="true" URL="/invoice" BtnColor="green" BtnText="Continue" hasIconImg="true" IconImgPath="right-arrow.svg" />
                    </div>
                </div>
            </section> {/* {subtotal.push(cartData.pakage[oneKey].subjects.length * parseInt(cartData.prize) + parseInt(cartData.users) * 50)} */}
        </>
    );
}

export default OrderDetails; 