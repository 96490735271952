import { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Banner from "../../Components/Common/Banner/Banner";
import SectionTitle from "../../Components/Common/SectionTitle/SectionTitle";
import Plans from "../../Components/Plans/Plans";
import { InputText } from '../../Components/Common/Inputs/Inputs';
import CommonButton from '../../Components/Common/Buttons/Buttons';
import CommonModal from '../../Components/Common/Modal/Modal';
import '../../Components/Common/ContactForm/ContactForm.css'
import HandleError from '../../Components/HandleError/HandleError';
import { PakageListing } from "../../Redux/Action/PakageAction";
import { useSelector, useDispatch } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import './Pricing.css';
import Api from '../../Api';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

const FreeTrialPopup = () => {
    const location = useLocation();
    const navigate = useNavigate()
    console.log(location?.state?.Url, "location.state.Url");
    let dispatch = useDispatch()
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const [userData, setUserData] = useState({})
    const [formError, setFormError] = useState()
    const handleClose = () => setShow(false);
    const [PakageDetails, setPakageDetails] = useState();
    const [submitCaptcha, setSubmitCaptcha] = useState(false)
    // const errors = {};
    let captcha;



    const onChange = (value) => {
        console.log("Captcha value:", value);
        setSubmitCaptcha(true)
        setUserData({ ...userData, captcha_token: value })
    }

    const handleSubmit = () => {
        console.log("fsdfsdfgjh", Object.keys(formError).length === 0);
        if (Object.keys(formError).length === 0) {
            captcha.reset();
            setShow(false);

            // const token = JSON.parse(localStorage.getItem("user-token"));

            const config = {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/json",
                    // Authorization: "BEARER " + token.data.token,
                },
                body: JSON.stringify(userData)
            };
            fetch(Api.baseURL + `/request_free_trial`, config)
                .then((resp) => resp.json())
                .then((result) => {
                    console.log(result, "result");
                    if (result?.status === "success") {
                        toast.success(result.msg)
                        setSubmitCaptcha(false)
                    }
                    if (result.status === "error") {
                        HandleError(result)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    }

    function validate(e) {

        const { name, value } = e.target;
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (name === "full_name") {
            if (!value) {
                setFormError({ ...formError, [name]: "Name is required!" })
            } else if (!isNaN(value)) {
                setFormError({ ...formError, [name]: "Please enter Alphabet value" })
            } else if (value.length < 4 || value.length > 40) {
                setFormError({ ...formError, [name]: "Name Length Should Be 4 To 40" })
            }
            else {
                delete formError[name]
                setUserData({
                    ...userData,
                    [name]: value
                });
            }
        }
        if (name === "email_address") {
            if (!value) {
                setFormError({ ...formError, [name]: "Email is required!" })
            } else if (!regex.test(value)) {
                setFormError({ ...formError, [name]: "Enter Vaild Email!" })
            }
            else {
                delete formError[name]
                setUserData({
                    ...userData,
                    [name]: value
                });
            }
        }
        if (name === "message") {
            if (!value) {
                setFormError({ ...formError, [name]: "Message is required!" })
            } else if (!isNaN(value)) {
                setFormError({ ...formError, [name]: "Please enter Alphabet value" })
            } else if (value.length < 4 || value.length > 400) {
                setFormError({ ...formError, [name]: "Message Length Should Be 4 To 400" })
            }
            else {
                delete formError[name]
                setUserData({
                    ...userData,
                    [name]: value
                });
            }
        }
        // setFormError(errors);
    };
    let ModalData = () => {
        return (<>
            <InputText
                type="text"
                placeholder="Full Name"
                onChange={validate}
                name="full_name"
                error={formError?.full_name}
            />
            <InputText
                type="email"
                placeholder="Email Address"
                onChange={validate}
                name="email_address"
                error={formError?.email_address}
            />

            <div className="form-group">
                <InputText
                    className="form-control"
                    placeholder="Message"
                    onChange={validate}
                    error={formError?.message}
                    name="message"></InputText>
            </div>
            <div className="col-lg-4 mt-4" >
                <ReCAPTCHA
                    sitekey="6LcfzyEgAAAAAGA0Ibj4iKxHA8Ou5HWc1julTQ7r"
                    onChange={(value) => onChange(value)}
                    ref={el => { captcha = el; }}
                />
            </div>
            <div className="contact-info">
                <p>For questions about our API, pricing or implementation please feel free to contact us on <a href="mailto:hello@msingipack.com">hello@msingipack.com</a>.</p>
            </div>
        </>)
    }


    return (
        <>

            <CommonButton isButton="true" BtnColor="dark" BtnText="Start trial" onClick={handleShow} />
            <CommonModal
                disabled={!submitCaptcha}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                show={show}
                modelData={ModalData()}
                ModalTitle="Request a free trial"
            />
        </>
    );
}

export default FreeTrialPopup;