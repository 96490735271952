import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import Banner from "../../Components/Common/Banner/Banner";
import Api from '../../Api';
import RenewInvoicePackage from "./invoicePakage"
import HandleError from '../../Components/HandleError/HandleError';
import RenewOrderTotal from './OrderTotal';
import { toast } from 'react-toastify';
const RenewInvoice = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [invoiceDetails, setInvoicedetails] = useState()
    const [BankDetails, setBankDetails] = useState()
    const current = new Date();

    useEffect(() => {
        console.log(location, "location");
        if (location?.state) {
            const token = JSON.parse(localStorage.getItem("user-token"));
            const config = {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Authorization: "BEARER " + token.data.token,
                },
                body: JSON.stringify(location?.state)
            };
            fetch(Api.baseURL + `/${location?.state?.url}/${id}`, config)
                .then((res) => res.json())
                .then((data) => {
                    if (data.data.status == "success") {
                        console.log("this is err", data.data);
                        setInvoicedetails(data.data.data)
                    }
                    if (data.data.status == "error") {
                        console.log("this is err", data.data);
                        HandleError(data.data.message)
                    }
                })
        }

    }, [location.state])

    useEffect(() => {
        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
            }
        };
        fetch(Api.baseURL + `/fetch_settings`, config)
            .then((res) => res.json())
            .then((data) => {
                if (data.status == "success") {
                    console.log("this is err", data);
                    setBankDetails(data.data)
                }
                if (data.status == "error") {
                    console.log("this is err", data.data);
                    HandleError(data.data.message)
                }
            })
    }, [])

    const submitInvoice = (e) => {
        console.log(" location log", location);
        e.preventDefault();
        let data11;
        if (location?.state?.tenure) {
            data11 = "/renew_subscription"
        } else {
            data11 = "/create_increment_subscription"
        }
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
            // body: JSON.stringify(location?.state)
            body: JSON.stringify({
                "grades": location?.state?.grades,
                "tot_users": location?.state?.no_of_users, "plan_tenure": location?.state?.tenure
            })
        };
        fetch(Api.baseURL + `${data11}/${invoiceDetails.id}`, config)
            .then((resp) => resp.json())
            .then((result) => {
                if (result?.status === "success") {
                    navigate("/thank-you")
                } else if (result?.status === "error") {
                    toast.error(result.message)
                }
            })
    }

    return (
        <>
            <Banner BannerHeading="INVOICE" BannerSubHeading={<p>Your invoice has been auto generated</p>} />
            <section className="what-section pad-t-80 pad-b-80">
                <div className='table-responsive invoice-tab'>
                    <center style={{ width: '100%', maxWidth: '800px', margin: 'auto' }}>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/content-plans">Content Plans</Link></li>
                            <li className="breadcrumb-item"><Link to="/order-details">Order Details</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Generate Invoice</li>
                        </ol>
                        <table width="100%" cellPadding={0} cellSpacing={0}>
                            <tbody><tr>
                                <td height={30} />
                            </tr>
                            </tbody>
                        </table>
                        <table cellPadding={0} cellSpacing={0} width="100%" bgcolor="#fce8d1" style={{ padding: '0 30px' }}>
                            <tbody>
                                <tr>
                                    <td height={20} />
                                </tr>
                                <tr>
                                    <td width="30"></td>
                                    <td>
                                        <table width="100%" cellPadding="0" cellSpacing="0">
                                            <tr>
                                                <td align="left">
                                                    <img src="../images/logo.svg" alt="Logo" width={180} height={120} style={{ display: 'block' }} />
                                                </td>
                                                <td align="center">
                                                    <h2 style={{ fontSize: '28px', fontWeight: 'bold', textTransform: 'uppercase', margin: 0, marginBottom: '10px' }}> Invoice</h2>
                                                    <p style={{ fontSize: '15px', whiteSpace: 'nowrap' }}>  <strong style={{ minWidth: '70px', display: 'inline-block' }}>Date:</strong>  {current.getDate()}/{current.getMonth() + 1}/{current.getFullYear()}&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                                </td>
                                                <td align="right">
                                                    <img src="../images/virtual-logo.png" alt="Logo" width={180} style={{ display: 'block' }} />
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td width="30"></td>
                                </tr>
                                <tr>
                                    <td height={20} />
                                </tr>
                            </tbody>
                        </table>
                        <table cellPadding={0} cellSpacing={0} width="100%" bgcolor="#f9f9f9" style={{ padding: '0 30px', fontSize: '15px' }}>
                            <tr>
                                <td height={30} />
                            </tr>
                            <tr>
                                <td width="30"></td>
                                <td>
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                        <tr>
                                            <td className="body-content">
                                                <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                    <tbody>
                                                        <tr>
                                                            {invoiceDetails &&
                                                                <td width="60%">
                                                                    <p style={{ color: '#000', fontSize: '16px' }}> Bill To: </p>
                                                                    <p style={{ color: '#000', fontSize: '20px', fontWeight: 'bold' }}>  {invoiceDetails.user_name}</p>
                                                                    <p style={{ color: '#000', lineHeight: '1.5' }}> {invoiceDetails.user_address} <br /></p>
                                                                    <p style={{ color: '#000', }}><strong>Tel:</strong>  {invoiceDetails.mob_no}</p>
                                                                    <p style={{ color: '#000', }}><strong>E-mail:</strong> {invoiceDetails.email}</p>
                                                                </td>
                                                            }

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                {invoiceDetails &&
                                    <td>

                                        <p style={{ color: '#000', fontSize: '16px' }}> Plan info: </p>
                                        <p> <strong style={{ minWidth: '100px', display: 'inline-block' }}>Plan:</strong>
                                            {invoiceDetails && invoiceDetails.package_name}
                                        </p>
                                        <p style={{ textTransform: 'capitalize' }}> <strong style={{ minWidth: '100px', display: 'inline-block', textTransform: 'capitalize' }}>Time:</strong>{location?.state?.plan_tenure ? location.state.plan_tenure.split("_").splice(0)[0] : invoiceDetails.plan_tenure.split("_").splice(0)[0]} </p>
                                        {invoiceDetails.package_name == "Gold" ?
                                            <p> <strong style={{ minWidth: '100px', display: 'inline-block' }}>No. of users:</strong> {location?.state?.tot_users ? location.state.tot_users?.toLocaleString() : invoiceDetails.new_no_of_users?.toLocaleString()}</p>
                                            : ""}
                                    </td>
                                }
                                <td width="30"></td>
                            </tr>
                            <tr>
                                <td height={30} />
                            </tr>
                        </table>
                        <div className='table-responsive'>
                            <table width="100%" bgcolor="#f9f9f9" cellPadding={10} cellSpacing={0} style={{ padding: '0 30px', fontSize: '15px' }}>
                                <tr>
                                    <td width="20"></td>
                                    <td>
                                        <table width="100%" cellPadding="10" cellSpacing="0">
                                            <thead>
                                                <tr style={{ fontSize: '15px', whiteSpace: 'nowrap' }}>
                                                    <th colSpan={2} style={{ fontWeight: 700 }} align="left"> Particular </th>
                                                    <th style={{ fontWeight: 700 }} align="center">Price </th>
                                                    {
                                                        invoiceDetails &&

                                                            invoiceDetails.package_name == "Bronze" ?
                                                            <th style={{ fontWeight: 700 }} align="center"> Sub. QTY </th> : ""

                                                    }
                                                    <th style={{ fontWeight: 700 }} align="center"> Number Of Users </th>
                                                    <th style={{ fontWeight: 700 }} align="center"> Total </th>
                                                </tr>
                                            </thead>

                                            {invoiceDetails != undefined ?
                                                <RenewInvoicePackage invoiceDetails={invoiceDetails} />
                                                :
                                                <tbody>
                                                    <tr>
                                                        <td height={20}> <>Please Wait....</></td>
                                                    </tr>
                                                </tbody>
                                            }
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={5} height={20}></td>
                                                </tr>
                                                {BankDetails && <tr>
                                                    <td colSpan={3} style={{ color: '#000', lineHeight: '1.5', verticalAlign: 'text-bottom' }} rowSpan={3}>
                                                        <p> <strong>Bank:</strong> {BankDetails.bank_name}</p>
                                                        <p><strong>A/c Name:</strong> {BankDetails.account_name}</p>
                                                        <p><strong>A/c No.</strong> {BankDetails.account_no}</p>
                                                        <p><strong>Branch:</strong> {BankDetails.branch}</p>
                                                        <p><strong>Code:</strong>  {BankDetails.code}</p>

                                                    </td>
                                                    {invoiceDetails != undefined ?
                                                        <RenewOrderTotal cartdata={invoiceDetails} />
                                                        :

                                                        <td>
                                                            <td height={20}> <>Please Wait....</></td>
                                                        </td>

                                                    }

                                                </tr>}
                                                <tr>
                                                    <td height={30} />
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </td>
                                    <td width="20"></td>
                                </tr>
                            </table>
                        </div>
                        <table cellPadding={0} cellSpacing={0} width="100%" className="mail-footer" align="center" bgcolor="#fce8d1" style={{ borderTop: '1px solid #ddd' }}>
                            <tbody>
                                <tr>
                                    <td height={30} />
                                </tr>
                                <tr align="center">
                                    <td style={{ color: '#000000', fontWeight: 'bold', fontSize: '12px', lineHeight: '1.8' }}> {BankDetails?.address} <br /> +254 111 044600 | finance@virtual-essence.co.ke</td>
                                </tr>
                                <tr>
                                    <td height={10} />
                                </tr>
                                <tr align="center">
                                    <td style={{ color: '#000000', fontWeight: 'normal', fontSize: '12px' }}> www.msingipack.com </td>
                                </tr>
                                <tr>
                                    <td height={30} />
                                </tr>
                            </tbody>
                        </table>
                        <div className="step-navigation">
                            <Link to="/order-details" className='btn btn-text'><span className="material-icons">arrow_back</span> Preview Order</Link>
                            <Link to="/thank-you" className='btn btn-green' onClick={submitInvoice}>Continue <img src="/images/right-arrow.svg" className="white-icon v-sub ms-2" width="23" alt="icon" /></Link>
                        </div>
                    </center>
                </div>

            </section>
        </>
    );
}

export default RenewInvoice;