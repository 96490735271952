import { useEffect, useState } from 'react';
import DashboardNavbar from '../../../Components/DashboardNavbar/DashboardNavbar';
import DashboardHeader from '../../../Components/DashboardHeader/DashboardHeader';
import PaginatedItems from "../../../Components/Common/pagination";
import Api from '../../../Api';
import { useParams } from 'react-router-dom';
import "../../../Components/Common/ContactForm/ContactForm.css";
import Loader from '../../../Components/Common/loader';
import CommonModal from "../../../Components/Common/Modal/Modal";
import { InputText } from "../../../Components/Common/Inputs/Inputs";
import CommonButton from '../../../Components/Common/Buttons/Buttons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const UpgradeUserList = () => {
    const [Sub_total, setSub_total] = useState()
    const [listData, setlistData] = useState()
    const [currentPosts, setcurrentPosts] = useState();
    const [currentPage, setcurrentPage] = useState(1);
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [UserTotal, setUserTotal] = useState(0)
    const [isReload, setIsReload] = useState(true)
    let { id } = useParams();
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
            params: { "page_no": currentPage }
        };
        fetch(Api.baseURL + `/list_upgrade_requests/${id}?page_no=${currentPage}`, config)
            .then((resp) => resp.json())
            .then((result) => {
                console.log(result, "res")
                setSub_total(result.data.sub_total)
                setlistData(result.data.requests)
                setcurrentPosts(result.data.tot_requests)
                setLoading(false)
                if (result.status === "error") {
                    // HandleError(result)
                }
            })
    }, [currentPage, isReload])

    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };

    const UpgradeUser = () => {
        setShow(true);
    }

    const EnterNumber = (e) => {
        e.preventDefault();
        setFormErrors(validate(e));
    }

    const body1 = () => {
        return (
            <>
                <div className="row mb-3">
                    <InputText
                        className="col-2 mb-0"
                        type="text"
                        name="name"
                        defaultValue={UserTotal}
                        placeholder="Enter Number of user"
                        onChange={EnterNumber}
                        error={formErrors.mob_no}
                        labelText="Number of user"
                        FormGroupClass="mb-0"
                    // (e) => { setUserTotal(e.target.value) }
                    />
                </div>
                <div style={{ textAlign: "right" }}>
                    {Sub_total}*{UserTotal} = {Sub_total * UserTotal}KSH
                </div>
            </>
        )
    }
    let modelData = body1();


    const validate = (e) => {
        const errors = {};
        setUserTotal(0)
        if (!e.target.value) {
            errors.mob_no = "User is required!";
        } else if (isNaN(e.target.value)) {
            errors.mob_no = "Please enter Numeric value";
        } else if (e.target.value == 0) {
            errors.mob_no = "Number of user should be More then 0";
        } else if (e.target.value % 1 != 0) {
            console.log("LOG", e.target.value);
            // var myTrunc = Math.trunc(parseInt(e.target.value));
            // setUserTotal(myTrunc)
            errors.mob_no = "Number of user should be Integer";
        } else if (e.target.value) {
            setUserTotal(e.target.value)
        }
        return errors;
    };


    const handleClose = () => {
        setShow(false)

    }

    const Submitdata = (e) => {
        console.log(Object.keys(formErrors).length, "formErrors");
        if (Object.keys(formErrors).length == "0") {
            e.preventDefault();
            const token = JSON.parse(localStorage.getItem("user-token"));
            const config = {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Authorization: "BEARER " + token.data.token,
                },
                body: JSON.stringify({ tot_users: UserTotal })
            };
            fetch(Api.baseURL + `/request_upgrade_users/${id}`, config)
                .then((resp) => resp.json())
                .then((result) => {
                    if (result?.status === "success") {
                        setShow(false);
                        setIsReload(!isReload)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        }

    };

    return (
        <>
            <CommonModal
                handleClose={handleClose}
                handleSubmit={Submitdata}
                show={show}
                modelData={modelData}
                Name={"Send Request"}
                ModalTitle="Upgrade user request"
            />
            <DashboardHeader />

            <div className="mpc-dashboard">
                <DashboardNavbar />
                <div className="dashboard-right-content">
                    <h1 className="main-page-heading with-filter">
                        <span>Users Request List</span>
                        <div className="filter">
                            {/* <button
                                className="btn-success"
                                theme="info"
                                title="Upgrade User"
                                onClick={UpgradeUser}
                                style={{ fontSize: `17px` }}
                            >
                                <span >
                                    Upgrade User Request
                                </span>
                            </button> */}
                            <CommonButton
                                isButton="true"
                                BtnType="submit"
                                BtnColor="green btn-sm"
                                BtnText="Upgrade User Request"
                                onClick={UpgradeUser}
                            />
                        </div>
                    </h1>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" style={{ width: '10%' }}>#</th>
                                    <th scope="col" style={{ width: '25%' }}>Total User</th>
                                    <th scope="col" style={{ width: '25%' }}>Status</th>
                                    <th scope="col" style={{ width: '25%' }}>Action</th>
                                </tr>
                            </thead>
                            {loading === true ?
                                <Loader />
                                :
                                <>
                                    {listData.lenght === undefined ? listData.map((ele, i) => {
                                        return (<>
                                            <tbody>
                                                <tr>
                                                    <td scope="col">{i + 1 + (currentPage - 1) * 10}</td>
                                                    <td scope="col">{ele.tot_users}</td>
                                                    <td scope="col">{ele.is_approved}</td>
                                                    <td scope="col">
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip> Download Invoice </Tooltip>}
                                                        >
                                                            <a className="btn-primary"
                                                                href={Api.baseURL + `/download_upgrade_invoice/${ele.id}`}
                                                                theme="info"
                                                                target="_blank"
                                                            >
                                                                <span className="material-icons">file_download</span>
                                                            </a>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>)
                                    }) : <tbody><tr>
                                        <td colSpan="15" className="text-center noRec-found"> No record found</td>
                                    </tr> </tbody>}
                                </>
                            }
                        </table>
                    </div>
                    {currentPosts > 10 &&
                        <PaginatedItems
                            List={currentPosts}
                            HandlePageClick={HandlePageClick}
                        />}
                </div>
            </div>

        </>
    )
}
export default UpgradeUserList