import "../../../Components/Common/ContactForm/ContactForm.css";
import "../Dashboard.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom"
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CommonModal from "../../../Components/Common/Modal/Modal";
import Api from "../../../Api";
import HandleError from "../../../Components/HandleError/HandleError";
import { toast } from "react-toastify";
const AssignedUser = ({ UserData, setReload, reload, checkBoxData, setcheckBoxData }) => {
    const navigate = useNavigate();
    const [id, setID] = useState()
    const [show, setShow] = useState(false)

    let SendEmailPopUp = (id) => {
        setID(id)
        setShow(true)
    }

    let handleClose = () => {
        setShow(false)
    }

    let RemoveUser = () => {
        setShow(false)
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "DELETE",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
        };
        fetch(Api.baseURL + `/delete_assign_user/${id}`, config)
            .then((resp) => resp.json())
            .then((result) => {
                if (result.status === "success") {
                    toast.success(result.message)
                    setReload(!reload)
                }
                else if (result.status === "error") {
                    HandleError(result)
                }
            })
    }

    let body = () => {
        return (
            <>
                <div className="d-flex ">
                    <p> Are you sure you want to unassign the user from the study materials? The same user can be reassigned till the plan has not expired.</p>
                </div>
            </>
        )
    }
    let modelData = body();


    const selectUser = (e, id) => {
        console.log("helooooooooooo", id, checkBoxData);
        if (e.target.checked) {
            setcheckBoxData([...checkBoxData, id])
        } else {

            const updatedDataRequiredField = checkBoxData.filter((value, index) => id !== value)
            setcheckBoxData(updatedDataRequiredField)
        }
    }

    return (
        <>
            <CommonModal
                handleClose={handleClose}
                handleSubmit={RemoveUser}
                show={show}
                modelData={modelData}
                Name={"Remove"}
                ModalTitle={"Remove User !!"}
            />
            {UserData !== undefined && UserData?.length !== 0 && UserData && UserData.length !== undefined ? UserData.map((ele, i) => {
                return (
                    <tr>
                        <td><input type="checkbox"
                            checked={ele.subscriber_uid == checkBoxData.find(element => element == ele.subscriber_uid) ? true : ""}
                            onClick={(e) => selectUser(e, ele.subscriber_uid)}
                        /></td>

                        <td>{i + 1}</td>
                        <td>{ele.username}</td>
                        <td>{ele.name}</td>
                        <td>{ele.email}</td>
                        <td>{ele.mob_no}</td>
                        <td>  <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip> Delete </Tooltip>}
                        >
                            <button className="btn-danger"
                                onClick={() => { SendEmailPopUp(ele.subscriber_uid) }}
                            >
                                <span className="material-icons">
                                    delete
                                </span>
                            </button>
                        </OverlayTrigger>
                            &nbsp;</td>
                    </tr>
                )
            }) :
                <tr>
                    <td colSpan="15" className="text-center noRec-found"> No record found</td>
                </tr>
            }
        </>
    );
};

export default AssignedUser;
