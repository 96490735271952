import { useEffect } from 'react';
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import Api from "../Api/index";
import { useBeforeunload } from 'react-beforeunload';

const UnAuthUser = () => {
    const { id } = useParams();

    // useBeforeunload((event) => {
    //     //event.preventDefault();
    //     handleTabClosing();
    // });

    // useEffect(() => {
    //     //handleTabClosing();
    //     window.addEventListener('beforeunload', alertUser)
    //     window.addEventListener('unload', handleTabClosing)
    //     return () => {
    //         window.removeEventListener('beforeunload', alertUser)
    //         window.removeEventListener('unload', handleTabClosing)
    //     }
    // }, [])

    // const handleTabClosing = () => {
    //     const token = JSON.parse(localStorage.getItem("user-token"));
    //     console.log("token---->>>", token);
    //     const config = {
    //         method: "GET",
    //         headers: {
    //             "Content-type": "application/json",
    //             Accept: "application/json",
    //             // Authorization: "BEARER " + token.data.token,
    //         },
    //     };
    //     fetch(`http://192.168.2.80:3334/api/v1/users/updateLoginLogSampleApi`, config)
    //         .then((resp) => console.log("more--->>", resp.json()))
    //         .then((result) => {
    //             console.log("hello", result);
    //             if (result.status === "error") {
    //                 // HandleError(result)
    //             }
    //         })
    // }

    // const alertUser = (event) => {
    //     event.preventDefault()
    //     event.returnValue = 'Hiiiiiiiiiii'
    // }


    useEffect(() => {
        const config = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
            },

        };
        fetch(Api.baseURL + `/verify-user/${id}`, config)
            .then((resp) => resp.json())
            .then((result) => {
                // dispatch({
                //     type: USERSIGNUP_SUCCESS,
                //     payload: result,
                // });
                if (result.status === "success") {
                    toast.success("Signup Successfully");
                    localStorage.setItem("user-token", JSON.stringify(result));
                    setInterval(() => {
                        window.location.href = "/dashboard";
                    }, 700);
                } else {
                    toast.error(result.message);
                }
            });
    }, [])

    return (
        <>
            <div>...Loading</div>
        </>
    );
}

export default UnAuthUser;