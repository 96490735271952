import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from "../../Components/Common/Banner/Banner";
import { Currency } from '../../Currency';

const RenewInvoicePackage = ({ invoiceDetails }) => {
    const [cartData, setcartData] = useState()
    const [PakageId, setPakageId] = useState()
    useEffect(() => {

        // const cartdata = localStorage.getItem('cart_item')
        if (invoiceDetails) {
            setcartData(invoiceDetails)
        }
    }, [])

    return (
        <tbody>
            {cartData?.package_name == "Bronze" ?
                cartData?.grades.map((value, i) => {
                    return (<tr key={i}>
                        <td colSpan={2} style={{ borderTop: '1px dashed #afafaf', lineHeight: '1.5' }}>
                            <strong>{value.grade_name}</strong> <br />
                            <small>
                                {value.subjects.map((ele, i) => {
                                    return (
                                        <>
                                            <span key={i}> {ele.subject_name}
                                                {i < value.subjects.length - 1 ? <>,</> : null}
                                            </span>
                                        </>
                                    )
                                })}
                            </small>
                        </td>
                        <td style={{ borderTop: '1px dashed #afafaf', whiteSpace: 'nowrap' }} align="center"> Ksh {value.rate}  </td>
                        <td style={{ borderTop: '1px dashed #afafaf' }} align="center">{value.subjects.length}</td>
                        <td style={{ borderTop: '1px dashed #afafaf' }} align="center">{value.new_tot_users?.toLocaleString()}</td>
                        <td style={{ borderTop: '1px dashed #afafaf', textAlign: 'center', fontWeight: 600, whiteSpace: 'nowrap' }}> {Currency(value.rate * value.subjects.length * value.new_tot_users)}</td>
                    </tr>)
                })
                :
                cartData?.package_name == "Silver" ?
                    cartData?.grades.map((value, i) => {
                        return (
                            <tr key={i}>
                                <td colSpan={2} style={{ borderTop: '1px dashed #afafaf', lineHeight: '1.5' }}>
                                    <strong>{value.grade_name}</strong> <br />
                                </td>
                                <td style={{ borderTop: '1px dashed #afafaf', whiteSpace: 'nowrap' }} align="center"> Ksh {value.rate} </td>
                                <td style={{ borderTop: '1px dashed #afafaf' }} align="center"> {value.new_tot_users?.toLocaleString()} </td>
                                <td style={{ borderTop: '1px dashed #afafaf', textAlign: 'center', fontWeight: 600, whiteSpace: 'nowrap' }}>{Currency(value.rate * value.new_tot_users)}</td>
                            </tr>
                        )
                    }) :
                    cartData?.package_name == "Gold" ?
                        <tr >
                            <td colSpan={2} style={{ borderTop: '1px dashed #afafaf', lineHeight: '1.5' }}>
                                <strong>All Grades/Classes content</strong> <br />

                            </td>
                            {console.log("log", cartData.grades[0])}
                            <td style={{ borderTop: '1px dashed #afafaf', whiteSpace: 'nowrap' }} align="center"> Ksh {cartData.grades[0]?.rate} </td>
                            <td style={{ borderTop: '1px dashed #afafaf' }} align="center"> {cartData.new_no_of_users?.toLocaleString()}</td>
                            <td style={{ borderTop: '1px dashed #afafaf', textAlign: 'center', fontWeight: 600, whiteSpace: 'nowrap' }}>{Currency((cartData.grades[0]?.rate) * (cartData.new_no_of_users))}</td>
                        </tr>
                        : ""}

        </tbody>
    )
}

export default RenewInvoicePackage;