import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from "../../Components/Common/Banner/Banner";
import InvoicePackage from './invoicePakage';
import { useNavigate } from "react-router-dom";
import OrderTotal from '../OrderDetails/OrderTotal';
import Api from '../../Api';

const Invoice = () => {
    const navigate = useNavigate();
    const [cartData, setcartData] = useState(0)
    const [userData, setuserData] = useState(0)
    const [BankDetails, setBankDetails] = useState()
    const current = new Date();
    useEffect(() => {
        if (!localStorage.getItem("user-token")) {
            localStorage.setItem("flag", 1)
            navigate('/login');
        }
        const cartdata = localStorage.getItem('cart_item')
        const userdata = localStorage.getItem('user-token')
        if (cartdata) {
            setcartData(JSON.parse(cartdata))
            setuserData(JSON.parse(userdata))
        }
    }, [])

    useEffect(() => {
        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",

            }
        };

        fetch(Api.baseURL + `/fetch_settings`, config)
            .then((res) => res.json())
            .then((data) => {
                setBankDetails(data.data)
            })
            .catch((err) => {
                if (err) {
                    console.log("this is err", err);
                }
            });
    }, [])

    const submitInvoice = () => {
        const userData = JSON.parse(localStorage.getItem('user-token'));
        const packages = []
        Object.entries(cartData.pakage).map((oneKey, i) => {
            packages.push(oneKey[1])
        })
        const data = {
            user_id: userData.data.id,
            plan_id: cartData.PakageId + 1,
            plan_tenure: cartData.planType && cartData.planType.split("_").splice(0)[0],
            tot_users: cartData.users,
            discount_per: cartData.discount_per,
            discount: cartData.discount,
            tax_per: cartData.tax_per,
            tax: cartData.tax,
            sub_total: cartData.subtotalprice,
            total: cartData.total,
            grades: packages
        }

        const config = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + userData.data.token,
            },
            body: JSON.stringify(data),
        };
        fetch(Api.baseURL + `/store_subscribe_plan`, config)
            .then((res) => res.json())
            .then((data) => {
                console.log("res", data);
            })
            .catch((err) => {
                if (err) {
                    console.log("this is err", err);
                }
            });
    }
    return (
        <>
            <Banner BannerHeading="INVOICE" BannerSubHeading={<p>Your invoice has been auto generated</p>} />
            <section className="what-section pad-t-80 pad-b-80">
                <div className='table-responsive invoice-tab'>
                    <center style={{ width: '100%', maxWidth: '800px', margin: 'auto' }}>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/content-plans">Content Plans</Link></li>
                            <li className="breadcrumb-item"><Link to="/order-details">Order Details</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Generate Invoice</li>
                        </ol>
                        <table width="100%" cellPadding={0} cellSpacing={0}>
                            <tbody><tr>
                                <td height={30} />
                            </tr>
                            </tbody>
                        </table>
                        <table cellPadding={0} cellSpacing={0} width="100%" bgcolor="#fce8d1" style={{ padding: '0 30px' }}>
                            <tbody>
                                <tr>
                                    <td height={20} />
                                </tr>
                                <tr>
                                    <td width="30"></td>
                                    <td>
                                        <table width="100%" cellPadding="0" cellSpacing="0">
                                            <tr>
                                                <td align="left">
                                                    <img src="../images/logo.svg" alt="Logo" width={180} height={120} style={{ display: 'block' }} />
                                                </td>
                                                <td align="center">
                                                    <h2 style={{ fontSize: '28px', fontWeight: 'bold', textTransform: 'uppercase', margin: 0, marginBottom: '10px' }}> Invoice</h2>
                                                    <p style={{ fontSize: '15px', whiteSpace: 'nowrap' }}>  <strong style={{ minWidth: '70px', display: 'inline-block' }}>Date:</strong>  {current.getDate()}/{current.getMonth() + 1}/{current.getFullYear()}&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                                </td>
                                                <td align="right">
                                                    <img src="../images/virtual-logo.png" alt="Logo" width={180} style={{ display: 'block' }} />
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td width="30"></td>
                                </tr>
                                <tr>
                                    <td height={20} />
                                </tr>
                            </tbody>
                        </table>
                        <table cellPadding={0} cellSpacing={0} width="100%" bgcolor="#f9f9f9" style={{ padding: '0 30px', fontSize: '15px' }}>
                            <tr>
                                <td height={30} />
                            </tr>
                            <tr>
                                <td width="30"></td>
                                <td>
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                        <tr>
                                            <td className="body-content">
                                                <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                    <tbody><tr>
                                                        {userData &&
                                                            <td width="60%">
                                                                <p style={{ color: '#000', fontSize: '16px' }}> Bill To: </p>
                                                                <p style={{ color: '#000', fontSize: '20px', fontWeight: 'bold' }}> {userData.data.name} </p>
                                                                <p style={{ color: '#000', lineHeight: '1.5' }}> {userData.data.physical_address} <br /> </p>
                                                                <p style={{ color: '#000', }}><strong>Tel:</strong> {userData.data.mob_no} </p>
                                                                <p style={{ color: '#000', }}><strong>E-mail:</strong> {userData.data.email}</p>
                                                            </td>}

                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td>
                                    <p style={{ color: '#000', fontSize: '16px' }}> Plan info: </p>
                                    <p> <strong style={{ minWidth: '100px', display: 'inline-block' }}>Plan:</strong> {cartData.pakagetype}</p>
                                    <p style={{ textTransform: 'capitalize' }}> <strong style={{ minWidth: '100px', display: 'inline-block', textTransform: 'capitalize' }}>Time:</strong>{cartData.planType && cartData.planType.split("_").splice(0)[0]} </p>
                                    {
                                        cartData.PakageId == 2 ?
                                            <p> <strong style={{ minWidth: '100px', display: 'inline-block' }}>No. of users:</strong> {cartData.users}</p>
                                            : ""
                                    }
                                </td>
                                <td width="30"></td>
                            </tr>
                            <tr>
                                <td height={30} />
                            </tr>
                        </table>
                        <div className='table-responsive'>
                            <table width="100%" bgcolor="#f9f9f9" cellPadding={10} cellSpacing={0} style={{ padding: '0 30px', fontSize: '15px' }}>
                                <tr>
                                    <td width="20"></td>
                                    <td>
                                        <table width="100%" cellPadding="10" cellSpacing="0">
                                            <thead>
                                                <tr style={{ fontSize: '15px', whiteSpace: 'nowrap' }}>
                                                    <th colSpan={2} style={{ fontWeight: 700 }} align="left"> Particular </th>
                                                    <th style={{ fontWeight: 700 }} align="center">Price </th>

                                                    {
                                                        cartData.PakageId == 0 ?
                                                            <th style={{ fontWeight: 700 }} align="center"> Sub. QTY </th> : ""
                                                    }

                                                    <th style={{ fontWeight: 700 }} align="center"> Number Of Users </th>

                                                    <th style={{ fontWeight: 700 }} align="center"> Total </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <InvoicePackage />
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={5} height={20}></td>
                                                </tr>
                                                {BankDetails && <tr>
                                                    <td colSpan={3} style={{ color: '#000', lineHeight: '1.5', verticalAlign: 'text-bottom' }} rowSpan={3}>
                                                        <p> <strong>Bank:</strong> {BankDetails.bank_name}</p>
                                                        <p><strong>A/c Name:</strong> {BankDetails.account_name}</p>
                                                        <p><strong>A/c No.</strong> {BankDetails.account_no}</p>
                                                        <p><strong>Branch:</strong> {BankDetails.branch}</p>
                                                        <p><strong>Code:</strong>  {BankDetails.code}</p>
                                                        {/* <p>Lipa na MPESA accepted at till No. 549407</p> */}
                                                    </td>
                                                    <OrderTotal cartdata={cartData} isshow={false} />
                                                </tr>}
                                                <tr>
                                                    <td height={30} />
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </td>
                                    <td width="20"></td>
                                </tr>
                            </table>
                        </div>
                        <table cellPadding={0} cellSpacing={0} width="100%" className="mail-footer" align="center" bgcolor="#fce8d1" style={{ borderTop: '1px solid #ddd' }}>
                            <tbody>
                                <tr>
                                    <td height={30} />
                                </tr>
                                <tr align="center">
                                    <td style={{ color: '#000000', fontWeight: 'bold', fontSize: '12px', lineHeight: '1.8' }}> {BankDetails?.address} <br /> +254 111 044600 | finance@virtual-essence.co.ke</td>
                                </tr>
                                <tr>
                                    <td height={10} />
                                </tr>
                                <tr align="center">
                                    <td style={{ color: '#000000', fontWeight: 'normal', fontSize: '12px' }}> www.msingipack.com </td>
                                </tr>
                                <tr>
                                    <td height={30} />
                                </tr>
                            </tbody>
                        </table>
                        <div className="step-navigation">
                            <Link to="/order-details" className='btn btn-text'><span className="material-icons">arrow_back</span> Preview Order</Link>
                            <Link to="/thank-you" className='btn btn-green' onClick={submitInvoice}>Continue <img src="/images/right-arrow.svg" className="white-icon v-sub ms-2" width="23" alt="icon" /></Link>
                        </div>
                    </center>
                </div>

            </section>
        </>
    );
}

export default Invoice;