import { USER_DETAILS, USER_ACCOUNT, Navclass, USER_NOTIFICATION } from "../Type.js/Type";
import Api from "../../Api/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HandleError from "../../Components/HandleError/HandleError";

const NavClass = (id) => (dispatch) => {
  dispatch({
    type: Navclass,
    payload: id,
  })
};

const UserList = (id) => (dispatch) => {
  const token = JSON.parse(localStorage.getItem("user-token"));
  const config = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token,
    },
  };
  fetch(Api.baseURL + `/user/${id}`, config)
    .then((resp) => resp.json())
    .then((result) => {
      dispatch({
        type: USER_DETAILS,
        payload: result,
      })
      if (result.status === "error") {
        HandleError(result)
      }
    })
};

const UserNotification = (id) => (dispatch) => {
  const token = JSON.parse(localStorage.getItem("user-token"));
  const config = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token,
    },
  };
  fetch(Api.baseURL + `/users/notification_count`, config)
    .then((resp) => resp.json())
    .then((result) => {
      if (result.status === "success") {
        console.log("state", result);
        dispatch({
          type: USER_NOTIFICATION,
          payload: result,
        })
      }
      else if (result.status === "error") {
        HandleError(result)
      }
    })
};

const UserUpdate = (userData) => (dispatch) => {
  const token = JSON.parse(localStorage.getItem("user-token"));
  const config = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token,
    },
    body: JSON.stringify(userData),
  };
  fetch(Api.baseURL + `/profile`, config)
    .then((resp) => resp.json())
    .then((result) => {
      if (result.status === "success") {
        toast.success(result.message);
        UserList(token.data.id);
      }
      if (result.status === "error") {
        toast.error(result.message);
      }
    })
    .catch((error) => {
      console.log("hell", error.response);
    });
};


const ChangePassword = (userData) => (dispatch) => {
  console.log("ffsd", userData);
  const token = JSON.parse(localStorage.getItem("user-token"));
  const config = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token,
    },
    body: JSON.stringify(userData),
  };
  fetch(Api.baseURL + `/change-password`, config)
    .then((resp) => resp.json())
    .then((result) => {
      if (result.status === "success") {
        toast.success(result.message);
        UserList(token.data.id);
      }
      if (result.status === "error") {
        toast.error(result.message);
      }
    })
    .catch((error) => {
      console.log("hell", error.response);
    });
};



const Useraccount = (Recordperpage, currentPage, UsersType) => (dispatch) => {
  const token = JSON.parse(localStorage.getItem("user-token"));
  const config = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token,
    },
    params: { "records_per_page": 10, "page_no": 1 }
  };
  fetch(Api.baseURL + `/otherusers?page_no=${currentPage}&&records_per_page=${Recordperpage}&&user_type=${UsersType}`, config)
    .then((resp) => resp.json())
    .then((result) => {
      if (result?.status === "success") {
        dispatch({
          type: USER_ACCOUNT,
          payload: result,
        })
      }
    })
    .catch((error) => {
      console.log(error)
    });
};

const AddUser = (userData, Recordperpage, currentPage, UsersType) => (dispatch) => {
  const token = JSON.parse(localStorage.getItem("user-token"));
  const config = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token,
    },
    body: JSON.stringify(userData),
  };
  fetch(Api.baseURL + `/otherusers`, config)
    .then((resp) => resp.json())
    .then((result) => {
      if (result?.status === "success") {
        dispatch(Useraccount(Recordperpage, currentPage, UsersType));
      }
      else if (result.status === "error") {
        toast.error(result.message)
      }
    })
};

const UseraccountUpdate = (userData, Recordperpage, currentPage, UsersType) => (dispatch) => {
  const token = JSON.parse(localStorage.getItem("user-token"));
  const config = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token,
    },
    body: JSON.stringify(userData),
  };
  fetch(Api.baseURL + `/otherusers/${userData.id}`, config)
    .then((resp) => resp.json())
    .then((result) => {
      if (result?.status === "success") {
        toast.success("User update successfully")
        dispatch(Useraccount(Recordperpage, currentPage, UsersType));
      }
      else if (result.status === "error") {
        toast.error(result.message)
      }
    })

};

export { UserList, UserUpdate, Useraccount, AddUser, UseraccountUpdate, NavClass, ChangePassword, UserNotification };
