import { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LogoutApiData } from "../../Redux/Action/AuthAction";
import { toast } from "react-toastify";
import "./DashboardNavbar.css";
import CommonModal from "../Common/Modal/Modal";
import { UserList, NavClass, UserNotification } from "../../Redux/Action/UserAction";
import Api from "../../Api";
import HandleError from "../HandleError/HandleError";
const DashboardNavbar = () => {
  const UserDetails = useSelector((state) => state.signupApiData);
  const [show, setShow] = useState(false);
  const [NotificationCount, setNotificationCount] = useState()
  const NotioficationCount = useSelector((state) => state.notification);

  const [IsClass, setisClass] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (localStorage.getItem("user-token")) {
    var userData = JSON.parse(localStorage.getItem("user-token")).data.id;
  }

  useEffect(() => {
    if (userData) {
      dispatch(UserList(userData));
      dispatch(UserNotification());
    }
  }, [userData]);

  useEffect(() => {
    dispatch(NavClass(IsClass));
    if (IsClass === false) {
      document.body.className = '';
    } else {
      document.body.className = 'sidebar-sticky';
    }
  }, [IsClass]);


  useEffect(() => {
    setNotificationCount(NotioficationCount?.data?.notification_count)
  }, [NotioficationCount?.data?.notification_count]);

  useEffect(() => {
    if (userData) {
      dispatch(UserList(userData));
      var timer = setInterval(() => {
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "BEARER " + token.data.token,
          },
        };
        fetch(Api.baseURL + `/users/notification_count`, config)
          .then((resp) => resp.json())
          .then((result) => {
            if (result.status === "success") {
              console.log("stateeeeeeeeee", result.data.notification_count);
              setNotificationCount(result.data.notification_count)
            }
            else if (result.status === "error") {
              HandleError(result)
            }
          })
      }, 3000);
    }
    return () => clearInterval(timer); // cleanup the timer
  }, [userData]);
  toast.configure();
  const logout = () => {
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const handleSubmit = () => {
    setShow(false);
    dispatch(LogoutApiData());
    localStorage.removeItem("user-token");
    toast.success("Logout Successfully");
    navigate("/login");
  };


  const body = () => {
    return (
      <>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div className="congrats-bx">
          <h2 className="mb-4">Confirm Logout!</h2>
          <p>Are you sure, you want to logout now?</p>
        </div>
      </>
    )
  }
  const modelData = body();

  let toggleBox = () => {
    setisClass(!IsClass)
    // console.log(document.body.className);

  };

  return (
    <>
      <header className="mpc-header">
        <div className="row align-items-center">
          <div className="col sidebar-toggle-effect">
            <div className="toggle-btn"
              onClick={toggleBox}
            >
              <span className="material-icons">
                menu
              </span>
            </div>
          </div>
          <div className="col">
            <div className="navbar-right">
              {/* {console.log("NotioficationCount", NotioficationCount.data?.notification_count)} */}
              <Link to="/notifications" className="notification">
                <div className="notifications">
                  <span className="material-icons">notifications_none</span>
                  <span className="notifications-count">
                    {NotificationCount}
                    {console.log(NotificationCount, "sahiba")}
                  </span>
                </div>
              </Link>
              <div className="navbar-user">
                <button className="user-btn" type="button"></button>

                <Link to="/my-profile" className="user-btn">
                  <img
                    src="/images/avatar2.svg"
                    className="img-fluid"
                    alt=""
                  />{" "}
                  <div>
                    <strong>
                      {" "}

                      {UserDetails.data === undefined
                        ? ""
                        : UserDetails.data.name}{" "}
                    </strong>{" "}
                  </div>
                </Link>

              </div>
            </div>
          </div>
        </div>
      </header>
      <CommonModal
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        show={show}
        ModalClass="confirmation-popup"
        Name="Logout"
        FooterClass="full-btn"
        modelData={modelData}
      />
    </>
  );
};

export default DashboardNavbar;
