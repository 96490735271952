import {
    PAKAGE_SUCCESS
  } from "../Type.js/Type";
  
  const initialState = {};
  const PakagePlanData = (state = initialState, action) => {
    let data = action.payload;
    switch (action.type) {
      case PAKAGE_SUCCESS:
        return data;
      default:
        return state;
    }
  };
  export default PakagePlanData;
  