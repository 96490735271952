import React, { useState, useEffect } from "react";
import ContactForm from "../Common/ContactForm/ContactForm";
import { Link, useLocation, useParams } from 'react-router-dom';
import './Footer.css';
import { useSelector, useDispatch } from "react-redux";
import { Tab, Tabs } from 'react-bootstrap';
import CommonButton from "../Common/Buttons/Buttons";
import { PakageListing } from "../../Redux/Action/PakageAction";
// import Plans from "../../Components/Plans/Plans";
const Footer = () => {
    const date = new Date()
    const myState1 = useSelector((state) => state.PakagePlanData);
    let dispatch = useDispatch();
    let location = useLocation();
    let result = location.pathname.slice(0, 21);
    let result1 = location.pathname.slice(0, 14);
    let result2 = location.pathname.slice(0, 16);
    let result3 = location.pathname.slice(0, 12);
    let result4 = location.pathname.slice(0, 13);
    let result5 = location.pathname.slice(0, 17);
    const [PakageDetails, setPakageDetails] = useState();
    // const errors = {};
    useEffect(() => {
        dispatch(PakageListing());
    }, [])

    useEffect(() => {
        // if (myState1.status === "success") {
        //     setisLoad(true)
        // }

        setPakageDetails(myState1.data?.pricing_details)
    }, [myState1])

    return (
        <>
            {location.pathname === "/lms-documentation" || location.pathname === "/lms-domains" || location.pathname === "/login" || location.pathname === "/sign-up" || location.pathname === "/forgot-password" || location.pathname === "/my-subscription"
                || location.pathname === "/my-profile" || location.pathname === "/user-account" ||
                result === `/Subscription-details` || location.pathname === `/dashboard` || result1 === "/assigned-user" ||
                result4 === "/view-content" || location.pathname === "/reports" || location.pathname === "/api-keys" ||
                location.pathname === "/documentation" || location.pathname === "/notifications" ||
                location.pathname === "/lms-domain" ||
                result5 === "/incremented-user" ||
                location.pathname === "/settings" || location.pathname === "/login-logs" || result2 === "/upgrade-request" || result3 === "/manage-user" || location.pathname === "/free-trial-request" ? null
                :
                <footer className={location.pathname === "/" || location.pathname === "/contact-us" ? "msingipack-ftr with-contact-form" : "msingipack-ftr"}>
                    {location.pathname === "/" || location.pathname === "/contact-us"
                        ?
                        <ContactForm />
                        :
                        null
                    }
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-4 msingipack-ftr-col">
                                        <h2>Terms</h2>
                                        <ul>
                                            <li><Link to="/terms-of-use">Terms of Use</Link></li>
                                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                            <li><Link to="/refund-policy">Refund Policy</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-4 msingipack-ftr-col">
                                        <h2>Content Plans</h2>
                                        <ul>
                                            <li>
                                                <Link to="/content-plans/bronze-plans">Bronze</Link>
                                            </li>
                                            <li>
                                                <Link to="/content-plans/silver-plans">Silver</Link>
                                            </li>
                                            <li>
                                                <Link to="/content-plans/gold-plans">Gold</Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="col-lg-4 msingipack-ftr-col">
                                        <h2>Resources</h2>
                                        <ul>
                                            <li><Link to="/getting-started">Getting started</Link></li>
                                            {/* <li><Link to="/api-documentation">API Documentation</Link></li> */}
                                            {/* <li><Link to="/integrations">Integrations</Link></li> */}
                                            <li><Link to="/#">Free Test Account</Link></li>
                                            <li><Link to="/faqs">FAQs</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 msingipack-ftr-col">
                                <h2>Contact Us</h2>
                                <p><span>Email:</span> <a href="mailto:hello@msingipack.com">hello@msingipack.com</a></p>
                                <p><span>Telephone:</span> <a href="tel:+254111044600">+254111044600</a></p>
                                <p><span>Address:</span> AppleWood Adams, Suite 511, Ngong Road P.O. Box 2422 - 00200 City Square, Nairobi, Kenya</p>
                                <div className="social-links">
                                    <a href="https://www.facebook.com/MsingiPACK" target="_blank"><img src="/images/facebook.svg" alt="icon" /></a>
                                    <a href="https://www.youtube.com/channel/UCPPPbsIbM300R2C3dQiTl9w" target="_blank"><img src="/images/youtube.svg" alt="icon" /></a>
                                    <a href="https://twitter.com/msingipack" target="_blank"><img src="/images/twitter.svg" alt="icon" /></a>
                                    <a href="https://www.linkedin.com/in/msingipack-elearning/" target="_blank"><img src="/images/linkedin.svg" alt="icon" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="copyright">
                            <Link to="/"><img src="/images/logo.svg" alt="logo" /></Link>
                            <p>© {date?.getFullYear()} MsingiPACK Cloud</p>
                            <div className="payment-method">
                                <img src="/images/payment-method.png" alt="payment-method" />
                            </div>
                        </div>
                    </div>
                </footer>
            }
        </>
    );
}


export default Footer;