import DashboardNavbar from "../../../Components/DashboardNavbar/DashboardNavbar";
import DashboardHeader from "../../../Components/DashboardHeader/DashboardHeader";
import "../../../Components/Common/ContactForm/ContactForm.css";
import "../Dashboard.css";
import { useEffect, useState } from "react";
import Api from "../../../Api";
import SubscriptionsList from "./subscriptionList"
import CommonModal from "../../../Components/Common/Modal/Modal";
import { InputText } from "../../../Components/Common/Inputs/Inputs";
import { useNavigate } from "react-router-dom"
import Loader from '../../../Components/Common/loader';
import CommonButton from "../../../Components/Common/Buttons/Buttons";
const MySubscription = () => {
  const navigate = useNavigate();
  const [SubscriptionList, setSubscriptionList] = useState()
  const [show, setShow] = useState(false);
  const [Loading, setLoading] = useState(false)
  const [UserTotal, setUserTotal] = useState(1)
  const [formErrors, setFormErrors] = useState({});
  const [SubscriptionId, setSubscriptionId] = useState()

  useEffect(() => {
    if (localStorage.getItem("flag") == 1) {
      localStorage.setItem("flag", 0)
      navigate("/invoice")
    }
    const userData = JSON.parse(localStorage.getItem('user-token'));
    const config = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "BEARER " + userData.data.token,
      },
      body: JSON.stringify({ "user_id": userData.data.id })
    };
    fetch(Api.baseURL + `/list_user_subscriptions`, config)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false)
        setSubscriptionList(data.data)
      })
      .catch((err) => {
        if (err) {
          console.log("this is err", err);
        }
      });
  }, [Loading]);


  const SubscriptionDetails = (id) => {
    navigate(`/Subscription-details/${id}`)
  }

  const UserList = (id) => {
    navigate(`/manage-user/${id}`)

  }

  const UpgradeUser = (id) => {
    // setShow(true);
    setSubscriptionId(id);

  }

  const handleClose = () => {
    setShow(false)

  }

  const EnterNumber = (e) => {
    e.preventDefault();
    setFormErrors(validate(e));
  }

  const validate = (e) => {
    const errors = {};
    if (!e.target.value) {
      errors.mob_no = "User is required!";
    } else if (isNaN(e.target.value)) {
      errors.mob_no = "Please enter Numeric value";
    } else if (e.target.value == 0) {
      errors.mob_no = "Number of user should be More then 0";
    } else if (e.target.value) {
      setUserTotal(e.target.value)
    }
    return errors;
  };
  const body1 = () => {
    return (
      <>
        <div className="row mb-3">

          <InputText
            className="col-2 mb-0"
            type="text"
            name="name"

            placeholder="Add User"
            onChange={EnterNumber}
            error={formErrors.mob_no}
            labelText="Add More User"
            required="true"
          // (e) => { setUserTotal(e.target.value) }
          />
        </div>
      </>
    )
  }
  let modelData = body1();

  const Submitdata = (e) => {
    console.log(Object.keys(formErrors).length, "formErrors");
    if (Object.keys(formErrors).length == "0") {
      e.preventDefault();
      const token = JSON.parse(localStorage.getItem("user-token"));
      const config = {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "BEARER " + token.data.token,
        },
        body: JSON.stringify({ tot_users: UserTotal })
      };
      fetch(Api.baseURL + `/request_upgrade_users/${SubscriptionId}`, config)
        .then((resp) => resp.json())
        .then((result) => {
          if (result?.status === "success") {
            setShow(false);
          }
        })
        .catch((error) => {
          console.log(error)
        });
    }

  };

  return (
    <>
      <CommonModal
        handleClose={handleClose}
        handleSubmit={Submitdata}
        show={show}
        modelData={modelData}
        Name={"Send Request"}
      />
      <DashboardHeader />
      {console.log(SubscriptionList, "SubscriptionList")}
      <div className="mpc-dashboard">
        <DashboardNavbar />
        <div className="dashboard-right-content">
          <h1 className="main-page-heading with-filter">
            <span>My Subscriptions</span>
            {
              SubscriptionList?.tot_subs <= 0 ?
                <CommonButton
                  isButton="true"
                  BtnColor="green btn-sm"
                  BtnText="Buy Plan"
                  //   disabled={Pakage.length === 0 || Object.keys(Pakage).length === 0}
                  onClick={() => navigate('/content-plans')}
                /> : null
            }
          </h1>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">User Name</th>
                  <th scope="col">Invoice Id</th>
                  <th scope="col">Total Users</th>
                  <th scope="col">Plan Name</th>
                  <th scope="col">Plan Tenure</th>
                  <th scope="col" style={{ textAlign: "center" }}>Payment Status</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Apply Date</th>
                  <th scope="col" style={{ textAlign: "center", whiteSpace: "noWrap" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {SubscriptionList != undefined ?
                  <>
                    <SubscriptionsList SubscriptionList={SubscriptionList} SubscriptionDetails={SubscriptionDetails} UserList={UserList} UpgradeUser={UpgradeUser} setLoading={setLoading} />
                  </>
                  :
                  <div md="8" className="d-flex align-items-center justify-content-center">
                    <Loader />
                  </div>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default MySubscription;
