import React, { useEffect, useState } from "react";
import "./ContentPlans.css";
import EachSubject from "./EachSubject";

const GoldContent = ({ GradeDetails, PakageDetails, prizeType, PlanType, HandleGoldplan, checkedbox, prize, GoldSubject }) => {
    let [subjectprice, setSubjectprice] = useState()
    useEffect(() => {
        setSubjectprice(parseInt(PakageDetails[`${2}`]?.[PlanType]) / parseInt(GoldSubject))
    })
    console.log("PakageDetails", typeof PakageDetails[`${2}`]?.[PlanType]);
    return (
        <>
            <div className="contentPlans-col" >
                <label className="checkbox2">
                    <input type="checkbox" checked={checkedbox} onClick={(e) => {
                        HandleGoldplan(e, PakageDetails[2])
                    }} />
                    <span>
                        <i></i> All Grades <EachSubject GradeDetails={GradeDetails} prize={prize == 0 ? PakageDetails[`${2}`]?.monthly_price : prize} PakageId="2" />
                    </span>
                </label>
                {GradeDetails.map((value, index) => {
                    return (
                        <div key={index} className="contentPlans-col">
                            <h2 className="contentPlans-title">
                                {value.grade_name}
                                {/* <span className="subject-price">
                                    <EachSubject prize={prize == 0 ? PakageDetails[`${2}`]?.monthly_price : subjectprice} PakageId="0" />
                                </span> */}
                            </h2>
                            <ul className="check-ul mt-4">
                                {value.subjects.map((value, index) => {
                                    return (
                                        <>
                                            <li>{value.subject_name}</li>
                                        </>
                                    )
                                })}
                            </ul>

                        </div>
                    )
                })}
            </div>
        </>
    )
}
export default GoldContent;