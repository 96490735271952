import { useEffect, useState } from 'react';
import DashboardNavbar from '../../../Components/DashboardNavbar/DashboardNavbar';
import DashboardHeader from '../../../Components/DashboardHeader/DashboardHeader';
import PaginatedItems from "../../../Components/Common/pagination";
import Api from '../../../Api';
import "../../../Components/Common/ContactForm/ContactForm.css";
import Loader from '../../../Components/Common/loader';
const moment = require('moment-timezone');

const LoginLogs = () => {
    const [listData, setlistData] = useState()
    const [currentPosts, setcurrentPosts] = useState();
    const [currentPage, setcurrentPage] = useState(1);
    const [loading, setLoading] = useState(true)
    const [local, setlocal] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
            params: { "page_no": currentPage }
        };
        fetch(Api.baseURL + `/list_login_logs/?page_no=${currentPage}`, config)
            .then((resp) => resp.json())
            .then((result) => {
                console.log(result, "res")
                setlistData(result.data.logs)
                setcurrentPosts(result.data.tot_logs)
                setLoading(false)
                if (result.status === "error") {
                    // HandleError(result)
                }
            })
    }, [currentPage])

    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };

    return (
        <>
            <DashboardHeader />
            <div className="mpc-dashboard">
                <DashboardNavbar />
                <div className="dashboard-right-content">
                    <h1 className="main-page-heading with-filter">
                        <span>Session Logs</span>
                        <div className="filter">
                        </div>
                    </h1>
                    <div className="notifications-table">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: '10%' }}>#</th>
                                        <th scope="col" style={{ width: '25%' }}>Name</th>
                                        <th scope="col" style={{ width: '25%' }}>Email</th>
                                        <th scope="col" style={{ width: '25%' }}>Login Time</th>
                                        <th scope="col" style={{ width: '15%' }}>Logout Time</th>
                                        {/* <th scope="col" style={{ width: '20%' }}>Action</th> */}
                                    </tr>
                                </thead>
                                {loading === true ?
                                    <Loader />
                                    :
                                    <>
                                        {currentPosts != 0 ? listData.map((ele, i) => {
                                            return (<>
                                                <tbody>
                                                    <tr>
                                                        <td scope="col">{i + 1}</td>
                                                        <td scope="col">{ele.name}</td>
                                                        <td scope="col">{ele.email}</td>
                                                        <td scope="col" className='max-width'>{moment(moment.tz(ele.login_stamp, local).tz(local).format()).format("dddd, MMMM Do YYYY, h:mm:ss a")}</td>
                                                        <td scope="col">{ele.logout_stamp ? moment(moment.tz(ele.logout_stamp, local).tz(local).format()).format("dddd, MMMM Do YYYY, h:mm:ss a") : ""}</td>
                                                    </tr>
                                                </tbody>
                                            </>)
                                        }) : <tbody><tr>
                                            <td colSpan="15" className="text-center noRec-found"> No record found</td>
                                        </tr> </tbody>}
                                    </>
                                }
                            </table>
                        </div>
                        {currentPosts > 10 &&
                            <PaginatedItems
                                List={currentPosts}
                                HandlePageClick={HandlePageClick}
                            />}
                    </div>
                </div>
            </div>
        </>
    )
}
export default LoginLogs