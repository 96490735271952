import { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import CommonButton from "../Common/Buttons/Buttons";
import { useDispatch } from "react-redux";
import { LogoutApiData } from "../../Redux/Action/AuthAction";
// import CommonModal from "../../Components/Common/Modal/Modal";
import "./Header.css";
import { toast } from "react-toastify";
import CommonModal from "../Common/Modal/Modal";
import FreeTrialPopup from "../../Pages/Pricing/FreeTrialPopup";
const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [height, setHeight] = useState(0);
  const [pricePopUp, setPricePopUp] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const header = useRef(null);
  const UserToken = localStorage.getItem("user-token");

  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleSubmit = () => {
    setShow(false);
    dispatch(LogoutApiData());
    localStorage.removeItem("user-token");
    toast.success("Logout Successfully");
    navigate("/login");
  };
  toast.configure();

  const logout = () => {
    setShow(true);
  };

  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        if (window.scrollY > 5) {
          setScroll(true);
          setHeight(header?.current?.clientHeight);
        } else {
          setScroll(false);
          setHeight(0);
        }
      },
      []
    );
  });

  const [IsClass, setisClass] = useState(false)

  useEffect(() => {
    if (IsClass === false) {
      document.body.className = '';
    } else {
      document.body.className = 'main-menu-open';
    }

  }, [IsClass]);

  const AddClass = () => {
    setisClass(!IsClass)
    console.log(document.body.className);

  }


  const callPricePage = () => {
    navigate("/pricing")
  }

  const body = () => {
    return (
      <>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div className="congrats-bx">
          <h2 className="mb-4">Confirm Logout!</h2>
          <p>Are you sure, you want to logout now?</p>
        </div>
      </>
    )
  }
  const modelData = body();

  // for subscription details page
  let result = location.pathname.slice(0, 21);
  let result1 = location.pathname.slice(0, 14);
  let result2 = location.pathname.slice(0, 16);
  let result3 = location.pathname.slice(0, 12);
  let result4 = location.pathname.slice(0, 13);
  let result5 = location.pathname.slice(0, 17);

  return (
    <>
      {location.pathname === "/lms-documentation" || location.pathname === "/my-subscription" ||
        location.pathname === "/lms-domain" || location.pathname === "/my-profile" || location.pathname === "/user-account" || result === `/Subscription-details` ||
        location.pathname === "/dashboard" || result1 === "/assigned-user" || result4 === "/view-content" || location.pathname === "/reports" || location.pathname === "/api-keys" ||
        location.pathname === "/documentation" || location.pathname === "/notifications" || location.pathname === "/settings" ||
        location.pathname === "/login-logs" || result5 === "/incremented-user" || result2 === "/upgrade-request" || location.pathname === "/free-trial-request" || result3 === "/manage-user" ||
        location.pathname === "/Lmsdomain"
        ? null : (
          <>
            <div className="header-placeholder" style={{ height: height }}></div>
            <header
              className={scroll ? "site-header sticky" : "site-header"}
              ref={header}
            >
              <nav className="navbar msingipack-default-navbar container-fluid">
                <div className="msingipack-navLogo-side">
                  <NavLink to="/" className="navbar-brand">
                    <img src="/images/logo.svg" alt="Logo" />
                  </NavLink>
                </div>
                <div className="toggle-btn  website-toggle" onClick={AddClass}>
                  <span className="material-icons">
                    menu
                  </span>
                </div>
                <div className="navbar-collapse msingipack-rightNav-side">
                  <ul className="navbar-nav">
                    <li className="nav-item" onClick={AddClass}>
                      <NavLink to="/content-plans" offset={-180} smooth={true}>
                        {" "}
                        Content Plans{" "}
                      </NavLink>
                    </li>
                    <li className="nav-item" onClick={AddClass}>
                      <NavLink to="/about"> About </NavLink>
                    </li>
                    <li className="nav-item hasDropDown" onClick={AddClass}>
                      <NavLink to="/pricing"> Pricing</NavLink>{" "}
                    </li>
                    <li className="nav-item hasDropDown position-relative">
                      <NavLink to="">Resources</NavLink>
                      {/* <span className="click-remove">Resources</span> */}
                      <div className="dropdown-custom">
                        <NavLink to="/getting-started" onClick={AddClass}>Getting started</NavLink>
                        {/* <NavLink to="/api-documentation" onClick={AddClass}>
                          API Documentation
                        </NavLink> */}
                        {/* <NavLink to="/integrations" onClick={AddClass}>Integrations</NavLink> */}
                        <NavLink to="/#" onClick={AddClass}>Free Test Account</NavLink>
                        <NavLink to="/faqs" onClick={AddClass}>FAQs</NavLink>
                      </div>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/contact-us" onClick={AddClass}> Contact </NavLink>
                    </li>
                    <li className="nav-item btn-item header-free-trial" style={{ userSelect: "auto" }} >   <FreeTrialPopup /></li>
                    {UserToken ? (
                      <>
                        <li className="nav-item btn-item">
                          <CommonButton
                            isLink="true"
                            URL="/dashboard"
                            BtnColor="green"
                            BtnText="Dashboard"
                          />
                        </li>
                        <li className="nav-item btn-item">
                          <CommonButton
                            isButton="true"
                            URL="/"
                            BtnColor="red"
                            BtnText="Logout"
                            onClick={logout}
                          />
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="nav-item btn-item">
                          <CommonButton
                            isLink="true"
                            URL="/login"
                            BtnColor="red"
                            BtnText="Login"
                          />
                        </li>
                        <li className="nav-item btn-item">
                          <CommonButton
                            isLink="true"
                            URL="/sign-up"
                            BtnColor="green"
                            BtnText="Sign up"
                          />
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </nav>
            </header>

            <CommonModal
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              show={show}
              ModalClass="confirmation-popup"
              FooterClass="full-btn"
              Name="Logout"
              modelData={modelData}
            />
          </>
        )
      }
    </>
  );
};

export default Header;
