import "../../../Components/Common/ContactForm/ContactForm.css";
import "../Dashboard.css";
import { useEffect, useState } from "react";
import AssignUser from "../AssignUser/Assignusers";
import Api from "../../../Api";
import { toast } from "react-toastify";
import { useNavigate, NavLink } from "react-router-dom";
import CommonButton from "../../../Components/Common/Buttons/Buttons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const FreeTrialList = ({ SubscriptionList, SubscriptionDetails, UserList, UpgradeUser }) => {
    let navigate = useNavigate();
    console.log(SubscriptionList, "SubscriptionList");
    if (SubscriptionList.subs.length > 0) {
        return (
            <>
                {SubscriptionList.subs.map((ele, i) => {
                    return (
                        <tr>
                            <th scope="row">{i + 1}</th>
                            <td>
                                {ele?.user_name}
                            </td>
                            <td>{ele?.tot_users}</td>
                            <td>{ele?.plan_name}</td>
                            <td>{ele?.plan_tenure}</td>
                            <td>
                                <span className={`badge badge-pill badge-${ele.is_payment_done === "Paid" ?
                                    "success" :
                                    "warning"
                                    }`
                                }>
                                    {ele?.is_payment_done}
                                </span>
                            </td>
                            <td>{ele?.start_date ? ele?.start_date : "-"}</td>
                            <td>{ele?.end_date ? ele?.end_date : "-"}</td>
                            <td>{ele?.apply_date}</td>
                            <td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip> View </Tooltip>}
                                >
                                    <button
                                        className="btn-theme"
                                        theme="info"
                                        onClick={ele.is_payment_done === "Paid" ? () => {
                                            SubscriptionDetails(ele.id)
                                        } : () => {
                                            toast.warning("Account inactive due to renewal or non payment")
                                        }
                                        }
                                    >
                                        <span className="material-icons">visibility</span>
                                    </button>
                                </OverlayTrigger>
                                &nbsp;
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip> Download Invoice </Tooltip>}
                                >
                                    <a className="btn-danger"
                                        href={Api.baseURL + `/download_invoice/${ele.id}`}
                                        theme="info"
                                        target="_blank"
                                    >
                                        <span className="material-icons">file_download</span>
                                    </a>
                                </OverlayTrigger>
                                &nbsp;
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip> Manage User </Tooltip>}
                                >
                                    <button
                                        className="btn-success"
                                        theme="info"
                                        onClick={ele.is_payment_done === "Paid" ? () => {
                                            UserList(ele.id);
                                        } : () => {
                                            toast.warning("Account inactive due to renewal or non payment")
                                        }
                                        }
                                    >
                                        <span className="material-icons">view_cozy</span>
                                    </button>
                                </OverlayTrigger>
                                &nbsp;
                                {ele.is_payment_done === "Unpaid" ?
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip> Upgrade User </Tooltip>}
                                    >
                                        <button
                                            className="btn-primary"
                                            theme="info"
                                            title="Upgrade User"
                                            onClick={() => {
                                                toast.warning("Account inactive due to renewal or non payment")
                                            }
                                            }
                                        >
                                            <span className="material-icons">edit</span>
                                        </button>
                                    </OverlayTrigger> :
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip> Upgrade User </Tooltip>}
                                    >
                                        <NavLink
                                            className="btn-primary"
                                            theme="info"
                                            to={`/upgrade-request/${ele.id}`}
                                            title="Upgrade User List"
                                        >
                                            <span className="material-icons">edit</span>
                                        </NavLink>
                                    </OverlayTrigger>
                                }
                            </td>
                        </tr>)
                })
                }
            </>
        );
    } else {
        return (
            <>
                <tr>
                    <td colSpan="15" className="text-center noRec-found"> No record found</td>
                </tr>
            </>)
    }
}

export default FreeTrialList;   
