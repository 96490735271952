import {
    DASHBOARD_STATE
} from "../Type.js/Type";

const initialState = {};
const DashboardStash = (state = initialState, action) => {
    let data = action.payload;
    console.log("DashboardStashDashboardStash", data);
    switch (action.type) {
        case DASHBOARD_STATE:
            return data;
        default:
            return state;
    }
};
export default DashboardStash;
