import { useEffect, useState } from 'react';
import DashboardNavbar from '../../../Components/DashboardNavbar/DashboardNavbar';
import DashboardHeader from '../../../Components/DashboardHeader/DashboardHeader';
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Api from '../../../Api';
import "../../../Components/Common/ContactForm/ContactForm.css";
import Loader from '../../../Components/Common/loader';
import ReactApexChart from 'react-apexcharts'

const Notifications = () => {
    return (
        <>
            <DashboardHeader />
            <div className="mpc-dashboard">
                <DashboardNavbar />
                <div className="dashboard-right-content">
                    <h1 className="main-page-heading with-filter">
                        <span>Notifications</span>
                        <div className="filter">
                        </div>
                    </h1>

                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className="repeat-white-bx shadow-none setting-bx">
                                <div className='bg-icon'>
                                    <span className="material-icons">confirmation_number</span>
                                </div>
                                <div className='setting-content-bx'>
                                    <h4>General</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi possimus similique cumque incidunt corrupti temporibus laudantium, non nesciunt placeat nostrum quisquam ab dignissimos, exercitationem iusto officiis quia, nulla optio in.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className="repeat-white-bx shadow-none setting-bx">
                                <div className='bg-icon'>
                                    <span className="material-icons">confirmation_number</span>
                                </div>
                                <div className='setting-content-bx'>
                                    <h4>General</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi possimus similique cumque incidunt corrupti temporibus laudantium, non nesciunt placeat nostrum quisquam ab dignissimos, exercitationem iusto officiis quia, nulla optio in.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className="repeat-white-bx shadow-none setting-bx">
                                <div className='bg-icon'>
                                    <span className="material-icons">confirmation_number</span>
                                </div>
                                <div className='setting-content-bx'>
                                    <h4>General</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi possimus similique cumque incidunt corrupti temporibus laudantium, non nesciunt placeat nostrum quisquam ab dignissimos, exercitationem iusto officiis quia, nulla optio in.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className="repeat-white-bx shadow-none setting-bx">
                                <div className='bg-icon'>
                                    <span className="material-icons">confirmation_number</span>
                                </div>
                                <div className='setting-content-bx'>
                                    <h4>General</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi possimus similique cumque incidunt corrupti temporibus laudantium, non nesciunt placeat nostrum quisquam ab dignissimos, exercitationem iusto officiis quia, nulla optio in.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Notifications;