import { useEffect, useState } from 'react';
import DashboardNavbar from '../../../Components/DashboardNavbar/DashboardNavbar';
import DashboardHeader from '../../../Components/DashboardHeader/DashboardHeader';
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import "../../../Components/Common/ContactForm/ContactForm.css";
import Loader from '../../../Components/Common/loader';
import ReactApexChart from 'react-apexcharts'
import { useDispatch, useSelector } from 'react-redux';
import RangeDatePicker from './RangeDatePicker';
import MultilevelStats from './MultilevalStats';
import { DatePickerAction } from '../../../Redux/Action/AuthAction';
import { Col } from 'react-bootstrap';
import moment from 'moment-timezone';
const Dashborad = () => {
    const UserDetails = useSelector((state) => state.signupApiData);
    const Dashoradlist = useSelector((state) => state.DashboardStash);
    let dispatch = useDispatch()
    // const [Dashoradlist, setDashboard] = useState()
    const navigate = useNavigate();
    const newDate = new Date()

    // data for populer session
    const data1 = [
        {
            label: "Highly Viewed Grade",
            value:
                Dashoradlist.highViewedGrade !== undefined
                    ? Dashoradlist.highViewedGrade.grade_name
                    : "",
            attrs: { md: "6", sm: "6" },
            label1: "Highly Viewed Subject",
            value1:
                Dashoradlist.highViewedSubject !== undefined
                    ? Dashoradlist.highViewedSubject.subject_name
                    : "",
            attrs: { md: "6", sm: "6" }
        },
    ];
    // data for avg session duratuion 
    const data2 = [
        {
            label: "Average Viewed Grade",
            value:
                Dashoradlist.highViewedGrade !== undefined
                    ? Dashoradlist.avgViewedGrade.grade_name
                    : "Psts",
            attrs: { md: "6", sm: "6" },
            label1: "Average Viewed Subject",
            value1:
                Dashoradlist.highViewedSubject !== undefined
                    ? Dashoradlist.avgViewedSubject.subject_name
                    : "",
            attrs: { md: "6", sm: "6" }
        },
    ];

    const series = [{
        name: 'Number Of User',
        type: 'area',
        data: Dashoradlist.GraphData
    }]
    const options = {
        chart: {
            type: 'line',
            stacked: false,
            zoom: {
                enabled: false
            },
            toolbar: {
                export: {
                    csv: {
                        headerCategory: 'Duration',
                    }
                }
            }
        },
        stroke: {
            width: [0, 2, 5],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '50%',
            }
        },

        fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
                inverseColors: false,
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                // stops: [00, 1000, 1000, 1000]
            }
        },

        markers: {
            size: 0
        },
        xaxis: {
            Duration: Dashoradlist.graphxaxisData,
            text: 'Points',
            title: {
                text: 'Duration'
            }
        },
        yaxis: {
            title: {
                text: 'Number Of User'
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0) + " points";
                    }
                    return y;
                }
            }
        }
    }

    useEffect(() => {
        if (localStorage.getItem("flag") == 1) {
            localStorage.setItem("flag", 0)
            navigate("/invoice")
        }
        const d = new Date();
        let year = d.getFullYear();
        dispatch(DatePickerAction({ "year": year }))
    }, []);

    return (
        <>
            <DashboardHeader />
            <div className="mpc-dashboard">
                <DashboardNavbar />

                <div className="dashboard-right-content">
                    <h1 className="main-page-heading with-filter">
                        <span>Welcome {UserDetails.data === undefined
                            ? "User "
                            : UserDetails.data.name}</span>
                        <div className="filter">
                        </div>
                    </h1>
                    {/* {console.log(DashboardStash, "DashboardStashDashboardStash")} */}
                    <div className="dashboard-details">
                        {Dashoradlist ?
                            <>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 d-flex">
                                        <div className="repeat-white-bx shadow-none dashboard-info-bx1 hover-effect w-100">
                                            <div className="row">
                                                <div className="col-3">
                                                    <NavLink to="/my-subscription">
                                                        <div className='bg-icon'>
                                                            <i className="large material-icons">event_repeat</i>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className="col-9">
                                                    <div className="text-right">
                                                        <h3 className="text-dark">Total Subscription {Dashoradlist.tot_subs}</h3>
                                                        <p>
                                                            <span>Paid <span data-plugin="counterup" className='count-num'> &nbsp;{Dashoradlist.tot_paid_subs} </span> &nbsp;&nbsp;</span>
                                                            |
                                                            <span>&nbsp;&nbsp; Unpaid<span className='count-num text-danger'> &nbsp;{Dashoradlist.tot_unpaid_subs}</span> </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/* end col*/}
                                    <div className="col-lg-4 col-md-6 d-flex">
                                        <div className="repeat-white-bx shadow-none dashboard-info-bx2 hover-effect w-100">
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className='bg-icon'>
                                                        <i className="material-icons">

                                                            people_outline
                                                        </i>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="text-right">
                                                        <h3 className="text-dark">Total Users {Dashoradlist.active_users + Dashoradlist.inactive_users}</h3>
                                                        <p>
                                                            <span>Active <span data-plugin="counterup" className='count-num'> &nbsp;{Dashoradlist.active_users} </span> &nbsp;&nbsp;</span>
                                                            |
                                                            <span>&nbsp;&nbsp; Inactive<span className='count-num text-danger'> &nbsp;{Dashoradlist.inactive_users}</span> </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div> {/* end row*/}
                                        </div> {/* end widget-rounded-circle*/}
                                    </div> {/* end col*/}
                                    <div className="col-lg-4 col-md-6 d-flex">
                                        <div className="repeat-white-bx shadow-none dashboard-info-bx3 hover-effect w-100">
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className='bg-icon'>
                                                        <i className="material-icons">description</i>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="text-right">
                                                        <h3 className="text-dark">Total Learning Materials</h3>
                                                        <p><span className='count-num'>{Dashoradlist.tot_content}</span></p>
                                                    </div>
                                                </div>
                                            </div> {/* end row*/}
                                        </div> {/* end widget-rounded-circle*/}
                                    </div> {/* end col*/}
                                </div>

                                <div className='row'>

                                    <div className='col-lg-7 d-flex'>
                                        <div className='repeat-white-bx w-100'>
                                            <div className=' row'>
                                                <div className='col-md-12'>
                                                    <h3 className="header-title mb-3 col">Overview Activity </h3>
                                                </div>
                                                <div className='col-md-12'>
                                                    <RangeDatePicker />
                                                </div>
                                            </div>
                                            <hr />
                                            <div className='flot-chart'>
                                                <>
                                                    <ReactApexChart options={options} series={series} type="line" />
                                                </>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-5 d-flex'>
                                        <div className='repeat-white-bx w-100'>
                                            <div className='row'>
                                                {Dashoradlist
                                                    ? data2.map((stats, idx) => (
                                                        <Col lg="12" md="12" sm="12" className="mb-5">
                                                            <MultilevelStats id={`small-stats-${idx}`}
                                                                label={stats.label}
                                                                value={stats.value}
                                                                label1={stats.label1}
                                                                value1={stats.value1}
                                                                heading="Average Session"
                                                            />
                                                        </Col>
                                                    ))
                                                    : "hello"}

                                                {Dashoradlist
                                                    ? data1.map((stats, idx) => (
                                                        <Col lg="12" md="12" sm="12" className="">
                                                            <MultilevelStats id={`small-stats-${idx}`}
                                                                label={stats.label}
                                                                value={stats.value}
                                                                label1={stats.label1}
                                                                value1={stats.value1}
                                                                heading="Popular Session"
                                                            />
                                                        </Col>
                                                    ))
                                                    : "hello"}
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </>
                            :
                            <div md="4" className="row">
                                <Loader />
                            </div>
                        }

                    </div>
                    <footer className='footer-bottom'>
                        <p className='text-center mb-0'>Copyright {newDate.getFullYear()} &copy; MsingiPACK Cloud</p>
                    </footer>
                </div>

            </div>

        </>

    );

}

export default Dashborad;