import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SectionTitle from "../Components/Common/SectionTitle/SectionTitle";
import { InputText } from "../Components/Common/Inputs/Inputs";
import CommonButton from "../Components/Common/Buttons/Buttons";
import "./Login/Login.css";
import { useParams } from 'react-router-dom';
import Api from "../Api";
import { toast } from "react-toastify";
const ResetPassword = () => {
    const myState1 = useSelector((state) => state.signupApiData);
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loginFieldData, setLoginFieldData] = useState({
        token: id,
        password: "",
        confirm_password: ""
    });

    const Login = (e) => {

        e.preventDefault();
        const config = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",

            },
            body: JSON.stringify(loginFieldData)
        };
        fetch(Api.baseURL + `/reset-password`, config)
            .then((resp) => resp.json())
            .then((result) => {
                if (result?.status === "success") {
                    toast.success(result.message)
                    setTimeout(() => {
                        navigate("/login");
                    }, 1000);
                }
                if (result?.status === "error") {
                    toast.error(result.message)
                }
            })
    };

    /* password show hide */
    const [Password, SetPassword] = useState(false);
    const ShowPassword = () => {
        SetPassword(!Password);
    };
    /* confirm password show hide */
    const [CPassword, SetCPassword] = useState(false);
    const ShowCPassword = () => {
        SetCPassword(!CPassword);
    };
    return (
        <>
            <section className="hero-section authentication-banner">
                <div className="banner-bg">
                    <img src="/images/banner-top-vector.svg" alt="banner-vectors" />
                </div>
                <div className="banner-btm-bg">
                    <img src="/images/banner-btm-vector.svg" alt="banner-vectors" />
                </div>

                <div className="container-fluid">
                    <SectionTitle
                        CustomClass="text-center pad-b-30"
                        title="Reset Password"
                        ShapeImage="heading-clip-1.svg"
                        SubHeading={<p className="what-sec-text">Set your new password</p>}
                    />
                    <form
                        onSubmit={Login}
                        onKeyPress={(e) =>
                            e.key === "Enter" ? Login : console.log("enter press here! ")
                        }
                    >
                        <div className="authentication-bx">

                            <InputText
                                type={Password ? "text" : "password"}
                                name="password"
                                placeholder="Password"
                                FormGroupClass="hasicon"
                                value={loginFieldData.password}
                                isPassword="true"
                                onChange={(e) => {
                                    setLoginFieldData({
                                        ...loginFieldData,
                                        password: e.target.value,
                                    });
                                }}
                                onClick={ShowPassword}
                                ChangeClass={Password ? "show-hide active" : "show-hide"}
                            />
                            <InputText
                                type={CPassword ? "text" : "password"}
                                name="confirm-password"
                                placeholder="Confirm Password"
                                FormGroupClass="hasicon"
                                value={loginFieldData.confirm_password}
                                isPassword="true"
                                onChange={(e) => {
                                    setLoginFieldData({
                                        ...loginFieldData,
                                        confirm_password: e.target.value,
                                    });
                                }}
                                onClick={ShowCPassword}
                                ChangeClass={CPassword ? "show-hide active" : "show-hide"}
                            />
                            {/* <div className="forgot-link">
                                <div className="form-checkbox">
                                    <div className="custom-check-block">
                                        <input
                                            type="checkbox"
                                            className="d-none form-check-input"
                                            id="exampleCheck1"
                                        />
                                        <label
                                            className="custom-check-label"
                                            htmlFor="exampleCheck1"
                                        >
                                            {" "}
                                            Remember me{" "}
                                        </label>
                                    </div>

                                </div>

                            </div> */}
                            <div className="form-group text-center">
                                <CommonButton
                                    isButton="true"
                                    BtnType="submit"
                                    BtnColor="green w-100"
                                    BtnText="Submit"
                                />
                            </div>

                        </div>
                    </form>
                </div>
            </section>
        </>
    );
};

export default ResetPassword;
