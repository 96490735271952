import { Navclass }
    from "../Type.js/Type";

const NavClass = "";
const navClass = (state = NavClass, action) => {
    console.log(action.payload, "payload");
    let data = action.payload;
    switch (action.type) {
        case Navclass:
            return data;
        default:
            return state;
    }
};
export default navClass;
