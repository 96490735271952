import { useEffect, useState } from "react";
import DashboardNavbar from "../../../Components/DashboardNavbar/DashboardNavbar";
import DashboardHeader from "../../../Components/DashboardHeader/DashboardHeader";
import "../../../Components/Common/ContactForm/ContactForm.css";
import "../Dashboard.css";
import CommonButton from "../../../Components/Common/Buttons/Buttons";
import AssignedUser from "./Usertab";
import CommonModal from "../../../Components/Common/Modal/Modal";
import Api from "../../../Api";
import Multiselect from 'multiselect-react-dropdown';
import PaginatedItems from "../../../Components/Common/pagination";
import HandleError from "../../../Components/HandleError/HandleError";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
const AssignUser = () => {
    let { id, grade_id } = useParams();
    let navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [Reload, setReload] = useState(false);
    const [currentPosts, setcurrentPosts] = useState();
    const [currentPage, setcurrentPage] = useState(1);
    const [UserData, setUserData] = useState();
    const [formErrors, setFormErrors] = useState({});
    const [Dropdowndata, setDropdowndata] = useState({});
    const [SelectedDropdowndata, setSelectedDropdowndata] = useState({});
    const [SelectedValue, setSelectedValue] = useState({});
    const [TotalUser, setTotalUser] = useState(0);
    const [planId, setPlanId] = useState(0);
    const [formValues, setFormValues] = useState({
        email: "",
        password: "",
        name: "",
        mob_no: ""
    });
    // ASSIGN UNAASIGN ALL
    const [show1, setShow1] = useState(false);
    /* checkbox data */
    const [checkBoxData, setcheckBoxData] = useState([])
    // recored per page
    const [Recordperpage, setRecordperpage] = useState(10);
    useEffect(() => {
        console.log("lgohumain", id, grade_id);
        setcheckBoxData([])
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
            body: JSON.stringify({ "page_no": currentPage, "id": id, "records_per_page": Recordperpage })
        };
        fetch(Api.baseURL + `/list_assign_users/${id}/${grade_id}`, config)
            .then((resp) => resp.json())
            .then((result) => {
                if (result.status === "success") {
                    console.log("success1234", result.data);
                    setUserData(result.data.data)
                    setcurrentPosts(result.data.tot_assign_user)
                    setSelectedDropdowndata(result.data.data)
                    setTotalUser(result.data.total_user)
                    setPlanId(result.data.plan_id)
                }
                if (result.status === "error") {
                    HandleError(result)
                }
            })
    }, [Reload, Recordperpage, currentPage]);



    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };


    const handleShow = () => {
        setShow(true)
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
        };
        fetch(Api.baseURL + `/otherusers`, config)
            .then((resp) => resp.json())
            .then((result) => {
                if (result?.status === "success") {
                    setDropdowndata({ "options": result.data.users })

                }
            })
            .catch((error) => {
                console.log(error)
            });
        // listing api of other user 
        const config1 = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
            body: JSON.stringify({ "id": id })
        };
        fetch(Api.baseURL + `/list_assign_users/${id}`, config1)
            .then((resp) => resp.json())
            .then((result) => {
                if (result.status === "success") {
                    // setUserData(result.data)
                    setSelectedDropdowndata(result.data)
                }
                if (result.status === "error") {
                    HandleError(result)
                }
            })
    };

    const handleClose = () => {
        setShow(false)
        setFormErrors({})
    }
    // for add new user

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const Submitdata = (e) => {
        const token = JSON.parse(localStorage.getItem("user-token"));
        setShow(false)
        let array = []
        for (let index = 0; index < SelectedValue.length; index++) {
            array.push(SelectedValue[index].id)
        }
        const config = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
            body: JSON.stringify({ "users": array })
        };
        fetch(Api.baseURL + `/assign_users/${id}`, config)
            .then((resp) => resp.json())
            .then((result) => {
                if (result?.status === "success") {
                    setDropdowndata({ "options": result.data.users })
                    setReload(!Reload)
                }
                if (result.status === "error") {
                    HandleError(result)
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    const onSelect = (selectedList, selectedItem) => {
        setSelectedValue(selectedList)
    }

    const onRemove = (selectedList, removedItem) => {
        setSelectedValue(selectedList)
    }

    const body = () => {
        return (
            <>
                <Multiselect
                    options={Dropdowndata.options} // Options to display in the dropdown
                    selectedValues={SelectedDropdowndata} // Preselected value to persist in dropdown
                    onSelect={onSelect} // Function will trigger on select event
                    onRemove={onRemove} // Function will trigger on remove event
                    displayValue="email" // Property name to display in the dropdown options
                    showCheckbox={true}
                    placeholder="Search User"
                />

            </>
        )
    }


    // edit existing user


    const handleEdit = (e) => {
        const { name, value } = e.target;
        // setUserData({ ...UserData, [name]: value });
    }


    const handleClose1 = () => {
        setShow1(false)
    }

    const Submitdata2 = () => {
        setShow1(false)
        const data = checkBoxData
        setcheckBoxData([])
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "DELETE",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
            body: JSON.stringify({ subscribers: data })
        };
        fetch(Api.baseURL + `/delete_bulk_assign_users`, config)
            .then((resp) => resp.json())
            .then((result) => {
                console.log("hellooooooooooo", result);
                if (result.status === "success") {
                    setReload(!Reload)
                    toast.success("Users delete successfully")
                }
                if (result.status === "error") {
                    HandleError(result)
                }
            })
    }


    let modelData = UserData ? body() : null;

    const selectUsers = (e) => {
        var data = []

        if (e.target.checked) {
            UserData.map((ele, i) => {
                data.push(ele.subscriber_uid)
            })
            setcheckBoxData([...data])
        } else {
            setcheckBoxData([])
            // const updatedDataRequiredField = checkBoxData.filter((value, index) =>  id !== value)
            // setcheckBoxData(updatedDataRequiredField)
        }
    }
    return (
        <>
            <CommonModal
                handleClose={handleClose1}
                handleSubmit={Submitdata2}
                show={show1}
                ModalTitle={"Delete user"}
                modelData="Are you sure you want to unassign these users from the study materials? These same users can be reassigned till the plan has not expired."
            />
            <DashboardHeader />
            <div className="mpc-dashboard">
                <DashboardNavbar />
                <div className="dashboard-right-content">
                    <h1 className="main-page-heading with-filter">
                        <span>Subscribers</span>
                        <div className="filter ">
                            {planId !== 3 ?
                                <p className="d-flex mobile-block align-items-center mb-0 text-center h4">

                                    <span>Total Student Limit
                                        <span data-plugin="counterup">
                                            &nbsp;
                                            {TotalUser}
                                        </span> &nbsp;&nbsp;
                                    </span>
                                    |<span>&nbsp;&nbsp;Assigned Users
                                        <span data-plugin="counterup">
                                            &nbsp;
                                            {currentPosts}
                                        </span> &nbsp;&nbsp;
                                    </span>
                                </p> : ""}
                            <div className="mobile-select-Subscribers d-flex justify-content-center align-items-center">
                                <select
                                    className=" btn btn-green"
                                    onChange={(e) => setRecordperpage(e.target.value)}
                                >
                                    <option disabled selected >Select Level</option>
                                    <>
                                        <option value="100" >
                                            100
                                        </option>
                                        <option value="50">
                                            50
                                        </option>
                                        <option value="20">
                                            20
                                        </option>
                                        <option value="10">
                                            10
                                        </option>
                                    </>
                                </select>
                                <span className="m-2" >
                                    <CommonButton
                                        isButton="true"
                                        BtnType="submit"
                                        BtnColor="green btn-sm"
                                        BtnText="Delete Selected  User"
                                        onClick={() => !checkBoxData.length == "0" ? setShow1(true) : ""}
                                    />
                                </span>
                                <button className="btn-theme" theme="info"
                                >
                                    <NavLink
                                        to="#"
                                        onClick={() => navigate(-1)}
                                        theme="info"
                                    >
                                        <span className="material-icons">keyboard_backspace</span>

                                    </NavLink>

                                </button></div>

                        </div>
                    </h1>
                    <div className="table-responsive">
                        <table className="table table-centered mb-0" id="btn-editable">
                            <thead>
                                <tr>
                                    <th><input type="checkbox"
                                        checked={checkBoxData.length > 0 && checkBoxData.length == UserData?.length ? true : false}
                                        onClick={(e) => selectUsers(e)}
                                    /></th>
                                    <th>S.N.</th>
                                    <th>#Username</th>
                                    <th> Name</th>
                                    <th>Email Address</th>
                                    <th>Mobile No.</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <AssignedUser UserData={UserData} reload={Reload} setReload={setReload} checkBoxData={checkBoxData} setcheckBoxData={setcheckBoxData} />
                            </tbody>
                        </table>
                    </div>
                    {currentPosts > 10 &&
                        <PaginatedItems
                            List={currentPosts}
                            HandlePageClick={HandlePageClick}
                        />
                    }
                </div>
            </div>


            <CommonModal
                handleClose={handleClose}
                handleSubmit={Submitdata}
                show={show}
                modelData={modelData}
                ModalTitle="Manage User"
            />
        </>
    );
};

export default AssignUser;
