import { useEffect, useState } from "react";
import DashboardNavbar from "../../../Components/DashboardNavbar/DashboardNavbar";
import DashboardHeader from "../../../Components/DashboardHeader/DashboardHeader";
import CommonButton from "../../../Components/Common/Buttons/Buttons";
import { InputText } from "../../../Components/Common/Inputs/Inputs";
import "../../../Components/Common/ContactForm/ContactForm.css";
import { useDispatch, useSelector } from "react-redux";
import "../Dashboard.css";
import "./MyProfile.css";
import { UserUpdate, ChangePassword, UserList } from "../../../Redux/Action/UserAction";
import Api from "../../../Api";
import { toast } from "react-toastify";
import HandleError from "../../../Components/HandleError/HandleError";

const Dashboard = () => {
  const state = useSelector((state) => state.signupApiData);
  const dispatch = useDispatch();
  const [Password, SetPassword] = useState(false);
  const [confirmPassword, SetConfirmPassword] = useState(false);
  const [CurrentPassword, SetShowCurrentPassword] = useState(false);
  const [UserData, SetUserData] = useState({});
  const [loadings, setLoadings] = useState(false)
  const [UserDetails, SetUserDetails] = useState({
    name: null,
    webUrl: "",
    mobNo: null,
    compName: "",
    phyAddress: "",
    postalAddress: "",
    companyEmail: "",
    companyMobno: "",
    city_town: "",
    webUrl: "",
    Email: ""
  });

  // change password
  const [ChangePasswordData, SetChangePasswordData] = useState({});


  var userid;
  if (localStorage.getItem("user-token")) {
    userid = JSON.parse(localStorage.getItem("user-token")).data.id;
  }

  useEffect(() => {
    SetUserData(state);
  }, [state]);


  if (localStorage.getItem("user-token")) {
    var userData = JSON.parse(localStorage.getItem("user-token")).data.id;
  }

  useEffect(() => {
    dispatch(UserList(userData));
  }, [loadings])
  const ShowPassword = () => {
    SetPassword(!Password);
  };

  const ShowConfirmPassword = () => {
    SetConfirmPassword(!confirmPassword);
  };
  const ShowCurrentPassword = () => {
    SetShowCurrentPassword(!CurrentPassword);
  };

  const updateProfile = (e) => {
    e.preventDefault();
    let data = {
      id: userid,
      name: UserDetails.name ? UserDetails.name : UserData.data.name,
      website_url: UserDetails.webUrl
        ? UserDetails.webUrl
        : UserData.data.website_url,

      mob_no: UserDetails.mobNo ? UserDetails.mobNo : UserData.data.mob_no,

      company_name: UserDetails.compName
        ? UserDetails.compName
        : UserData.data.company_name,

      email: UserDetails.Email
        ? UserDetails.Email
        : UserData.data.email,

      physical_address: UserDetails.phyAddress
        ? UserDetails.phyAddress
        : UserData.data.physical_address,

      postal_address: UserDetails.postalAddress
        ? UserDetails.postalAddress
        : UserData.data.postal_address,

      city_town: UserDetails.city_town
        ? UserDetails.city_town
        : UserData.data.city_town,

      company_email_address: UserDetails.companyEmail
        ? UserDetails.companyEmail
        : UserData.data.company_email_address,

      company_mob_no: UserDetails.companyMobno
        ? UserDetails.companyMobno
        : UserData.data.company_mob_no,
    };
    dispatch(UserUpdate(data));
  };

  // change password
  const changePassword = (e) => {
    e.preventDefault();
    let data = {
      old_password: ChangePasswordData.old_password,
      new_password: ChangePasswordData.new_password,
      confirm_password: ChangePasswordData.confirm_password,
    };
    dispatch(ChangePassword(data));
  }

  const changeTokan = (e) => {
    e.preventDefault();
    console.log('tettestttt');
    // Save lms domain
    const token = JSON.parse(localStorage.getItem("user-token"));
    const config = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "BEARER " + token.data.token,
      },
      // body: JSON.stringify({"email" : UserData.data.email , "password" : UserData.data.password}),
    };
    fetch(Api.baseURL + `/users/generate_lms_token`, config)
      .then((resp) => resp.json())
      .then((result) => {
        if (result.status === "success") {
          toast.success("LMS domain add successfully")
          setLoadings(!loadings)
          console.log(result, "res")
        }
        if (result.status === "error") {
          HandleError(result)
          console.log(result, "res")
        }
      })

  }
  return (
    <>
      <DashboardHeader />
      <div className="mpc-dashboard">
        <DashboardNavbar />
        <div className="dashboard-right-content">
          <h1 className="main-page-heading with-filter"> My Profile</h1>
          <div className="row">
            <div className="col-lg-6">
              <div className="repeat-white-bx shadow-none">
                {UserData.data !== undefined && (
                  <form>
                    {/* 1 */}
                    <InputText
                      defaultValue={UserData.data.name}
                      onChange={(e) =>
                        SetUserDetails({
                          ...UserDetails,
                          name: e.target.value,
                        })
                      }
                      type="text"
                      className="form-control"
                      placeholder="Full Name"
                      labelText="Full Name"
                    />
                    {/* 2 */}
                    <InputText
                      defaultValue={UserData.data.website_url}
                      onChange={(e) =>
                        SetUserDetails({
                          ...UserDetails,
                          webUrl: e.target.value,
                        })
                      }
                      type="text"
                      className="form-control"
                      placeholder="Web-Url"
                      labelText="Website Url"
                    />
                    {/* 3 */}
                    <InputText
                      defaultValue={UserData.data.mob_no}
                      onChange={(e) =>
                        SetUserDetails({
                          ...UserDetails,
                          mobNo: e.target.value,
                        })
                      }
                      type="number"
                      className="form-control"
                      placeholder="mob_no"
                      labelText="Mobile Number"
                    />
                    {/* 04 */}
                    <InputText
                      defaultValue={UserData.data.email}
                      onChange={(e) =>
                        SetUserDetails({
                          ...UserDetails,
                          Email: e.target.value,
                        })
                      }
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      labelText="Email"
                      isDisabled
                    />
                    {/* 4 */}
                    <InputText
                      defaultValue={UserData.data.company_name}
                      onChange={(e) =>
                        SetUserDetails({
                          ...UserDetails,
                          compName: e.target.value,
                        })
                      }
                      type="text"
                      className="form-control"
                      placeholder="company_name"
                      labelText="Company Name"
                    />
                    {/* 5*/}
                    <InputText
                      defaultValue={UserData.data.physical_address}
                      onChange={(e) =>
                        SetUserDetails({
                          ...UserDetails,
                          phyAddress: e.target.value,
                        })
                      }
                      type="text"
                      className="form-control"
                      placeholder="physical_address"
                      labelText="Physical Address"
                    />
                    {/* 6 */}
                    <InputText
                      defaultValue={UserData.data.postal_address}
                      onChange={(e) =>
                        SetUserDetails({
                          ...UserDetails,
                          postalAddress: e.target.value,
                        })
                      }
                      type="text"
                      className="form-control"
                      placeholder="postal_address"
                      labelText="Postal Address"
                    />
                    {/* 7 */}
                    <InputText
                      defaultValue={UserData.data.company_email_address}
                      onChange={(e) =>
                        SetUserDetails({
                          ...UserDetails,
                          companyEmail: e.target.value,
                        })
                      }
                      type="email"
                      className="form-control"
                      placeholder="company_email_address"
                      labelText="Company Email Address"
                    />
                    {/* 07 */}
                    <InputText
                      defaultValue={UserData.data.city_town}
                      onChange={(e) =>
                        SetUserDetails({
                          ...UserDetails,
                          city_town: e.target.value,
                        })
                      }
                      type="text"
                      className="form-control"
                      placeholder="City/Town"
                      labelText="City/Town"
                    />
                    {/* 8 */}

                    <InputText
                      defaultValue={UserData.data.company_mob_no}
                      onChange={(e) =>
                        SetUserDetails({
                          ...UserDetails,
                          companyMobno: e.target.value,
                        })
                      }
                      type="text"
                      className="form-control"
                      placeholder="company_mob_no"
                      labelText="Company Mobile Number"
                    />

                    <div className="form-group text-center mb-0">
                      <CommonButton
                        isButton="true"
                        onClick={updateProfile}
                        BtnType="submit"
                        BtnColor="green w-50"
                        BtnText="Update Profile"
                      />
                    </div>
                  </form>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="repeat-white-bx shadow-none">
                <form>
                  <InputText
                    type={CurrentPassword ? "text" : "password"}
                    name="password"
                    placeholder="Current Password"
                    required="required"
                    FormGroupClass="hasicon"
                    isPassword="true"
                    onClick={ShowCurrentPassword}
                    onChange={(e) =>
                      SetChangePasswordData({
                        ...ChangePasswordData,
                        old_password: e.target.value,
                      })
                    }
                    ChangeClass={
                      CurrentPassword ? "show-hide active" : "show-hide"
                    }
                  />
                  <InputText
                    type={Password ? "text" : "password"}
                    name="new_password"
                    placeholder="New Password"
                    required="required"
                    FormGroupClass="hasicon"
                    isPassword="true"
                    onChange={(e) =>
                      SetChangePasswordData({
                        ...ChangePasswordData,
                        new_password: e.target.value,
                      })
                    }
                    onClick={ShowPassword}
                    ChangeClass={Password ? "show-hide active" : "show-hide"}
                  />
                  <InputText
                    type={confirmPassword ? "text" : "password"}
                    name="confirm_password"
                    placeholder="Confirm Password"
                    required="required"
                    onChange={(e) =>
                      SetChangePasswordData({
                        ...ChangePasswordData,
                        confirm_password: e.target.value,
                      })
                    }
                    FormGroupClass="hasicon"
                    isPassword="true"
                    onClick={ShowConfirmPassword}
                    ChangeClass={confirmPassword ? "show-hide active" : "show-hide"
                    }
                  />
                  <div className="form-group text-center mb-0">
                    <CommonButton
                      isButton="true"
                      BtnType="submit"
                      BtnColor="green w-50"
                      BtnText="Update Password"
                      onClick={changePassword}
                    />
                  </div>
                </form>
              </div>
              {/* <div className="repeat-white-bx shadow-none">
                
                <InputText
                      value={UserData?.data?.lms_token}
                    
                        // SetUserDetails({
                        //   ...UserDetails,
                        //   companyMobno: e.target.value,
                        // })
                      
                      type="text"
                      className="form-control"
                      placeholder="LMS Token"
                      labelText="LMS Token"
                    />
                  <div className="form-group text-center mb-0">
                    <CommonButton
                      isButton="true"
                      BtnType="submit"
                      BtnColor="green w-50"
                      BtnText="Update LMS Token"
                      onClick={changeTokan}
                    />
                  </div>
                
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
