import React, { useEffect, useState } from "react";
import { InputText } from "../../Components/Common/Inputs/Inputs";
import "./ContentPlans.css";
import EachSubject from "./EachSubject";

const BronzeContent = ({ GradeDetails, PakageDetails, prize, pId, selectPakage, ManageUsers, Pakage }) => {
    return (
        <>
            {GradeDetails.map((value, index) => {
                return (
                    <div className="contentPlans-col" key={index} >
                        <h2 className="contentPlans-title">
                            {value.grade_name}
                            <EachSubject GradeDetails={GradeDetails} prize={prize == 0 ? PakageDetails[`${0}`]?.monthly_price : prize} PakageId="0" />
                        </h2>
                        <div className="row">
                            <div className="col-md-8">
                                <div className="row">
                                    {value.subjects.map((element) => {
                                        console.log("element");
                                        return <RenderCheckbox element={element} selectPakage={selectPakage} value={value} prize={prize == 0 ? PakageDetails[`${0}`]?.monthly_price : prize} pId={pId} />
                                    })}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="num-of-user contentplans_num">
                                    <label>
                                        {/* {console.log("fdfsfsdfdfsfsd", value)} */}
                                        <InputText FormGroupClass="mb-0" value={Pakage[value.grade_id] && Pakage[value.grade_id].users ? Pakage[value.grade_id].users : ""}
                                            onChange={(e) => ManageUsers(e, value.grade_id)} type="number" min="1"
                                            isDisabled={Pakage[value.grade_id] && Pakage[value.grade_id].subjects ? false : true}
                                        />

                                        {/* {Pakage[value.grade_id] && Pakage[value.grade_id].users ? Pakage[value.grade_id].users : ""} */}
                                        <span>{(Pakage[value.grade_id] && Pakage[value.grade_id].users) ? " No. of users" : (Pakage[value.grade_id] || Pakage[value.grade_id]?.users) ?
                                            <span style={{ color: "red" }}>
                                                Please Select Users
                                            </span>
                                            : " No. of users"}</span>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                )
            })}
        </>)
}

const RenderCheckbox = ({ element, selectPakage, value, prize, pId }) => {
    const [checked, setChecked] = useState(false)
    // console.log("element", checked);
    useEffect(() => {
        setChecked(false)
    }, [prize, pId])

    return (
        <div className="col-lg-4 mt-4" >
            <label className="checkbox2">
                <input type="checkbox"
                    onClick={(e) => {
                        console.log("element", checked)
                        selectPakage(e, element.grade_id, element.id, value.grade_name, element.subject_name, prize)
                        setChecked(!checked)
                    }} checked={checked} />
                <span>
                    <i></i> {element.subject_name}
                </span>
            </label>
        </div>)
}

export default BronzeContent;