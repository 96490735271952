import React, { useEffect, useState } from 'react';
import '../../Pages/OrderDetails/OrderDetails.css';
import { Currency } from '../../Currency';

const RenewOrderTotal = ({ cartdata }) => {
    const [subTotal, setsubTotal] = useState()
    const [TotalUsers, setTotalUsers] = useState()
    const [Discount, setDiscount] = useState()
    const [Tax, setTax] = useState()
    const [Totals, setTotal] = useState()

    useEffect(() => {
        let ans = 0;
        let Ans = cartdata?.package_name === "Bronze" ? cartdata.grades.map((oneKey, i) => {
            ans = ans + (oneKey.rate * oneKey.qty * oneKey.new_tot_users);
        }) : cartdata?.package_name === "Silver" ? cartdata.grades.map((oneKey, i) => {
            console.log("ans", ans);
            ans = ans + parseFloat(oneKey.rate * oneKey.new_tot_users);
        }) : cartdata?.package_name === "Gold" ?
            ans = ans + (parseFloat(cartdata.grades[0]?.rate) * cartdata?.new_no_of_users)
            : ""
        console.log("ans", ans);
        setsubTotal(ans)
    }, [])

    useEffect(() => {
        // let totalUsers = subTotal * cartdata?.new_no_of_users
        // let discount = ((totalUsers * cartdata?.discount_per) / 100)
        let tax = (((subTotal) * cartdata?.tax_per) / 100)
        let total = subTotal + tax
        // console.log("Dsdssfdfsdf", total, tax);
        // setTotalUsers(totalUsers)
        // setDiscount(discount)
        setTax(tax)
        setTotal(total)
    }, [subTotal])

    useEffect(() => {
        // let total = TotalUsers - Discount + Tax
        // setTotal(total)
    }, [Tax])
    return (
        <>
            {Totals && <td className='order-total' colSpan="2">
                <h2>Cart Total</h2>
                <p> Sub Total: <span> {Currency(subTotal)}</span></p>
                {/* <p>Discount {cartdata?.discount_per + "%"}: <span className='green-value'>Ksh {Discount}</span></p> */}
                <p>Tax VAT  {cartdata.tax_per + "%"}: <span>
                    {Currency(Tax)}
                </span></p>
                <p><strong>Total:</strong> <strong>{Currency(Totals)}</strong></p>
            </td>
            }
        </>
    );
}

export default RenewOrderTotal;
// (cartdata?.discount)?.toFixed(2)