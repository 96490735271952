import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import { useNavigate, useLocation, NavLink, useDispatch, useParams, Link } from "react-router-dom";
import CommonButton from "../../../Components/Common/Buttons/Buttons";
import DashboardHeader from "../../../Components/DashboardHeader/DashboardHeader";
import DashboardNavbar from "../../../Components/DashboardNavbar/DashboardNavbar";
import Api from "../../../Api";
import Iframe from "react-iframe";

const ViewContent = () => {
    const location = useLocation();
    const navigate = useNavigate()
    let { id } = useParams()
    const [fullScreen, setFullScreen] = useState(false)
    const [topicList, setTopicList] = useState()
    const [iFrameData, setiFrameData] = useState()

    // to get the height and width of window

    const ref = useRef(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const handleFullScreen = (element) => {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen(); // Firefox
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen(); // Safari
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen(); // IE/Edge
        }
    }

    const videoFullScreen = () => {
        const FullScreenEle = document.querySelector('#fullScreen_iframe_wrap');
        handleFullScreen(FullScreenEle);
    }

    useEffect(() => {
        document.addEventListener('fullscreenchange', (event) => {
            if (document.fullscreenElement) {
                setFullScreen(true);
            } else {
                setFullScreen(false);
            }
        });

        return () => {
            // Cleanup when the component unmounts
            document.removeEventListener('fullscreenchange');
        };
    }, []);


    useLayoutEffect(() => {
        setWidth(ref.current.clientWidth);
        setHeight(ref.current.offsetHeight);
    }, []);


    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user-token'));
        const body = { "subject_id": id }
        const config = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + userData.data.token,
            },
            body: JSON.stringify(body)
        };
        fetch(Api.baseURL + `/list_topics`, config)
            .then((res) => res.json())
            .then((data) => {
                setTopicList(data.data)
                setiFrameData(data.data[0].content_url)
            })
            .catch((err) => {
                if (err) {
                    console.log("this is err", err);
                }
            });

    }, [])

    useEffect(() => {
        console.log(document.referrer, "document.referrer");
        return () => {
            setupBeforeUnloadListener();
        }

    }, [])

    const doSomethingBeforeUnload = () => {
        // Do something
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
        };
        fetch(Api.baseURL + `/users/logout`, config)
            .then((res) => res.json())
            .then((data) => {
                localStorage.removeItem("user-token");
            })
            .catch((err) => {
                if (err) {
                    console.log("this is err", err);
                }
            });
    }

    // Setup the `beforeunload` event listener
    const setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            setTimeout(() => {
                return ev.returnValue = "Are you sure you want to close?";
            }, 10000);
        })
    }

    return (
        <div ref={ref}>
            <DashboardHeader />
            <div className="mpc-dashboard" >
                <DashboardNavbar />
                <div className="dashboard-right-content view-content-page">
                    <h1 className="main-page-heading with-filter view-page-content-head">
                        <span>
                            <button className="btn-theme" theme="info">
                                <NavLink
                                    to="#"
                                    onClick={() => navigate(-1)}
                                    theme="info"
                                >
                                    <span className="material-icons">keyboard_backspace</span>
                                </NavLink>
                            </button></span>
                        <CommonButton
                            isButton="true"
                            URL="/"
                            BtnColor={fullScreen ? "green btn-sm fixed-btn" : "green btn-sm"}
                            BtnText={fullScreen ? "Exit Full Screen" : "Full Screen"}
                            onClick={videoFullScreen}
                        />
                    </h1>
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="sidebar-iframe">
                                {width >= 992 ?
                                    <h3>{location?.state?.grade_category_id == 2 ? "Sub-Topic" : location?.state?.grade_category_id != 4 ? "Sub-Strand" : "Past Paper"}</h3>
                                    :
                                    null
                                }

                                {width <= 991 ?
                                    <>
                                        <h3>{location?.state?.grade_category_id == 2 ? "Sub-Topic" : location?.state?.grade_category_id != 4 ? "Sub-Strand" : "Past Paper"}</h3>
                                        <select onChange={(e) => setiFrameData(e.target.value)}>
                                            {topicList && topicList.map((ele, i) => {
                                                return (
                                                    <option style={{ "cursor": "pointer" }} value={ele.content_url} key={i}>
                                                        {ele.topic_name}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </>
                                    :
                                    (<ul> {topicList && topicList.map((ele, i) => {
                                        return (
                                            <li style={{ "cursor": "pointer" }} onClick={() => {
                                                setiFrameData(ele.content_url)
                                            }} key={i}>
                                                {ele.topic_name}
                                            </li>
                                        )
                                    })}
                                    </ul>
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-lg-10">
                            {/* className={fullScreen ? "iframe-sec active" : "iframe-sec"} */}
                            {/* sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation"  */}
                            <div id="fullScreen_iframe_wrap" className={fullScreen ? "iframe-sec active" : "iframe-sec"}>
                                {iFrameData &&
                                    <Iframe id="iframe_id" url={iFrameData + "? url=http://msingipack.arkasoftwares.in"} align="center" frameborder="0" allow="fullscreen"></Iframe>
                                }
                                {/* {iFrameData ? <Iframe id="iframe_id" url={iFrameData + "? url=http://msingipack.arkasoftwares.in"} sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation" align="center" frameborder="0" allow="fullscreen"></Iframe> :
                                     <div className="no-recod">no data found</div>
                                     } */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default ViewContent;