import React, { useState } from "react";
import { InputText } from "../../Components/Common/Inputs/Inputs";
import CommonButton from "../../Components/Common/Buttons/Buttons";
import "./ContentPlans.css";
import { useNavigate } from "react-router-dom";
import ContentPrize from "./ContentPrize";
import ContentHeader from "./ContentHeader";
import BronzeContent from "./BronzeContent";
import SilverContent from "./SilverContent";
import GoldContent from "./GoldContent";
import { useEffect } from "react";
import Api from "../../Api/index"
import { toast } from "react-toastify";
import { Currency } from "../../Currency";

const ContentTabs = ({ PakageType, GradeDetails, PakageId, PakageDetails, changeDiscount }) => {
  const [prize, isprizeType] = useState(0);
  const [pId, setPId] = useState("")
  const [checked, setChecked] = useState(0)
  const [PlanType, setPlanType] = useState("monthly_price")
  const [Pakage, setpakage] = useState([])
  const [Subject, settotalSubject] = useState(0)
  const [selectObject, setselectObject] = useState(0)
  const [CountValue, setCountValue] = useState(0)
  const [checkedbox, setcheckedbox] = useState(false)
  const [Data, setdata] = useState([])
  const [gold, setgoldpeice] = useState(0)
  const [finalPrice, setfinalPrice] = useState({})
  const [Selected_discount_per, setSelected_discount_per] = useState({})
  const [perUserprice, setuserPrice] = useState()
  const [totalValue, settotalValue] = useState(0)
  // total subject for gold 
  const [GoldSubject, setGoldSubject] = useState(10)

  const navigate = useNavigate();
  useEffect(() => {
    setselectObject(0)
    isprizeType(PakageDetails[PakageId]?.[PlanType])
  }, [PlanType, PakageType, PakageId])

  useEffect(() => {
    settotalSubject(0)
    setpakage([])
    setcheckedbox(false)
    setChecked(0)
    setdata([])
    setgoldpeice(0)
    setSelected_discount_per(PakageDetails[PakageId]?.monthly_discount_per)
  }, [PakageId])

  useEffect(() => {
    setfinalPrice({
      planType: PlanType,
      pakagetype: PakageType,
      pakage: Pakage,
      users: CountValue,
      prize: prize,
      Subject: Subject,
      PakageId: PakageId,
      discount_per: 0,
      tax_per: perUserprice?.tax_per,
      priceperuser: perUserprice?.price_per_user
    })
  }, [PlanType, Pakage, CountValue, perUserprice])

  useEffect(() => {
    const config = {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      }
    };
    fetch(Api.baseURL + `/fetch_settings`, config)
      .then((res) => res.json())
      .then((data) => {
        setuserPrice(data.data)
      })
      .catch((err) => {
        if (err) {
        }
      });
    if (PakageId == "2") {
      fetch(Api.baseURL + `/get_dashboard_stats`, config)
        .then((res) => res.json())
        .then((data) => {
          setGoldSubject(100)
        })
        .catch((err) => {
          if (err) {
          }
        });
    }
  }, [])

  // BRONZE PLAN
  const selectPakage = async (e, id, subject_id, Grade_name, Subject_name, perSubjectprize) => {
    console.log("locallocallocal", id, subject_id, Grade_name, Subject_name, perSubjectprize);
    if (e.currentTarget.checked) {
      settotalSubject(Subject + 1)
    } else {
      settotalSubject(Subject - 1)
    }
    let data
    data = {
      [id]: [
        parseInt(subject_id)
      ]
    }
    var newArray = [];
    if (e.currentTarget.checked) {
      let data
      let subjects
      subjects = {
        subject_name: Subject_name,
        subject_id: subject_id
      }
      data = {
        [id]: {
          grade_name: Grade_name,
          grade_id: id,
          subjects: [subjects],
          rate: perSubjectprize,
          qty: null,
          users: Pakage[id] ? Pakage[id].users : null,
          price: 102222,
        }
      }
      console.log("dtaa.id", data[id])
      if (Object.keys(Pakage).length > 0 && Pakage[id] && Pakage[id].subjects) {
        data[id].qty = await Pakage[id].subjects.length + 1
        Pakage[id].subjects.push(subjects)
        calculatePrice()
        // const myPromise = new Promise((resolve, reject) => {
        // resolve(data[id].qty = Pakage[id].subjects.length + 1);
        // });
        // myPromise.then(setpakage((prev) => ({ ...prev, [id]: { ...prev[id], "users": e.target.value } })))
        console.log("Fsdfhsjkdfhsdfsdfs5345435435", Pakage);
      } else {
        data[id].qty = await 22
        console.log("Fsdfhsjkdfhsdfsdfs5345435435", Pakage);
        setpakage({ ...Pakage, ...data })
      }
      console.log(data, "imdata");
    } else {
      newArray = Pakage[id].subjects.map(function (el, i) {
        if (el.subject_id == subject_id) {
          return "remove";
        } else {
          return "keep";
        }
      })
      newArray.map(function (ele, i) {
        if (ele == "remove") {

          Pakage[id].subjects.splice(i, 1);
          calculatePrice()
        }
      });
      if (Pakage[id].subjects.length == 0) {
        console.log("fsdnfsfsdfs ", Pakage);
        delete Pakage[id]
      }
    }
  }


  useEffect(() => {
    calculatePrice()
  }, [Pakage])


  // SILVER PLAN
  const HandleselectObject = (e, id, price, Grade_name, subjects) => {
    console.log("local", price);
    let data = []
    let data1 =
    {
      grade_name: Grade_name,
      grade_id: id,
      Subject: [subjects],
      rate: price,
      qty: 4,
      price: price,
    }

    if (e.currentTarget.checked) {
      setcheckedbox(true)
      setdata([...Data, parseInt(e.target.name)])
      var number = parseInt(id)
      setpakage([...Pakage, data1])
      // setselectObject(selectObject + parseFloat(price))
      // settotalValue(totalValue + (parseFloat(price)*))
    } else {
      const found = Data.find(element => element == e.target.name);
      const data = arrayRemove(Data, found)
      const found1 = Pakage.find(element => element.grade_id == id);
      const data1 = arrayRemove(Pakage, found1)
      var number = parseInt(id)
      setdata([...data])
      setpakage([...data1])

      // setselectObject(selectObject - parseFloat(price))
    }
  }
  // GOLD PLAN 
  const HandleGoldplan = (e, price) => {
    console.log("PakageDetails[PakageId]?.[PlanType]", PakageDetails[PakageId]?.[PlanType] * CountValue);
    isprizeType(PakageDetails[PakageId]?.[PlanType])
    let data1 =
    {
      grade_name: "grade1",
      grade_id: "id",
      Subject: [{ id: 3, grade_id: 1, subject_name: "moral scince" }, { id: 4, grade_id: 2, subject_name: "scince" }],
      rate: 2,
      qty: 4,
      price: 10,
      users: 2
    }
    if (e.currentTarget.checked) {
      console.log("PlanTypes", parseInt(price?.[PlanType]) * perUserprice.price_per_user);
      setgoldpeice(parseInt(price?.[PlanType]))
      setcheckedbox(true)
      setpakage([...Pakage, data1])
      // settotalValue(parseInt(PakageDetails[PakageId]?.[PlanType]) * CountValue)
    } else {
      setgoldpeice(0)
      setcheckedbox(false)
      setpakage([])
      setCountValue(0)
      // settotalValue(0)
    }
  }

  // COMMON
  const setprizeType = (e, id, arg, PlanTypes, istrue, PakageDetails, discount_per) => {

    const value = [];
    changeDiscount(PakageDetails[PakageId]?.[discount_per])
    value.push(e.target.value)
    setCountValue(1)
    setPId(id)
    setPlanType(PlanTypes)
    isprizeType(e.target.value)
    setChecked(arg);
    setSelected_discount_per(0)
    setcheckedbox(false)
    setdata([])
    setgoldpeice(0)
    setpakage([])
    settotalSubject(0)
    settotalValue(0)
  }
  const FinalPrice = async () => {
    if (Pakage.length !== 0) {
      let check = false

      for (const property in Pakage) {
        console.log(Pakage[property], "Pakagfsdfsfsfe[property]");
        let num = isNaN(Pakage[property].users)
        // for gold packagge
        if (PakageId == 0 && Pakage[property].users !== null && Pakage[property].users !== 0 && Pakage[property].subjects) {
          check = true
          continue
        }
        // for silver package
        else if ((PakageId == 1 && Pakage[property].users !== null && Pakage[property].users !== 0 && num == false && Pakage[property].users !== undefined && Pakage[property].Subject)) {
          console.log(Pakage, " Pakage[property]");
          check = true
          continue
        }
        else if (PakageId == 2 && CountValue != 0) {
          check = true
          continue
        }
        else {
          check = false
          toast.error("Please input number the number of students in selected grades.");
          break
        }
      }
      if (check) {
        // toast.error("No. of user should be more then ");
        localStorage.setItem('cart_item', JSON.stringify(finalPrice))
        navigate('/order-details');
      }
    }
  }
  function arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  }

  const calculatePrice = (e = "", g_id = "") => {
    let Value = 0
    Object.values(Pakage).map(function (key, i) {
      console.log("Fsdfsf45435", Pakage)
      if (PakageType === "Bronze") {
        if (Pakage[g_id] && (Pakage[g_id].grade_id === key.grade_id)) {
          Value = Value + Number(e.target.value) * parseInt(Pakage[g_id].subjects.length) * parseInt(Pakage[g_id].rate)
        } else {
          console.log("Fsdfsf45435", parseInt(key.users), parseInt(key.subjects.length), parseInt(key.rate))
          Value = Value + Number(key.users) * parseInt(key.subjects.length) * parseInt(key.rate)
        }
      }
      else if (PakageType === "Silver") {
        let user = key.users || 0;
        console.log("Fsdfsf45435Fsdfsf45435", user, parseInt(key.price))
        Value = Value + (Number(key.price) || 0) * Number(user)

      }
    });
    console.log("ValueValueValueValue", Value)
    settotalValue(Value)
  }

  // manage user grade wise
  const ManageUsers = (e, g_id) => {
    if (e.target.value.length < 13) {


      if (PakageType === "Bronze") {
        if (Pakage[g_id]) {
          console.log("shfdshdfjsdjkfds", parseInt(Math.abs(e.target.value)));
          setpakage((prev) => ({ ...prev, [g_id]: { ...prev[g_id], "users": parseInt(Math.abs(e.target.value)) } }))
          // Pakage[g_id].users = e.target.value
          setCountValue("1")
          calculatePrice(e, g_id)
        } else {
          console.log("Fsdfhsjkdfhsdfsdfs5345435435", Pakage);
          setpakage((prev) => ({ ...prev, [g_id]: { ...prev[g_id], "users": parseInt(Math.abs(e.target.value)) } }))
          setCountValue("1")
        }
      }
      else if (PakageType === "Silver") {
        // let AddKey = Pakage.filter((ele, i) => ele.grade_id == g_id).map((ele, i) => {
        //   ele.users = e.target.value
        //   return ele
        // })
        let AddKey = Pakage.map((ele, i) => {
          console.log("eellelelelee", e.target.value > 0)
          if (g_id == ele.grade_id && ele.Subject[0].length > 0) {
            ele.users = Math.abs(parseInt(e.target.value))
          }
          return ele
        })
        setpakage(AddKey)
        calculatePrice(e, g_id)
        console.log("fskdgfsjdfgfgsjhgdgg", AddKey, Pakage);
        if (Pakage[g_id]) {
          // setpakage((prev) => ([...prev, [g_id]: { ...prev[g_id], "users": e.target.value }]))
          // // Pakage[g_id].users = e.target.value
          // setCountValue("1")
          // calculatePrice(e, g_id)
        }
      }
    }

  }
  return (
    <>
      <>
        {PakageType === "Bronze" ?
          <>
            <div className="row align-items-center">
              <ContentHeader id="0" />
              <ContentPrize PakageId={PakageId} PakageDetails={PakageDetails} setprizeType={setprizeType} checked={checked} PakageDetail={PakageDetails} />
            </div>
            <BronzeContent GradeDetails={GradeDetails} PakageDetails={PakageDetails} prize={prize} pId={PakageId} selectPakage={selectPakage} ManageUsers={ManageUsers} Pakage={Pakage} />
          </>
          : PakageType == "Silver" ?
            <SilverContent PakageId={PakageId} PakageDetails={PakageDetails} HandleselectObject={HandleselectObject} GradeDetails={GradeDetails} setprizeType={setprizeType} prize={prize} pId={pId} checked={checked} PlanType={PlanType} checkedbox={checkedbox} data={Data} ManageUsers={ManageUsers} Pakage={Pakage} /> 
          : <><div className="row align-items-center">
              <ContentHeader id="2" />
              <ContentPrize PakageId={PakageId} PakageDetails={PakageDetails} setprizeType={setprizeType} checked={checked} PakageDetail={PakageDetails} />
            </div>
              <GoldContent GradeDetails={GradeDetails} PakageDetails={PakageDetails} PlanType={PlanType} HandleGoldplan={HandleGoldplan} checkedbox={checkedbox} prize={prize} GoldSubject={GoldSubject} /></>
        }
      </>
      <div className="col-parent-row">
        <div className="subscription-total">
          <div className="num-of-user">
            {PakageId == 2 ?
              <>
                <label>
                  No. of users{" "}
                  <small>
                    (Please enter the number of users to be accessing the content)
                  </small>
                </label>
                <InputText FormGroupClass="mb-0" step="1" type="number" min="1" value={CountValue} onChange={(e) => checkedbox && setCountValue(e.target.value)} />
              </>
              : ""}

          </div>
          <div className="grand-total-bx">
            {perUserprice && <p>
              {console.log(prize, "perUserprice")}
              <strong>Total:</strong> {PakageId == 2 ? Currency(prize * CountValue) : Currency(totalValue)}
            </p>}
            {/* {perUserprice && <p>
              <strong>Total:</strong> Ksh {parseFloat((CountValue * perUserprice.price_per_user) * (PakageId === 0 ? Subject * PakageDetails[`${[PakageId]}`]?.[PlanType] : PakageId === 1 ? selectObject : PakageId === 2 ? gold : 0)).toFixed(2)}
            </p>} */}
            {console.log(GoldSubject, "selectObject")}
            <CommonButton
              isButton="true"
              BtnColor="green"
              BtnText="Continue"
              hasIconImg="true"
              disabled={Pakage.length === 0 || Object.keys(Pakage).length === 0}
              onClick={
                FinalPrice
              }
              IconImgPath="right-arrow.svg"
            />
          </div>
        </div>
      </div>

    </>
  );
};

export default ContentTabs;
