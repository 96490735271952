import React, { useRef, useState } from "react";
import { InputText } from "../../Components/Common/Inputs/Inputs";
import CommonButton from "../../Components/Common/Buttons/Buttons";
import "./ContentPlans.css";
import ContentPrize from "./ContentPrize";
import ContentHeader from "./ContentHeader";
import EachSubject from "./EachSubject";

const SilverContent = ({ GradeDetails, PakageDetails, PakageId, prize, pId, setprizeType, checked, PlanType, HandleselectObject, name, checkedbox, data,
    ManageUsers, Pakage }) => {
    let ref = useRef()
    const SubjectPakageDetails = PakageDetails[1].packagePlanDetails

    const inputData = (e) => {
        if (e.target.value.length < 10) {

            // e.target.style.width = `${e.target.value.length + 200}px`
        }
        console.log("ffsdfsdfsdref", e.target.value.length);
        // ref = ref.current.style.width = "100px"
    }
    if (GradeDetails) {
        return (
            <>
                {GradeDetails.map((value, index) => {
                    const inputValue = Pakage.find((ele) => ele.grade_id === value.grade_id)
                    console.log("SubjectPakageDetails", inputValue);
                    return (
                        <label key={index} className="contentPlans-col-outer"
                        >
                            <label className="contentPlans-col-outer">
                                <input type="checkbox" value={value} checked={index == data.find(element => element == index) ? checkedbox : ""} name={index} onChange={(e) => {
                                    HandleselectObject(e, value.grade_id, SubjectPakageDetails[`${index}`]?.[PlanType], value.grade_name, value.subjects, SubjectPakageDetails[`${index}`]?.[PlanType])
                                }} />
                                <div className="contentPlans-col">
                                    <div className="row align-items-center">
                                        <div className="col-sm-7">
                                            <h2 className="contentPlans-title">
                                                <i></i>
                                                {value.grade_name}
                                                <span className="subject-price">
                                                    <EachSubject prize={SubjectPakageDetails[`${index}`]?.[PlanType]} PakageId={index} />
                                                </span>
                                            </h2>
                                        </div>
                                        <ContentPrize PakageId={index} PakageDetails={SubjectPakageDetails} setprizeType={setprizeType} checked={checked} PakageDetail={PakageDetails} />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <ul className="check-ul mt-4">
                                                {value.subjects.map((value, index) => {
                                                    return (
                                                        <>
                                                            <li>{value.subject_name}</li>
                                                        </>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="num-of-user contentplans_num">
                                                <label>
                                                    <InputText FormGroupClass="mb-0"
                                                        // ref={ref}
                                                        // style={{ width: ref === undefined ? ref : "67px" }}
                                                        onChange={(e) => {
                                                            ManageUsers(e, value.grade_id)
                                                        }}
                                                        type="number"
                                                        min="0"
                                                        onFocus={
                                                            inputData}
                                                        value={inputValue ? inputValue.users : null}
                                                        isDisabled={index == data.find(element => element == index) ? false : true}
                                                    />
                                                    <span >
                                                        {(inputValue && inputValue?.users) ? " No. of users" : (inputValue || inputValue?.users) ?
                                                            <span style={{ color: "red" }}>
                                                                Please Select Users
                                                            </span>
                                                            : " No. of users"}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </label>
                    )
                })}
            </>)
    } else {
        return (<>"hello"</>)
    }

}
export default SilverContent;
