import DashboardNavbar from "../../../Components/DashboardNavbar/DashboardNavbar";
import DashboardHeader from "../../../Components/DashboardHeader/DashboardHeader";
import { SelectBox } from "../../../Components/Common/Inputs/Inputs";
import "../../../Components/Common/ContactForm/ContactForm.css";
import "../Dashboard.css";
import image from "../../../Assets/actionIcon/trash.svg"
import image1 from "../../../Assets/actionIcon/edit.svg"
import image2 from "../../../Assets/actionIcon/eye.svg"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Api from "../../../Api";
import { Useraccount } from "../../../Redux/Action/UserAction"
import { useDispatch, useSelector } from "react-redux";
import HandleError from "../../../Components/HandleError/HandleError";
import CommonModal from "../../../Components/Common/Modal/Modal";
import { toast } from "react-toastify";
const UserTab = ({ EditUser, setcheckBoxData, checkBoxData, Recordperpage, currentPage, UsersType }) => {
    const myState1 = useSelector((state) => state.UserDetails);
    const [UserId, setUserId] = useState()
    const [show, setShow] = useState(false);
    const [PopupNumber, setPopupNumber] = useState(1);

    // checkbox data

    const navigate = useNavigate();
    let dispatch = useDispatch();

    const [reload, setReload] = useState(false)
    useEffect(() => {
        dispatch(Useraccount(Recordperpage, currentPage, UsersType));
    }, [reload]);

    const DeleteUser = (id) => {
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "DELETE",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
        };
        fetch(Api.baseURL + `/otherusers/${UserId}`, config)
            .then((resp) => resp.json())
            .then((result) => {
                if (result?.status === "success") {
                    setReload(!reload)
                    setShow(false)
                    toast.success("User delete successfully")
                }
                if (result?.status === "error") {
                    HandleError(result)
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }


    const SendEmail = () => {
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
        };
        fetch(Api.baseURL + `/send_login_details/${UserId}`, config)
            .then((resp) => resp.json())
            .then((result) => {
                if (result?.status === "success") {
                    setReload(!reload)
                    setShow(false)
                    toast.success("Credentials send successfully")
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }


    const SendEmailPopUp = (id, popupNumber) => {
        setUserId(id)
        setPopupNumber(popupNumber)
        setShow(true);
    }

    const handleClose = () => {
        setShow(false)
    }

    const body1 = () => {
        return (
            <>
                <div className="row mb-3">
                    <p className='input-success'> Are you sure you want send details in the mail to this user !!</p>
                </div>
            </>
        )
    }


    const body2 = () => {
        return (
            <>
                <div className="row mb-3">
                    <p className='input-success'> Are you sure you want to delete the users? this will not be retrievable and unassigned from all study materials. !!</p>
                </div>
            </>
        )
    }
    let modelData = PopupNumber == 0 ? body1() : body2();


    const selectUser = (e, id) => {
        if (e.target.checked) {
            setcheckBoxData([...checkBoxData, id])
        } else {

            const updatedDataRequiredField = checkBoxData.filter((value, index) => id !== value)
            setcheckBoxData(updatedDataRequiredField)
        }
    }
    return (
        <>
            <CommonModal
                handleClose={handleClose}
                handleSubmit={PopupNumber == 0 ? SendEmail : PopupNumber == 1 ? DeleteUser : null}
                show={show}
                modelData={modelData}
                Name={PopupNumber == 0 ? "Send" : PopupNumber == 1 ? "Delete" : null}
                ModalTitle={PopupNumber == 0 ? "Send User Credentials" : PopupNumber == 1 ? "Delete User !!" : null}
            />
            {myState1.data?.users.length != "0" ? myState1.data?.users.map((ele, i) => {
                return (
                    <tr>
                        <td><input type="checkbox" checked={ele.id == checkBoxData.find(element => element == ele.id) ? true : ""} onClick={(e) => selectUser(e, ele.id)} /></td>
                        <td>{i + 1 + (currentPage - 1) * 10}</td>
                        <td>{ele.username}</td>
                        <td>{ele.name}</td>
                        <td>{ele.email}</td>
                        <td>{ele.mob_no}</td>
                        <td>
                            <span className={`badge badge-pill badge-${ele.status === "Active" ?
                                "success" :
                                "danger"
                                }`
                            }>
                                {ele.status}
                            </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip> Send Mail to user </Tooltip>}
                            >
                                <button className="btn-theme" onClick={() => SendEmailPopUp(ele.id, 0)}>
                                    <span className="material-icons">
                                        send
                                    </span>
                                </button>
                            </OverlayTrigger>
                            &nbsp;
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip> Delete </Tooltip>}
                            >
                                <button className="btn-danger" onClick={() => SendEmailPopUp(ele.id, 1)}>
                                    <span className="material-icons">
                                        delete
                                    </span>
                                </button>
                            </OverlayTrigger>
                            &nbsp;
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Edit</Tooltip>}
                            >
                                <button className="btn-primary"
                                    style={{ backgroundColor: "#0d6efd" }}
                                    onClick={() => EditUser(ele)}>
                                    <span className="material-icons">
                                        edit
                                    </span>
                                </button>
                            </OverlayTrigger>
                        </td>
                    </tr>
                )
            }) :
                <tr>
                    <td colSpan="15" className="text-center noRec-found"> No record found</td>
                </tr>
            }
        </>
    );
};

export default UserTab;
