import React, { useState } from "react";

const ContentPrize = ({ PakageId, PakageDetails, setprizeType, checked, PakageDetail }) => {
    console.log("PakageDetails", PakageDetails);
    if (PakageDetails !== undefined) {
        return (
            <div className="col-lg-5 mt-5 mt-lg-0">
                <div className="row mb-2">
                    <div className="col text-center">Monthly</div>
                    <div className="col text-center">Termly</div>
                    <div className="col text-center">Yearly</div>
                </div>
                <div className="priceBx mb-4">
                    <div className="row">
                        <label className="col">
                            <input
                                type="radio"
                                value={PakageDetails[`${PakageId}`]?.monthly_price}
                                checked={checked === 0 ? true : false}
                                onChange={(e) => {
                                    setprizeType(e, PakageId, 0, "monthly_price", false, PakageDetail, "monthly_discount_per")

                                }} />
                            <span>Ksh {PakageDetails[`${PakageId}`]?.monthly_price}</span>
                        </label>
                        <label className="col">
                            <input type="radio"
                                value={PakageDetails[`${PakageId}`]?.termly_price}
                                checked={checked === 1 ? true : false}
                                onChange={(e) => {
                                    setprizeType(e, PakageId, 1, "termly_price", false, PakageDetail, "termly_discount_per")
                                }} />
                            <span>Ksh {PakageDetails[`${PakageId}`]?.termly_price}</span>
                        </label>
                        <label className="col">
                            <input type="radio"
                                value={PakageDetails[`${PakageId}`]?.yearly_price}
                                checked={checked === 2 ? true : false}
                                onChange={(e) => {
                                    setprizeType(e, PakageId, 2, "yearly_price", false, PakageDetail, "yearly_discount_per")
                                }} />
                            <span>Ksh {PakageDetails[`${PakageId}`]?.yearly_price}</span>
                        </label>
                    </div>
                </div>
            </div>
        )
    };
}
export default ContentPrize;
