import { useEffect, useState } from 'react';
import DashboardNavbar from '../../../Components/DashboardNavbar/DashboardNavbar';
import DashboardHeader from '../../../Components/DashboardHeader/DashboardHeader';
import Api from '../../../Api';
import "../../../Components/Common/ContactForm/ContactForm.css";
import Loader from '../../../Components/Common/loader';
import CommonButton from "../../../Components/Common/Buttons/Buttons";
import PaginatedItems from "../../../Components/Common/pagination";
import { UserNotification } from '../../../Redux/Action/UserAction';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import HandleError from '../../../Components/HandleError/HandleError';
import { toast } from 'react-toastify';
import CommonModal from "../../../Components/Common/Modal/Modal";
import { InputText } from "../../../Components/Common/Inputs/Inputs";
import Multiselect from 'multiselect-react-dropdown';
const LmsDomains = () => {
    const [listData, setlistData] = useState()
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [idss, setid] = useState();
    const [formErrors, setFormErrors] = useState();
    const [formErrors1, setFormErrors1] = useState();
    const [currentLmsDomains, setcurrentLmsDomains] = useState();
    const [isSubmit, setIsSubmit] = useState(false);
    const [currentPage, setcurrentPage] = useState(1);
    const [loading, setLoading] = useState(true)
    const [Refresh, setRefresh] = useState(true)
    const [loadings, setLoadings] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [SelectedDropdowndata, setSelectedDropdowndata] = useState({});
    const [Dropdowndata, setDropdowndata] = useState({});
    const [SelectedValue, setSelectedValue] = useState([]);
    const [formValues, setFormValues] = useState({
        domain_url: "",
    });

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
        };
        fetch(Api.baseURL + `/list_lms_domain?page_no=${currentPage}`, config)
            .then((resp) => resp.json())
            .then((result) => {
                setlistData(result.data.userLmsDomains)
                setcurrentLmsDomains(result.data.tot_user_lms_domains - 1)
                setLoading(false)
                // dispatch(UserNotification());
                if (result.status === "error") {
                    // HandleError(result)
                }
            })

        // if (Object.keys(formErrors).length === 0 && isSubmit) {

        // }
    }, [currentPage, loadings, Refresh])

    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };

    const Submitdata1 = () => {
        const token = JSON.parse(localStorage.getItem("user-token"));
        setShow1(false)
        const config = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
            body: JSON.stringify({ "user_lms_domain_id": idss })
        };
        fetch(Api.baseURL + `/delete_lms_domain`, config)
            .then((resp) => resp.json())
            .then((result) => {
                if (result.status === "success") {
                    toast.success("LMS domain delete successfully")
                    setLoadings(!loadings)
                    console.log(result, "res")
                }
                if (result.status === "error") {
                    // HandleError(result)
                    toast.error(result.message)
                    console.log(result, "res")
                }
            })
    }

    const handleClose1 = () => {
        setShow1(false)
    }

    const SendEmailPopUp = (id) => {
        setShow1(true)
        setid(id)

    }
    const handleClose = () => {
        setShow(false)
        setFormValues({ domain_url: "" })
        setFormErrors()
        setFormErrors1()
    }
    const Submitdata = (e) => {
        e.preventDefault();
        var regx = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        var data = formValues.domain_url
        // console.log("sdfsdfdsfdssdfsdfdsfdssdfsdfdsfdssdfsdfdsfds",data.match(regx))
        if (formValues?.domain_url.length > 1 && data.match(regx) != null && SelectedValue.length > 0) {
            // setFormErrors(validate(formValues));
            // setIsSubmit(true);
            let array = []
            for (let index = 0; index < SelectedValue.length; index++) {
                array.push(SelectedValue[index].id)
            }
            console.log('tettestttt');
            // Save lms domain
            const token = JSON.parse(localStorage.getItem("user-token"));
            const config = {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Authorization: "BEARER " + token.data.token,
                },
                body: JSON.stringify({ "domain_url": formValues.domain_url, "subscriptions": array }),
            };
            fetch(Api.baseURL + `/store_lms_domain`, config)
                .then((resp) => resp.json())
                .then((result) => {
                    if (result?.status === "success") {
                        // dispatch(Useraccount());
                    }
                    else if (result.status === "error") {
                        toast.error(result.message)
                    }

                    if (result.status === "success") {
                        toast.success("LMS domain add successfully")
                        setLoadings(!loadings)
                        console.log(result, "res")
                    }
                    if (result.status === "error") {
                        HandleError(result)
                        console.log(result, "res")
                    }
                })
            /*******************************/
            setShow(false);
            setFormValues({
                domain_url: "",
            });
            setFormErrors()
            setIsSubmit(false)
        }
        else if (formValues.domain_url.length > 1 && data.match(regx) == null && SelectedValue.length > 0) {
            console.log(SelectedValue.length, formValues.domain_url.length, "formValuesformValuesformValues");
            setFormErrors("Enter valid Url")

            setFormErrors1("")
        }
        else if (formValues.domain_url.length > 1 && data.match(regx) == null) {
            console.log(SelectedValue.length, formValues.domain_url.length, "formValuesformValuesformValues");
            setFormErrors("Enter valid Url")
        }
        else if (formValues.domain_url.length < 1 && SelectedValue.length > 0) {
            console.log(SelectedValue.length, formValues.domain_url.length, "formValuesformValuesformValues");
            setFormErrors1("")
            setFormErrors("Domain name required")
        } else if (SelectedValue.length < 1 && formValues.domain_url.length > 1) {
            console.log(SelectedValue.length, formValues.domain_url.length, "formValuesformValuesformValues");
            setFormErrors1("Subscription Required")
            setFormErrors("")
        } else {
            console.log(SelectedValue.length, formValues.domain_url.length, "formValuesformValuesformValues");
            setFormErrors1("Subscription Required")
            setFormErrors("Domain name required")
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };
    const validate = (values) => {
        const errors = {};
        if (!values.domain_url) {
            errors.username = "Domain url is required!";
        }
        return errors;
    };

    //popup for single user
    const handleShow = (grade_id) => {
        // setgrade_id(grade_id)
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
        };
        fetch(Api.baseURL + `/get_active_subscription_list`, config)
            .then((resp) => resp.json())
            .then((result) => {
                if (result?.status === "success") {
                    let data = result.data.subscriptions.map((ele, i) => {
                        return { ...ele, "id_plan_name": `${ele.invoice_id} ${ele.plan_name} ` }
                    })
                    console.log(data, "gdfgcfgfdgfdgfdgd");
                    setDropdowndata({ "options": data })
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    const onSelect = (selectedList, selectedItem) => {
        console.log("FDSFSDF", selectedList);
        setSelectedValue(selectedList)
    }

    const onRemove = (selectedList, removedItem) => {
        setSelectedValue(selectedList)
    }

    const body = () => {
        return (
            <>
                <InputText
                    className="col-2 mb-0"
                    type="url"
                    name="domain_url"
                    value={formValues.domain_url}
                    placeholder="Enter Domain Url"
                    onChange={handleChange}
                    error={formErrors}
                    labelText="Domain Url"
                    required="true"
                />
                <label id="aria-label" htmlFor="aria-example-input">
                    Subscription
                </label>

                <Multiselect
                    className='dataaaaa'
                    options={Dropdowndata.options} // Options to display in the dropdown
                    selectedValues={SelectedDropdowndata} // Preselected value to persist in dropdown
                    onSelect={onSelect} // Function will trigger on select event
                    onRemove={onRemove} // Function will trigger on remove event
                    displayValue="id_plan_name" // Property name to display in the dropdown options
                    showCheckbox={true}
                    placeholder="Search Subscription"
                />
                {formErrors1 &&
                    <p className='input-errors'>{formErrors1}</p>
                }
            </>
        )
    }

    // Modal data
    let modelData = body();

    let UpdateToken = (ele) => {
        const token = JSON.parse(localStorage.getItem("user-token"));
        const config = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
            body: JSON.stringify({
                "user_lms_domain_id": ele
            })
        };
        fetch(Api.baseURL + `/generate_domain_lms_token`, config)
            .then((resp) => resp.json())
            .then((result) => {
                if (result?.status === "success") {
                    toast.success("Token Update successfully")
                    setRefresh(!Refresh)
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }


    const UpdatePopUp = (id) => {
        handleShow(id)
        setid(id)
        setShow2(true)
        const token = JSON.parse(localStorage.getItem("user-token"));
        setShow1(false)
        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
        };
        fetch(Api.baseURL + `/edit_lms_domain/${id}`, config)
            .then((resp) => resp.json())
            .then((result) => {
                if (result.status === "success") {
                    // setLoadings(!loadings)
                    let data = result.data.subscriptionsArr.map((ele, i) => {
                        return { ...ele, "id_plan_name": `${ele.invoice_id} ${ele.package_name} ` }
                    })
                    console.log(data, "gdfgcfgfdgfdgfdgd");
                    setSelectedDropdowndata(data)
                    setSelectedValue(data)
                    setFormValues({ "domain_url": result.data.lmsDomainInfo[0].domain_url })

                }
                if (result.status === "error") {
                    // HandleError(result)
                    toast.error(result.message)
                    console.log(result, "res")
                }
            })

    }
    const Submitdata2 = () => {
        var regx = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        var data = formValues.domain_url
        // console.log("sdfsdfdsfdssdfsdfdsfdssdfsdfdsfdssdfsdfdsfds",data.match(regx))
        if (formValues?.domain_url.length > 1 && data.match(regx) != null && SelectedValue.length > 0) {
            // setFormErrors(validate(formValues));
            // setIsSubmit(true);
            let array = []
            for (let index = 0; index < SelectedValue.length; index++) {
                console.log('tettestttt', SelectedValue[index]);
                if (SelectedValue[index]?.subscription_id) {
                    array.push(SelectedValue[index].subscription_id)
                } else {
                    array.push(SelectedValue[index].id)
                }
            }
            // Save lms domain
            const token = JSON.parse(localStorage.getItem("user-token"));
            const config = {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Authorization: "BEARER " + token.data.token,
                },
                body: JSON.stringify({ "user_lms_domain_id": idss, "domain_url": formValues.domain_url, "subscriptions": array }),
            };
            fetch(Api.baseURL + `/update_lms_domain`, config)
                .then((resp) => resp.json())
                .then((result) => {
                    if (result?.status === "success") {
                        // dispatch(Useraccount());
                    }
                    else if (result.status === "error") {
                        toast.error(result.message)
                    }

                    if (result.status === "success") {
                        toast.success("LMS domain add successfully")
                        setLoadings(!loadings)
                        console.log(result, "res")
                    }
                    if (result.status === "error") {
                        HandleError(result)
                        console.log(result, "res")
                    }
                })
            /*******************************/
            setShow2(false);
            setFormValues({
                domain_url: "",
            });
            setFormErrors()
            setIsSubmit(false)
        }
        else if (formValues.domain_url.length > 1 && data.match(regx) == null && SelectedValue.length > 0) {
            setFormErrors("Enter valid Url")

            setFormErrors1("")
        }
        else if (formValues.domain_url.length > 1 && data.match(regx) == null) {
            console.log(SelectedValue.length, formValues.domain_url.length, "formValuesformValuesformValues");
            setFormErrors("Enter valid Url")
        }
        else if (formValues.domain_url.length < 1 && SelectedValue.length > 0) {
            console.log(SelectedValue.length, formValues.domain_url.length, "formValuesformValuesformValues");
            setFormErrors1("")
            setFormErrors("Domain name required")
        } else if (SelectedValue.length < 1 && formValues.domain_url.length > 1) {
            console.log(SelectedValue.length, formValues.domain_url.length, "formValuesformValuesformValues");
            setFormErrors1("Subscription Required")
            setFormErrors("")
        } else {
            console.log(SelectedValue.length, formValues.domain_url.length, "formValuesformValuesformValues");
            setFormErrors1("Subscription Required")
            setFormErrors("Domain name required")
        }
        console.log(SelectedValue.length, formValues.domain_url.length, "formValuesformValuesformValues");
    }

    const handleClose2 = () => {
        setShow2(false)
        setFormValues({ domain_url: "" })
        setFormErrors()
        setFormErrors1()
        setSelectedDropdowndata({})
    }
    return (
        <>
            <CommonModal
                handleClose={handleClose}
                handleSubmit={Submitdata}
                show={show}
                ModalTitle="Add LMS Domain"
                modelData={modelData}
            />
            <CommonModal
                handleClose={handleClose1}
                handleSubmit={Submitdata1}
                show={show1}
                ModalTitle="Delete LMS Domain"
                modelData={"Are you sure you want to delete LMS domain"}
            />
            <CommonModal
                handleClose={handleClose2}
                handleSubmit={Submitdata2}
                show={show2}
                ModalTitle="Update LMS Domain"
                modelData={modelData}
            />
            <DashboardHeader />
            <div className="mpc-dashboard">
                <DashboardNavbar />
                <div className="dashboard-right-content">
                    <h1 className="main-page-heading with-filter">
                        <span>LMS Domains</span>
                        <div className="filter">
                            <CommonButton
                                isButton="true"
                                BtnType="submit"
                                BtnColor="green btn-sm"
                                BtnText="Add LMS Domain"
                                onClick={() => {
                                    handleShow()
                                    setShow(true)
                                }}
                            />
                        </div>
                    </h1>
                    <div className="notifications-table">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: '5%' }}>#</th>
                                        <th scope="col" style={{ width: '20%' }}>Domain Url</th>
                                        <th scope="col" style={{ width: '20%' }}>Subscriptions Id</th>
                                        <th scope="col" style={{ width: '20%' }}>Domain Token</th>
                                        <th scope="col" style={{ width: '20%' }}>Action</th>
                                    </tr>
                                </thead>
                                {loading === true ?
                                    <Loader />
                                    :
                                    <>
                                        {listData?.length != "0" ? listData.map((ele, i) => {
                                            return (<>
                                                <tbody>
                                                    <tr>
                                                        <td scope="col">{i + 1 + (currentPage - 1) * 10}</td>
                                                        <td scope="col">{ele.domain_url}</td>
                                                        <td scope="col">{ele.subscriptions}</td>
                                                        <td scope="col">{ele.domain_token}</td>
                                                        <td scope="col">
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip> Delete </Tooltip>}
                                                            >
                                                                <button className="btn-danger"
                                                                    onClick={() => { SendEmailPopUp(ele.id) }}
                                                                >
                                                                    <span className="material-icons">
                                                                        delete
                                                                    </span>
                                                                </button>
                                                            </OverlayTrigger>
                                                            &nbsp;

                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip>Edit</Tooltip>}
                                                            >
                                                                <button className="btn-primary"
                                                                    style={{ backgroundColor: "#0d6efd" }}
                                                                    onClick={() => UpdatePopUp(ele.id)}
                                                                >
                                                                    <span className="material-icons">
                                                                        edit
                                                                    </span>
                                                                </button>
                                                            </OverlayTrigger>            &nbsp;
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip>Update Token</Tooltip>}>
                                                                <button className="btn-theme" onClick={() => UpdateToken(ele.id)}>
                                                                    <i className="material-icons" >refresh</i>
                                                                </button>
                                                            </OverlayTrigger>

                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </>)
                                        }) : <tbody>
                                            <tr>
                                                <td colSpan="15" className="text-center noRec-found"> No record found</td>
                                            </tr>
                                        </tbody>}
                                    </>
                                }
                            </table>
                        </div>
                        {currentLmsDomains > 10 &&
                            <PaginatedItems
                                List={currentLmsDomains}
                                HandlePageClick={HandlePageClick}
                            />}
                    </div>
                </div>
            </div>
        </>
    )
}
export default LmsDomains;