import { useEffect, useState } from 'react';
import SectionTitle from "../../Components/Common/SectionTitle/SectionTitle";
import { Link } from 'react-router-dom';
import CommonButton from "../../Components/Common/Buttons/Buttons";
import { InputText } from "../../Components/Common/Inputs/Inputs";
import { useNavigate } from 'react-router-dom';
import '../Login/Login.css';
import Api from '../../Api';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
    const [Usermail, setEmail] = useState({ email: "" })
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem("user-token")) {
            navigate("/");
        }
    }, []);

    const SendMail = (e) => {
        e.preventDefault();
        setEmail({ email: "" })
        const config = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(Usermail)
        };

        fetch(Api.baseURL + `/send-verification-email`, config)
            .then((res) => res.json())
            .then((data) => {
                if (data.status === "success") {
                    toast.success(data.message)
                }
                if (data.status === "error") {
                    toast.error(data.message)
                }
            })
            .catch((err) => {
                // if (err.status === "error") {
                //     toast.error(err.message)
                // }
            });

    }
    return (
        <>
            <section className="hero-section authentication-banner">
                <div className="banner-bg">
                    <img src="/images/banner-top-vector.svg" alt="banner-vectors" />
                </div>
                <div className="banner-btm-bg">
                    <img src="/images/banner-btm-vector.svg" alt="banner-vectors" />
                </div>
                <div className="container-fluid">
                    <SectionTitle CustomClass="text-center pad-b-30" title="Forgot Password" ShapeImage="heading-clip-1.svg" SubHeading={<p className="what-sec-text">Enter your email address</p>} />
                    <div className="authentication-bx">
                        <form>
                            <InputText
                                type="email"
                                placeholder="Email"
                                value={Usermail.email}
                                onChange={(e) => setEmail({ ...Usermail, ["email"]: e.target.value })}
                            />
                            <label>Please check registred email Id for reset password link</label>
                            <div className="form-group text-center">
                                <CommonButton isButton="true" onClick={SendMail} BtnColor="green w-100" BtnText="Reset Password" />
                            </div>

                            <p className="create-account">Back to <Link to="/login">Login</Link></p>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ForgotPassword;