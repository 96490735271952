import { useState } from 'react';
import { InputText } from '../Inputs/Inputs';
import SectionTitle from "../../Common/SectionTitle/SectionTitle";
import { useDispatch } from "react-redux";
import CommonButton from '../Buttons/Buttons';
import { ContactApiData } from '../../../Redux/Action/AuthAction';
import './ContactForm.css';
import ReCAPTCHA from "react-google-recaptcha";
const ContactForm = () => {
    const dispatch = useDispatch();
    const [contactFields, setContactFields] = useState({
        question: "",
        name: "",
        email: "",
        message: "",
        captcha_token: ""
    });
    const [submitCaptcha, setSubmitCaptcha] = useState(false)
    const [contactFieldsError, setContactFieldsError] = useState({
    });
    let captcha;
    const onChange = (value) => {
        console.log("Captcha value:", value);
        setSubmitCaptcha(true)
        setContactFields({ ...contactFields, captcha_token: value })

    }

    const onSubmit = () => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!contactFields.question) {
            errors.question = "Question is required!";
        } else if (!isNaN(contactFields.question)) {
            errors.question = "Please enter Alphabet value";
        } else if (contactFields.question.length < 4) {
            errors.question = "Question Length Should Be more then 4 ";
        }
        if (!contactFields.name) {
            errors.username = "Name is required!";
        } else if (!isNaN(contactFields.name)) {
            errors.username = "Please enter Alphabet value";
        } else if (contactFields.name.length < 4 || contactFields.name.length > 40) {
            errors.username = "Name Length Should Be 4 To 40";
        }
        if (!contactFields.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(contactFields.email)) {
            errors.email = "Enter Vaild Email!";
        }
        if (!contactFields.message) {
            errors.message = "Message field is required!";
        } else if (!isNaN(contactFields.message)) {
            errors.message = "Please enter Alphabet value";
        }
        console.log(Object.keys(errors).length === 0, errors, "err");
        if (Object.keys(errors).length === 0) {
            dispatch(ContactApiData({ contactFields }));
            setContactFieldsError(errors)
            setContactFields({
                question: "",
                name: "",
                email: "",
                message: "",
                captcha_token: ""
            })
            captcha.reset();
            setSubmitCaptcha(false)
        } else {
            setContactFieldsError(errors)
        }

        // setContactFields({
        //     question: "",
        //     name: "",
        //     email: "",
        //     message: ""
        // })
        // dispatch(ContactApiData({ contactFields }));
    }

    return (
        <>
            <div className="container">
                <form action name="contact form" className="ftr-contact-form">
                    <SectionTitle CustomClass="pad-b-50 text-center" title="Contact Us" ShapeImage="heading-clip-2.svg" />
                    <div className="row">
                        <div className="col-lg-4">
                            <InputText
                                type="text"
                                name="question"
                                value={contactFields.question}
                                placeholder="Ask a Question"
                                onChange={(e) => { setContactFields({ ...contactFields, question: e.target.value }) }
                                }
                                error={contactFieldsError.question}
                            />
                        </div>
                        <div className="col-lg-4">
                            <InputText
                                type="text"
                                name="name"
                                value={contactFields.name}
                                placeholder="Name"
                                onChange={(e) => { setContactFields({ ...contactFields, name: e.target.value }) }
                                }
                                error={contactFieldsError.username}
                            />
                        </div>
                        <div className="col-lg-4">
                            <InputText
                                type="email"
                                name="email"
                                value={contactFields.email}
                                placeholder="Email"
                                onChange={(e) => { setContactFields({ ...contactFields, email: e.target.value }) }
                                }
                                error={contactFieldsError.email}
                            />
                        </div>
                        <div className="col-lg-12">
                            <textarea
                                className="form-control"
                                name="message"
                                placeholder="Message...."
                                value={contactFields.message}
                                onChange={(e) => { setContactFields({ ...contactFields, message: e.target.value }) }
                                }

                            />

                            <p className='input-errors'>{contactFieldsError.message}</p>
                        </div>
                        <div className="col-lg-4 mt-4">
                            <ReCAPTCHA
                                sitekey="6LcfzyEgAAAAAGA0Ibj4iKxHA8Ou5HWc1julTQ7r"
                                onChange={(value) => onChange(value)}
                                ref={el => { captcha = el; }}
                            />
                        </div>
                        <div className="col-12 text-left mt-4">
                            <CommonButton
                                disabled={!submitCaptcha}
                                isButton="true" BtnType="button" BtnColor="green" BtnText="Submit" onClick={onSubmit} />
                        </div>
                    </div>
                </form>
            </div >
        </>
    );
}

export default ContactForm;